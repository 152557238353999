import * as mapboxgl from "mapbox-gl"

export function updateMapItineraryWalk(map: mapboxgl.Map, coordinates, mapId: string) {
  if (map.getSource(mapId)) {
    map.removeLayer(mapId)
    map.removeSource(mapId)
  }
  map.addSource(mapId, {
    type: "geojson",
    data: {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: coordinates,
      },
    },
  })
  map.addLayer({
    id: mapId,
    type: "line",
    source: mapId,
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#A9A9A9",
      "line-width": 6,
      "line-dasharray": [0, 2],
    },
  })
}

export function updateMapItinerary(map: mapboxgl.Map, coordinates, mapId: string) {
  if (map.getSource(mapId)) {
    map.removeLayer(mapId)
    map.removeSource(mapId)
  }

  coordinates.map((x, index) => {
    if (x[1] > 44) {
      console.log(x[1])
      console.log(index)
    }
  })
  map.addSource(mapId, {
    type: "geojson",
    data: {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: coordinates,
      },
    },
  })
  map.addLayer({
    id: mapId,
    type: "line",
    source: mapId,
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#000",
      "line-width": 4,
    },
  })
}
