import { useEffect } from "react"
import { pushGtmEvent } from "../gtm/interaction-event"

export const useDetectScroll = () => {
  const scrollBreakpoints = { first: true, second: true, third: true, fourth: true }

  const handleScroll = () => {
    let percentage = getScrollPercentage()
    if (scrollBreakpoints.first == true && percentage >= 25 && percentage < 50) {
      scrollBreakpoints.first = false
      pushGtmEvent("eventga", "Scroll", "25%", "")
    }
    if (scrollBreakpoints.second == true && percentage >= 50 && percentage < 75) {
      scrollBreakpoints.second = false
      pushGtmEvent("eventga", "Scroll", "50%", "")
    }
    if (scrollBreakpoints.third == true && percentage >= 75 && percentage < 100) {
      scrollBreakpoints.third = false
      pushGtmEvent("eventga", "Scroll", "75%", "")
    }
    if (scrollBreakpoints.fourth == true && percentage >= 100) {
      scrollBreakpoints.fourth = false
      pushGtmEvent("eventga", "Scroll", "100%", "")
    }
  }

  /**
   * Get current browser viewpane heigtht
   */
  const getWindowHeight = () => {
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0
  }

  /**
   * Get current absolute window scroll position
   */
  const getWindowYscroll = () => {
    return window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop || 0
  }

  /**
   * Get current absolute document height
   */
  const getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight || 0,
      document.documentElement.scrollHeight || 0,
      document.body.offsetHeight || 0,
      document.documentElement.offsetHeight || 0,
      document.body.clientHeight || 0,
      document.documentElement.clientHeight || 0
    )
  }

  /**
   * Get current vertical scroll percentage
   */
  const getScrollPercentage = () => {
    return (getWindowYscroll() / (getDocHeight() - getWindowHeight())) * 100
  }

  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", handleScroll)
    }
  })
}
