// extracted by mini-css-extract-plugin
export var container = "autocomplete-module--container--34149";
export var input = "autocomplete-module--input--719c6";
export var inputFocused = "autocomplete-module--inputFocused--527fd";
export var suggestion = "autocomplete-module--suggestion--eaa2b";
export var suggestionHighlighted = "autocomplete-module--suggestionHighlighted--13b47";
export var suggestionsContainer = "autocomplete-module--suggestionsContainer--d357d";
export var suggestionsContainerOpen = "autocomplete-module--suggestionsContainerOpen--13abf";
export var suggestionsList = "autocomplete-module--suggestionsList--61695";
export var wrapper = "autocomplete-module--wrapper--5a81a";
export var wrapperFullWidth = "autocomplete-module--wrapperFullWidth--2fa16";