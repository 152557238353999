// extracted by mini-css-extract-plugin
export var buttonNoStyle = "traffic-blocks-module--buttonNoStyle--15468";
export var controls = "traffic-blocks-module--controls--d29aa";
export var customBlockArrows = "traffic-blocks-module--customBlockArrows--b5b84";
export var detailsCard = "traffic-blocks-module--detailsCard--e16f4";
export var detailsCardBody = "traffic-blocks-module--detailsCardBody--b22f3";
export var detailsCardBodyLePilote = "traffic-blocks-module--detailsCardBodyLePilote--38dbe";
export var detailsCardBodyWrapper = "traffic-blocks-module--detailsCardBodyWrapper--cb57c";
export var detailsCardHeader = "traffic-blocks-module--detailsCardHeader--0d26c";
export var detailsCardHeaderContent = "traffic-blocks-module--detailsCardHeaderContent--ee3f0";
export var detailsCardHeaderTitle = "traffic-blocks-module--detailsCardHeaderTitle--af88f";
export var exitButton = "traffic-blocks-module--exitButton--3f922";
export var exitIcon = "traffic-blocks-module--exitIcon--ad0e0";
export var exitIconContainer = "traffic-blocks-module--exitIconContainer--f8195";
export var gridBlocks = "traffic-blocks-module--gridBlocks--c98a1";
export var image = "traffic-blocks-module--image--fbbda";
export var infoTrafficTitle = "traffic-blocks-module--infoTrafficTitle--52841";
export var lePiloteImage = "traffic-blocks-module--lePiloteImage--2ea25";
export var oneGridBlock = "traffic-blocks-module--oneGridBlock--9b56e";
export var only4 = "traffic-blocks-module--only4--f660d";
export var secondaryBlock = "traffic-blocks-module--secondaryBlock--2fc79";
export var secondaryBlocks = "traffic-blocks-module--secondaryBlocks--3593c";
export var shortDescription = "traffic-blocks-module--shortDescription--ad0a2";
export var slides = "traffic-blocks-module--slides--de013";
export var svgImage = "traffic-blocks-module--svgImage--85e5c";
export var textInPlaceOfImage = "traffic-blocks-module--textInPlaceOfImage--b9d87";