import * as config from "../../config.js"

const removeEmpty = obj => {
  Object.keys(obj).forEach(key => (obj[key] == null || obj[key] == undefined || obj[key] === "") && delete obj[key])
}

export function getSingleLineFromApi(lineReference) {
  const token = config.api_token
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": token,
    },
  }
  //let sortingArr = ["A", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "21", "22", "23", "24", "25", "26", "M1", "M2", "M3", "03SP", "05SP", "08SP", "09SP", "10SP", "12SP", "15SP", "26SP"];
  return fetch(
    config.api_url + config.api_url_get_lines_endpoint + "?filters[reference]=" + lineReference,
    requestOptions
  ).then(async response => {
    const data = await response.json()
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data.data[0]
  })
}

export function getLinesFromApi() {
  const token = config.api_token
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": token,
    },
  }
  return fetch(config.api_url + config.api_url_get_lines_endpoint, requestOptions).then(async response => {
    const data = await response.json()
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data.data
  })
}

export function registerCustomerToSmsAlertRequest(formData) {
  let bday = ""
  if (formData["bday"]) {
    bday = formData["bday"].toISOString().split("T")[0]
  }
  let linesIdsArray = []
  linesIdsArray.push(formData.line0 ? formData.line0.id : null)
  linesIdsArray.push(formData.line1 ? formData.line1.id : null)
  linesIdsArray.push(formData.line2 ? formData.line2.id : null)
  linesIdsArray.push(formData.line3 ? formData.line3.id : null)
  linesIdsArray.push(formData.line4 ? formData.line4.id : null)
  const definedLinesIds = linesIdsArray.filter(v => v !== null)
  const token = config.api_token
  let requestBody = {
    last_name: formData["family-name"],
    first_name: formData["given-name"],
    phone: formData.tel,
    enabled: true,
    email: formData.email,
    birthday: bday,
    address: formData.address,
    zipcode: formData["postal-code"],
    city_name: formData.city,
    lines: definedLinesIds,
    network_subscriber: definedLinesIds.length === 0 || formData.allNetworkCheckbox,
    offers_subscriber: !!formData.otherOffersCheckboxField,
  }
  removeEmpty(requestBody)
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": token,
    },
    body: JSON.stringify(requestBody),
  }
  return fetch(config.api_url + config.api_url_user_registration_endpoint, requestOptions).then(async response => {
    const data = await response.json()
    if (!response.ok) {
      const error = (data && data.messages) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}
