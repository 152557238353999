import React from "react"
import * as styles from "../traffic-block/traffic-block.module.scss"

type RenderProps = {
  block: any
  onPerturbationClicked: (event) => void
}

const LePiloteTrafficBlock: React.FC<RenderProps> = ({ block, onPerturbationClicked }) => {
  const linesWithUniqueDisruptions = block.map(lineWithTrafficInfos => {
    const disruptions = lineWithTrafficInfos.traffic.reduce((trafficInfos, traffic) => {
      trafficInfos.set(traffic.DisruptionType.Code, traffic)
      return trafficInfos
    }, new Map<string, Object>())
    return {
      line: lineWithTrafficInfos,
      disruptions: Array.from(disruptions.values()),
    }
  })

  return (
    <ul className={styles.trafficBlock}>
      {linesWithUniqueDisruptions.map(item => (
        <li className={styles.blockContent} key={item.line.line.Name}>
          <button
            id={`le-pilote-traffic-block-button-${item.line.line.Id}`}
            className={styles.buttonNoStyle}
            onClick={() => onPerturbationClicked(item.line.line.Id)}
            key={item.line.line.Id}
          >
            {item.line.line.icone ? (
              <img src={item.line.line.icone.url} alt={item.line.line.Code || ""} />
            ) : (
              item.line.line.Code
            )}
          </button>
        </li>
      ))}
    </ul>
  )
}

export default LePiloteTrafficBlock
