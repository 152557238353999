import React, { useEffect, useRef, useState } from "react"
import {
  LePiloteInfoTrafficLinesIconsFieldsFragment,
  LePiloteNextTripsSearchModuleFieldsFragment,
} from "../../../graphql-types"
import * as styles from "./le-pilote-next-trips-search.module.scss"
import * as blockStyles from "../home/custom-block/custom-block.module.scss"
import * as inputsStyles from "../layout/inputs.module.scss"
import { graphql } from "gatsby"
import Autocomplete from "../autocomplete/autocomplete"
import { getNextTrips, getStopsFromApi } from "../../api/le-pilote-api"

type RenderProps = {
  moduleData: LePiloteNextTripsSearchModuleFieldsFragment
  linesIconsData?: LePiloteInfoTrafficLinesIconsFieldsFragment[]
}

const LePiloteNextTripsSearch: React.FC<RenderProps> = ({ moduleData, linesIconsData }) => {
  const component = useRef<HTMLFormElement>()

  const [stops, setStops] = useState([])
  const [realStops, setRealStops] = useState(null)
  const [nextTrips, setNextTrips] = useState(null)
  const [noResults, setNoResults] = useState(false)

  useEffect(() => {
    if (window.location.search) {
      const query = extractQueryFromUrl(window.location.search)
      if (query) {
        searchNextTrips(query[0])
      }
    }
  }, [])

  function extractQueryFromUrl(param) {
    const fullQuery = param.split("=")
    if (fullQuery[1] && fullQuery[1] != "") {
      const query = fullQuery[1].split("%23")
      query[1] = query[1].replaceAll("+", " ")
      return query
    }
    return null
  }

  function formatStopsResults(stopsResults) {
    if (stopsResults) {
      const uniqueStops = stopsResults.filter(
        (ele, index) => index === stopsResults.findIndex(elem => elem.LogicalId === ele.LogicalId)
      )
      return uniqueStops
    } else {
      return []
    }
  }

  function getLineIcon(lineCode) {
    const lineCodeLowercase = lineCode.toLowerCase()
    return linesIconsData.filter(x => x.basename.endsWith(`${lineCodeLowercase}`))
  }

  function formatNextTripArrivalTimeAndLine(nextTrip, hour) {
    let arrivalTime = hour.TheoricArrivalTime
    let lineId = hour.LineId
    if (hour.PredictedArrivalTime) {
      arrivalTime = hour.PredictedArrivalTime
    }
    if (hour.aimedArrivalTime) {
      arrivalTime = hour.AimedArrivalTime
    }
    if (hour.realArrivalTime) {
      arrivalTime = hour.RealArrivalTime
    }
    let date = new Date()
    let minutes = date.getHours() * 60 + date.getMinutes()
    let time
    let timeInMinutes
    let matchingLines = nextTrip.Lines.filter(line => line.Id == lineId)
    if (arrivalTime - minutes <= 0) {
      time = moduleData.arrivingMessage
      timeInMinutes = 0
    } else {
      if (arrivalTime - minutes >= 60) {
        let roundHour = Math.floor((arrivalTime - minutes) / 60)
        let remainder = (arrivalTime - minutes) % 60
        time = roundHour + (roundHour == 1 ? " h " : " hrs ") + remainder + " min."
      } else {
        time = arrivalTime - minutes + " min."
      }
      timeInMinutes = arrivalTime - minutes
    }
    hour.computedNextArrivals = { time, timeInMinutes }
    hour.computedLines = matchingLines
    const destination = nextTrip.VehicleJourneys.find(journey => journey.Id === hour.VehicleJourneyId)
    hour.computedDestination = destination.JourneyDestination
    if (hour.computedLines && hour.computedLines[0]) {
      hour.computedLineIcon = getLineIcon(hour.computedLines[0].Code)
    }
  }

  function searchNextTrips(query) {
    setNoResults(false)
    getNextTrips(query).then(nextTripsData => {
      if (!nextTripsData) {
        setNextTrips(null)
        setNoResults(true)
        return null
      }
      nextTripsData.Hours.map(hour => {
        formatNextTripArrivalTimeAndLine(nextTripsData, hour)
      })
      nextTripsData.Hours.sort(function (a, b) {
        return a.computedNextArrivals.timeInMinutes - b.computedNextArrivals.timeInMinutes
      })
      setNextTrips(nextTripsData)
    })
    return nextTrips
  }

  function searchAllTypeTripPoints(query) {
    if (query && query != "") {
      let cleanQuery = query.trim()
      let tmpStops
      ;(async () => {
        tmpStops = await getStopsFromApi(cleanQuery)
        setStops(tmpStops)
      })()
      const completeResults = formatStopsResults(stops)
      setRealStops(completeResults)
      return search(cleanQuery, completeResults, toLabel)
    }
    return []
  }

  const handleClickEvent = () => {
    const form = component.current
    const searchValue = form["search"].value.split("#")
    const tripPoint = searchAllTypeTripPoints(searchValue[1])
    if (tripPoint && tripPoint[0]) {
      searchNextTrips(tripPoint[0].LogicalId ? tripPoint[0].LogicalId : tripPoint[0].Id)
    }
    window.history.replaceState({}, document.title, window.location.href.split("?")[0])
  }

  return (
    <div className={styles.moduleContainer}>
      <form className={blockStyles.block} method="get" ref={component}>
        <div className={blockStyles.title + " " + styles.titleModule}>{moduleData.title}</div>
        <div className={styles.form}>
          <div className={styles.formPart}>
            <div className={styles.field}>
              <label htmlFor="trippoint">{moduleData.trippointFieldLabel}</label>
              <Autocomplete
                onChange={setRealStops}
                suggestFunction={searchAllTypeTripPoints}
                toLabel={toLabel}
                id="trippoint"
                placeholder={
                  (realStops && realStops.Name) ||
                  (nextTrips && nextTrips.Stops[0].Name) ||
                  moduleData.trippointFieldPlaceholder
                }
              />
              <input
                type="hidden"
                name="search"
                value={
                  (realStops && realStops.LogicalId + "#" + realStops.Name) ||
                  (realStops && realStops.Id + "#" + realStops.Name)
                }
              />
            </div>
          </div>
        </div>
        <button
          className={styles.searchButton + " " + inputsStyles.primaryButton}
          type="button"
          onClick={handleClickEvent}
        >
          {moduleData.searchButtonLabel}
        </button>
      </form>

      <div className={styles.nextTripsResultsContainer}>
        {nextTrips ? (
          nextTrips.Hours.map(x => {
            {
              return x.computedLines.map(line => {
                return (
                  <div className={styles.nextTripResult} key={line.Id}>
                    {x.computedLineIcon[0] ? (
                      <img src={x.computedLineIcon[0].url} className={styles.nextTripLineIcon}></img>
                    ) : (
                      <div className={styles.nextTripLineIcon + " " + styles.nextTripLineIconText}>{line.Code}</div>
                    )}
                    <div className={styles.nextTripLineName}>
                      {x.computedDestination ? x.computedDestination : line.Name}
                    </div>
                    <div className={styles.nextTripEta}>{x.computedNextArrivals.time}</div>
                  </div>
                )
              })
            }
          })
        ) : (
          <div className={styles.errorMessage}>{noResults && !nextTrips ? <p>{moduleData.errorMessage}</p> : null}</div>
        )}
      </div>
    </div>
  )
}

function search<T>(query: string, elements: T[], toLabelFn: (element: T) => string): T[] {
  if (elements) {
    return elements.filter(result => toLabelFn(result).toLowerCase().indexOf(query.toLowerCase()) !== -1)
  }
}

function toLabel(element?: any) {
  return element ? element.Name : ""
}

export const fragments = graphql`
  fragment LePiloteNextTripsSearchModuleFields on DatoCmsLePiloteNextTripsSearchModule {
    __typename
    id
    title
    trippointFieldLabel
    trippointFieldPlaceholder
    searchButtonLabel
    errorMessage
    arrivingMessage
  }

  fragment LePiloteInfoTrafficLinesIconsFields on DatoCmsAsset {
    id
    url
    basename
  }
`
export default LePiloteNextTripsSearch
