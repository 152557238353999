import { format, parse } from "date-fns"
import config from "../../config.js"

const LocalityIds = "13001|13100|13290|13090|13540|13080|13109|13770|13920|13921|13095|13113|13510|13032"
const OperatorIds = "7"
const STOP_PLACE = "4"
const ADDRESS = "3"
const POINT_OF_INTEREST = "1"
const ITINERARY_URL = "https://api.pilote4.cityway.fr/journeyplanner/api/opt/PlanTrips/json?"

export function getTripPointsFromApi(query: string) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  }
  const url = "https://tsvc.pilote3.cityway.fr:443/api/transport/v3/trippoint/GetTripPoints/json?"
  const params =
    "Keywords=" +
    query.split(" ").join("%20") +
    `&OperatorIds=${OperatorIds}` +
    `&LocalityIds=${LocalityIds}&Lang=${config.locale}`

  return fetch(url + params, requestOptions).then(async response => {
    const data = await response.json()
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data.Data
  })
}

export function getStopsFromApi(query: string) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  }
  const url = "https://tsvc.pilote3.cityway.fr:443/api/transport/v3/stop/SearchStops/json?"
  const params =
    "Keywords=" +
    query.split(" ").join("%20") +
    `&OperatorIds=${OperatorIds}` +
    `&LocalityIds=${LocalityIds}&Lang=${config.locale}`
  return fetch(url + params, requestOptions).then(async response => {
    const data = await response.json()
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data.Data
  })
}

export function getNextTrips(logicalStopId: number) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  }
  const url = "https://tsvc.pilote3.cityway.fr:443/api/transport/v3/timetable/GetNextStopHours/json?"
  const params =
    "LogicalStopIds=" + logicalStopId + `&OperatorIds=${OperatorIds}&Lang=${config.locale}` + "&MaxItemsByLine=2"
  return fetch(url + params, requestOptions).then(async response => {
    const data = await response.json()
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data.Data
  })
}

export function getItineraryLePilote(
  departureStop: any,
  arrivalStop: any,
  date: string,
  time: string,
  departurePointType: string,
  arrivalPointType: string,
  dateType: boolean
) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }
  const dateString = date + time
  const newDate = parse(dateString, "dd/MM/yyyyHH:mm", new Date())
  const formatedDate = format(newDate, "yyyy-MM-dd'T'HH:mm")
  const dateTypeValue = dateType ? "Arrival" : "Departure"

  let departureType =
    departurePointType == STOP_PLACE
      ? "STOP_PLACE"
      : departurePointType == "COORDINATES"
        ? "COORDINATES"
        : departurePointType == ADDRESS
          ? "ADDRESS"
          : departurePointType == POINT_OF_INTEREST
            ? "POI"
            : ""

  let arrivalType =
    arrivalPointType == STOP_PLACE
      ? "STOP_PLACE"
      : arrivalPointType == "COORDINATES"
        ? "COORDINATES"
        : arrivalPointType == ADDRESS
          ? "ADDRESS"
          : arrivalPointType == POINT_OF_INTEREST
            ? "POI"
            : ""

  let departure: string
  let arrival: string
  if (departureType === "COORDINATES") {
    departure =
      "DepartureType=" +
      departureType +
      "&DepartureLatitude=" +
      departureStop.latitude +
      "&DepartureLongitude=" +
      departureStop.longitude
  } else {
    departure = "DepartureType=" + departureType + `&DepartureId=` + (departureStop.value || departureStop)
  }

  if (arrivalType === "COORDINATES") {
    arrival =
      "&ArrivalType=" +
      arrivalType +
      "&ArrivalLatitude=" +
      arrivalStop.latitude +
      "&ArrivalLongitude=" +
      arrivalStop.longitude
  } else {
    arrival = "&ArrivalType=" + arrivalType + `&ArrivalId=` + (arrivalStop.value || arrivalStop)
  }
  const params =
    departure + arrival + "&Date=" + formatedDate + "&DateType=" + dateTypeValue + "&AllowedOperatorIds=" + OperatorIds
  return fetch(ITINERARY_URL + params, requestOptions).then(async response => {
    if (!response.ok) {
      const error = response.statusText
      return Promise.reject(error)
    }
    const data = await response.json()
    if (response.ok && data && data.StatusCode != 200) {
      const error = [data.StatusCode, data.Data, data.Message]
      return Promise.reject(error)
    }
    return Promise.resolve(data.Data)
  })
}

function getLineIcon(lineCode, linesIconsData) {
  const lineCodeLowercase = lineCode.toLowerCase()
  return linesIconsData.filter(x => x.basename.endsWith(`${lineCodeLowercase}`))
}

export function getItineraryLePiloteFormatedResults(tripsData, linesIconsData) {
  if (tripsData) {
    if (tripsData[0].response.Status.Code == "OK") {
      let trips = tripsData[0].response.trips.Trip.map(trip => {
        let sections = trip.sections.Section.map(section => {
          let allTripSections = []
          if (section.Leg) {
            let segment = getItineraryWalkingSegments(section)
            if (segment) {
              let mergedSegment = [].concat.apply([], segment)
              allTripSections.push({
                walkSegmentCoordinates: mergedSegment,
                walkSegmentDetails: section.Leg,
              })
            }
          }
          if (section.PTRide) {
            let segment = getItinerarySegments(section)
            if (segment) {
              let mergedSegment = [].concat.apply([], segment)
              if (linesIconsData) {
                let iconData = getLineIcon(section.PTRide.Line.Number, linesIconsData)
                allTripSections.push({
                  rideSegmentCoordinates: mergedSegment,
                  rideSegmentDetails: section.PTRide,
                  lineIcon: iconData,
                })
              } else {
                allTripSections.push({
                  rideSegmentCoordinates: mergedSegment,
                  rideSegmentDetails: section.PTRide,
                  lineIcon: null,
                })
              }
            }
          }
          return allTripSections
        })
        let tripTitle = trip.TripTitle
        return { tripType: tripTitle, sections: sections, fullTripData: trip }
      })
      return trips
    }
  }
}

export function getItinerarySegments(section) {
  let segments = []
  if (section.PTRide) {
    let positions
    section.PTRide.steps.Step.map(x => {
      let step = x.Geometry.split("(").pop().split(")")[0]
      positions = step.split(",")
      let stepPositions = positions.map(pos => {
        let cleanCoordinates = pos.trim().split(" ")
        return cleanCoordinates.map(y => {
          return parseFloat(y)
        })
      })
      if (stepPositions) {
        segments.push(stepPositions)
      }
    })
    return segments
  }
  return null
}

export function getItineraryWalkingSegments(section) {
  let segments = []
  if (section.Leg) {
    let positions
    section.Leg.pathLinks.PathLink.map(walkSegment => {
      if (walkSegment.Geometry) {
        let step = walkSegment.Geometry.split("(").pop().split(")")[0]
        positions = step.split(",")

        let stepPositions = positions.map(position => {
          let cleanCoordinates = position.trim().split(" ")
          return cleanCoordinates.map(coordinates => {
            return parseFloat(coordinates)
          })
        })
        if (stepPositions) {
          segments.push(stepPositions)
        }
      } else {
        segments = [
          [walkSegment.Arrival.Site.Position.Lat, walkSegment.Arrival.Site.Position.Long],
          [walkSegment.Departure.Site.Position.Lat, walkSegment.Departure.Site.Position.Long],
        ]
      }
    })
    return segments
  }
  return null
}
