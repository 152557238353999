import * as inputsStyles from "../inputs.module.scss"
import React, { ReactElement, useState } from "react"
import { Control } from "react-hook-form/dist/types"
import { Controller } from "react-hook-form"
import DatePicker from "react-datepicker"
import * as config from "../../../../config"
import { getYear, getMonth } from "date-fns"

type RenderProps = {
  id?: string
  name: string
  required?: boolean
  className?: string
  hasErrors?: boolean
  minDate?: Date
  maxDate?: Date
  previousMonthAlt?: string
  nextMonthAlt?: string
  isItineraryForm?: boolean
  onFocus?: (value: any) => void
  autocomplete?: boolean
} & (
  | {
      valueSelected?: string
      onChange?: (value: string) => void
      control: Control
    }
  | { valueSelected: string; onChange: (value: string) => void; control?: Control }
)

const DateInput: React.FC<RenderProps> = ({
  id,
  name,
  required,
  className = "",
  valueSelected,
  onChange,
  control,
  hasErrors,
  minDate,
  maxDate,
  previousMonthAlt,
  nextMonthAlt,
  onFocus,
  isItineraryForm,
  autocomplete,
}) => {
  const [startDate, setStartDate] = useState(new Date())
  const years = Array.from(new Array(101), (elemValue, index) => getYear(new Date()) - index)

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  return wrapWithController(name, control, valueSelected, ({ handleChange, handleBlur, value }) =>
    id != "birthdateField" ? (
      <DatePicker
        id={id}
        name={name}
        className={inputsStyles.input + " " + (hasErrors ? inputsStyles.fieldInError + " " : "") + className}
        // condition true == only for Aixenbus subsidiary Le Pilote itinerary search
        selected={!isItineraryForm ? value : value || new Date()}
        onChange={val => {
          handleChange(val)
          onChange && onChange(val)
        }}
        onBlur={handleBlur}
        dateFormat={config.date_format[config.locale]}
        minDate={minDate}
        maxDate={maxDate}
        required={required}
        autoComplete={autocomplete ? name : undefined}
        onFocus={event => {
          onFocus && onFocus(event.target.id)
        }}
      />
    ) : (
      <DatePicker
        className={inputsStyles.input + " " + (hasErrors ? inputsStyles.fieldInError + " " : "") + className}
        onFocus={event => {
          onFocus && onFocus(event.target.id)
        }}
        maxDate={maxDate}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} title={previousMonthAlt}>
              {"<"}
            </button>
            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            >
              {months.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} title={nextMonthAlt}>
              {">"}
            </button>
          </div>
        )}
        selected={startDate}
        onChange={date => setStartDate(date)}
        autoComplete={autocomplete ? name : undefined}
      />
    )
  )
}

function wrapWithController<T>(
  name: string,
  control: Control | null,
  value: T | null,
  renderInput: ({ handleChange, handleBlur, value }) => ReactElement
) {
  if (!control) {
    return renderInput({ handleChange: () => {}, handleBlur: () => {}, value })
  }
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) =>
        renderInput({
          handleChange: onChange,
          handleBlur: onBlur,
          value: value,
        })
      }
    />
  )
}

export default DateInput
