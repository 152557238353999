import React, { useState, useRef } from "react"
import * as styles from "./accordion.module.scss"
import Chevron from "./chevron"

type Props = {
  title: string
  children?: React.ReactNode
}

const Accordion: React.FC<Props> = ({ title, children }) => {
  const [isActive, setActiveState] = useState(false)
  const [setHeight, setHeightState] = useState("0px")
  const accordionContent = useRef(null)

  function toggleAccordion() {
    setActiveState(!isActive)
    setHeightState(isActive === true ? "0px" : `${accordionContent.current.scrollHeight}px`)
  }

  return (
    title && (
      <li className={styles.accordionSection}>
        <button
          className={isActive ? `${styles.accordion} ${styles.active}` : `${styles.accordion}`}
          onClick={toggleAccordion}
          aria-expanded={isActive}
        >
          <span className={styles.accordionTitle}>{title}</span>
          <Chevron
            className={isActive ? `${styles.accordionIcon} ${styles.rotate}` : `${styles.accordionIcon}`}
            width={16}
            fill={"#786E64"}
          />
        </button>
        <div ref={accordionContent} style={{ maxHeight: `${setHeight}` }} className={styles.accordionContent}>
          <ul className={styles.accordionText}>{children}</ul>
        </div>
      </li>
    )
  )
}

export default Accordion
