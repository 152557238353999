import React from "react"
import * as styles from "./title-banner.module.scss"
import sanitizeHtml from "sanitize-html"

type RenderProps = {
  title: string
}

const TitleBanner: React.FC<RenderProps> = ({ title }) => {
  title = title.replace("<p>", "<span>").replace("</p>", "</span>")
  const sanitizedTitle = sanitizeHtml(title)

  return (
    <div className={styles.titleBannerBox}>
      <h1 className={styles.titleBanner} dangerouslySetInnerHTML={{ __html: sanitizedTitle }}></h1>
    </div>
  )
}

export default TitleBanner
