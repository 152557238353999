declare global {
  interface Window {
    dataLayer: any[]
  }
}

export class InteractionEvent {
  eventObject: Object

  constructor(event: string, category: string, action: string, label: string) {
    this.eventObject = {
      event: event,
      e_category: category,
      e_action: action,
      e_label: label,
    }
  }

  pushInteractionEvent(dataLater) {
    dataLater.push(this.eventObject)
  }
}

export function pushGtmEvent(eventName, eventCategory, eventAction, eventLabel) {
  let dataLayer = typeof window !== "undefined" && window.dataLayer ? window.dataLayer : []
  const eventObject = new InteractionEvent(eventName, eventCategory, eventAction, eventLabel)
  eventObject.pushInteractionEvent(dataLayer)
}

export class PageEvent {
  eventObject: Object
  constructor(
    event: string,
    language: string,
    urlOriginal: string,
    siteType: string,
    workEnvironment: string,
    pageName: string,
    hostname: string,
    page_Error: string,
    pageCategory: string,
    pageSubCategory: string,
    numberOfSearchResults?: number
  ) {
    this.eventObject = {
      event: event,
      language: language,
      urlOriginal: urlOriginal,
      siteType: siteType,
      workEnvironment: workEnvironment,
      pageName: pageName,
      hostname: hostname,
      page_Error: page_Error,
      pageCategory: pageCategory,
      pageSubCategory: pageSubCategory,
      ...(numberOfSearchResults != null && {
        numberOfSearchResults: numberOfSearchResults,
      }),
    }
  }
  pushPageEvent(dataLater) {
    dataLater.push(this.eventObject)
  }
}

export function pushGtmPageEvent(
  event,
  language,
  urlOriginal,
  siteType,
  workEnvironment,
  pageName,
  hostname,
  page_Error,
  pageCategory,
  pageSubCategory,
  numberOfSearchResults?
) {
  let dataLayer = typeof window !== "undefined" && window.dataLayer ? window.dataLayer : []
  const eventObject = new PageEvent(
    event,
    language,
    urlOriginal,
    siteType,
    workEnvironment,
    pageName,
    hostname,
    page_Error,
    pageCategory,
    pageSubCategory,
    numberOfSearchResults
  )
  eventObject.pushPageEvent(dataLayer)
}
