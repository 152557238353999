// extracted by mini-css-extract-plugin
export var container = "info-traffic-detail-module--container--bb7fa";
export var corpus = "info-traffic-detail-module--corpus--423e7";
export var image = "info-traffic-detail-module--image--d1156";
export var infoTrafficImage = "info-traffic-detail-module--infoTrafficImage--89e06";
export var linePicto = "info-traffic-detail-module--linePicto--e39ca";
export var linePictoBox = "info-traffic-detail-module--linePictoBox--b3eda";
export var linesPictoContainer = "info-traffic-detail-module--linesPictoContainer--da1eb";
export var listNoStyle = "info-traffic-detail-module--listNoStyle--82153";
export var pictoBoxTraffic = "info-traffic-detail-module--pictoBoxTraffic--45eec";
export var shortDescription = "info-traffic-detail-module--shortDescription--2e38f";
export var svgImage = "info-traffic-detail-module--svgImage--3e272";
export var title = "info-traffic-detail-module--title--d711d";
export var titleBox = "info-traffic-detail-module--titleBox--746c2";