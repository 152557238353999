import { graphql } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { ReactSVG } from "react-svg"
import {
  SchedulesSearchBlockFieldsFragment,
  SchedulesSearchBlockLineFieldsFragment,
  SchedulesSearchBlockMunicipalityFieldsFragment,
} from "../../../../graphql-types"
import { timesheet } from "../../../../static/images"
import { pushGtmEvent } from "../../../gtm/interaction-event"
import Autocomplete from "../../autocomplete/autocomplete"
import * as inputsStyles from "../../layout/inputs.module.scss"
import * as blockStyles from "../custom-block/custom-block.module.scss"
import * as globalBlockStyles from "../main-blocks/main-blocks.module.scss"
import * as styles from "./schedules-search-block.module.scss"

type RenderProps = {
  block: SchedulesSearchBlockFieldsFragment
  lines: SchedulesSearchBlockLineFieldsFragment[]
  intersectionRatioCallback: (ratio: number) => void
  locale: string
  defaultLocale: string
}

type Line = SchedulesSearchBlockLineFieldsFragment
type Municipality = SchedulesSearchBlockMunicipalityFieldsFragment

const SchedulesSearchBlock: React.FC<RenderProps> = ({
  block,
  lines,
  intersectionRatioCallback,
  locale,
  defaultLocale,
}) => {
  const component = useRef<HTMLFormElement>()
  const observer = useRef<IntersectionObserver>()
  useEffect(() => {
    if (!("IntersectionObserver" in window)) {
      return
    }
    const options = { threshold: [0.2, 0.4, 0.6, 0.8, 1.0] }
    observer.current = new IntersectionObserver(entries => {
      intersectionRatioCallback(entries[entries.length - 1].intersectionRatio)
    }, options)
    observer.current.observe(component.current)
    return () => observer.current.disconnect()
  }, [])

  const search = <T,>(query: string, elements: T[], toLabelFn: (element: T) => string): T[] => {
    if (elements) {
      return elements.filter(result => toLabelFn(result).toLowerCase().indexOf(query.toLowerCase()) !== -1)
    }
  }
  const toLabel = element => (element ? element.name : "")

  const [selectedLine, setSelectedLine] = useState(null)
  const [selectedMunicipality, setSelectedMunicipality] = useState(null)
  const [municipalityError, setMunicipalityError] = useState(false)
  const [lineError, setLineError] = useState(false)

  const linesWithSchedulesOrPlan = lines.filter(line => line.schedules.length > 0 || line.lineMap)

  const searchLines = query => {
    const filteredLines = filterLines(linesWithSchedulesOrPlan, selectedMunicipality)
    if (!selectedLine) {
      setLineError(true)
    }
    return search(query, filteredLines, toLabel)
  }

  const searchMunicipalities = query => {
    const filteredMunicipalities = filterMunicipalities(linesWithSchedulesOrPlan, selectedLine)
    if (!selectedMunicipality) {
      setMunicipalityError(true)
    }
    return search(query, filteredMunicipalities, toLabel)
  }

  useEffect(() => {
    if (selectedLine) {
      setLineError(false)
    }
  }, [selectedLine])

  useEffect(() => {
    if (selectedMunicipality) {
      setMunicipalityError(false)
    }
  }, [selectedMunicipality])

  const slugLanguagePrefix = locale === defaultLocale ? "" : `/${locale}`
  return (
    <form
      className={`${blockStyles.block} ${globalBlockStyles.mainBlocksCard}`}
      ref={component}
      method="get"
      action={`${slugLanguagePrefix}/${block.resultsPage.slug}#schedules-search-results`}
    >
      <div className={styles.blockHeader}>
        <ReactSVG
          src={timesheet}
          className={styles.blockHeaderPicto}
          beforeInjection={svg => svg.setAttribute("aria-hidden", "true")}
        />
        <h3 className={blockStyles.title}>{block.title}</h3>
      </div>
      <div className={styles.form}>
        {/* Lines */}
        {block.searchType !== "Par municipalité" && (
          <div className={styles.formPart}>
            <div className={styles.field}>
              <label htmlFor="linesInput">{block.lineFieldLabel}</label>
              <Autocomplete
                onChange={setSelectedLine}
                suggestFunction={searchLines}
                toLabel={toLabel}
                id="linesInput"
                placeholder={block.lineFieldPlaceholder}
                title={block.lineFieldPlaceholder}
              />
              <input type="hidden" name="line" value={selectedLine?.id || ""} />
              <div className={styles.errorMessage}>{lineError ? block.lineErrorMessage : null}</div>
            </div>
          </div>
        )}

        {/* Municipalities */}
        {block.searchType !== "Par ligne" && (
          <div className={styles.formPart}>
            <div className={styles.field}>
              <label htmlFor="municipalitiesInput">{block.municipalityFieldLabel}</label>
              <Autocomplete
                onChange={setSelectedMunicipality}
                suggestFunction={searchMunicipalities}
                toLabel={toLabel}
                id="municipalitiesInput"
                placeholder={block.municipalityFieldPlaceholder}
                title={block.municipalityFieldPlaceholder}
              />
              <input type="hidden" name="municipality" value={selectedMunicipality?.id || ""} />
              <div className={styles.errorMessage}>{municipalityError ? block.municipalityErrorMessage : null}</div>
            </div>
          </div>
        )}
      </div>

      <button
        className={`${styles.searchButton} ${inputsStyles.primaryButton}`}
        type="submit"
        onClick={() =>
          pushGtmEvent(
            "eventga",
            "Homepage - Schedule Search",
            selectedLine?.name || "",
            selectedMunicipality?.name || ""
          )
        }
      >
        {block.searchButtonLabel}
      </button>
    </form>
  )
}

const filterMunicipalities = (lines: Line[], selectedLine: Line | null): Municipality[] => {
  return Array.from(
    lines
      .reduce((municipalities, line) => {
        if (selectedLine && selectedLine.id !== line.id) {
          return municipalities
        }
        line.municipalities.forEach(municipality => municipalities.set(municipality.id, municipality))
        return municipalities
      }, new Map<string, Municipality>())
      .values()
  ).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
}

const filterLines = (lines: Line[], selectedMunicipality: Municipality | null): Line[] => {
  return lines.filter(line =>
    line.municipalities.some(municipality => !selectedMunicipality || municipality.id === selectedMunicipality.id)
  )
}

export const fragments = graphql`
  fragment SchedulesSearchBlockFields on DatoCmsSchedulesSearchBlock {
    __typename
    id
    searchType
    title
    municipalityFieldLabel
    municipalityFieldPlaceholder
    lineFieldLabel
    lineFieldPlaceholder
    searchButtonLabel
    resultsPage {
      slug
    }
    lineErrorMessage
    municipalityErrorMessage
  }

  fragment SchedulesSearchBlockLineFields on DatoCmsLine {
    id
    name
    municipalities {
      ...SchedulesSearchBlockMunicipalityFields
    }
    schedules {
      id
    }
    lineMap {
      url
    }
  }

  fragment SchedulesSearchBlockMunicipalityFields on DatoCmsMunicipality {
    id
    name
  }
`

export default SchedulesSearchBlock
