declare global {
  interface Window {
    SpeechRecognition: any
    webkitSpeechRecognition: any
    SpeechGrammarList: any
    webkitSpeechGrammarList: any
  }
}

let mic: any | boolean = false
if (typeof window !== "undefined") {
  if (window) {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
    if (SpeechRecognition) {
      mic = new SpeechRecognition()
      mic.continuous = false
      mic.interimResults = true
      mic.finalTranscript = ""
      mic.lang = "fr-FR"
    }
  }
}

export const useSpeechToText = () => {
  return mic
}
