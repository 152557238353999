import React, { useEffect } from "react"
import * as styles from "./breadcrumb.module.scss"
import { HeaderMenuFieldsFragment } from "../../../../graphql-types"
import sanitizeHtml from "sanitize-html"
import Chevron from "../../layout/header/side-menu/accordion/chevron"

type RenderProps = {
  menuData: HeaderMenuFieldsFragment
  pageTitle: string
  pageSlug?: string
  locale: string
  defaultLocale: string
}

export let pageInfo: {
  parent: string
  selected: string
  parentSlug: string
} = { parent: null, selected: null, parentSlug: null }

const Breadcrumb: React.FC<RenderProps> = ({ menuData, pageTitle, pageSlug, locale, defaultLocale }) => {
  const sanitizedTitle = sanitizeHtml(pageTitle)
  const homeLabel = "Accueil"

  const pages: {
    parent: string
    selected: string
    parentSlug: string
  } = { parent: null, selected: null, parentSlug: null }

  const searchInSubMenu = () => {
    menuData.column1.forEach(subMenu => {
      if (subMenu.page && pageSlug === subMenu.page.slug) {
        pages.parent = menuData.label
        pages.parentSlug = menuData.page ? menuData.page.slug : menuData.externalPage
        pages.selected = subMenu.label
      }
    })
    menuData.column2.forEach(subMenu => {
      if (subMenu.page && pageSlug === subMenu.page.slug) {
        pages.parent = menuData.label2
        pages.parentSlug = menuData.page2 ? menuData.page2.slug : menuData.externalPage2
        pages.selected = subMenu.label
      }
    })
    menuData.column3.forEach(subMenu => {
      if (subMenu.page && pageSlug === subMenu.page.slug) {
        pages.parent = menuData.label3
        pages.parentSlug = menuData.page3 ? menuData.page3.slug : menuData.externalPage3
        pages.selected = subMenu.label
      }
    })
    menuData.column4.forEach(subMenu => {
      if (subMenu.page && pageSlug === subMenu.page.slug) {
        pages.parent = menuData.label4
        pages.parentSlug = menuData.page4 ? menuData.page4.slug : menuData.externalPage4
        pages.selected = subMenu.label
      }
    })
    menuData.column5.forEach(subMenu => {
      if (subMenu.page && pageSlug === subMenu.page.slug) {
        pages.parent = menuData.label5
        pages.parentSlug = menuData.page5 ? menuData.page5.slug : menuData.externalPage5
        pages.selected = subMenu.label
      }
    })
  }

  switch (pageSlug) {
    case menuData.page && menuData.page.slug:
      pages.selected = menuData.label
      break
    case menuData.page2 && menuData.page2.slug:
      pages.selected = menuData.label2
      break
    case menuData.page3 && menuData.page3.slug:
      pages.selected = menuData.label3
      break
    case menuData.page4 && menuData.page4.slug:
      pages.selected = menuData.label4
      break
    case menuData.page5 && menuData.page5.slug:
      pages.selected = menuData.label5
      break
  }

  if (!pages.selected) {
    searchInSubMenu()
  }

  useEffect(() => {
    pageInfo = pages
  }, [])

  return (
    <nav role="navigation" aria-label="Position dans le site" className={styles.accessNav}>
      <ul className={styles.breadcrumb}>
        <li className={styles.breadcrumbList}>
          <a href={locale === defaultLocale ? "/" : `/${locale}`} className={styles.label}>
            {homeLabel}
          </a>
          <div className={styles.chevronBox}>
            <Chevron aria-hidden={true} className={styles.chevron} />
          </div>
        </li>

        {(pages.parent || pages.selected) && (
          <li className={styles.breadcrumbList}>
            {pages.parent ? (
              <a
                href={locale === defaultLocale ? `/${pages.parentSlug}` : `/${locale}/${pages.parentSlug}`}
                className={styles.label}
              >
                {pages.parent}
              </a>
            ) : (
              <span className={styles.selectedLabel}>{pages.selected}</span>
            )}
            {pages.parent && (
              <div className={styles.chevronBox}>
                <Chevron aria-hidden={true} className={styles.chevron} />
              </div>
            )}
          </li>
        )}

        {pages.parent && (
          <li>
            <span className={styles.selectedLabel}>{pages.selected}</span>
          </li>
        )}

        {!pages.parent && !pages.selected && (
          <li>
            <div className={styles.selectedLabel} dangerouslySetInnerHTML={{ __html: sanitizedTitle }} />
          </li>
        )}
      </ul>
    </nav>
  )
}

export default Breadcrumb
