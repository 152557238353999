import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import {
  LePiloteInfoTrafficLinesIconsFieldsFragment,
  MainBlocksCardColorsFieldsFragment,
  MainBlocksFieldsFragment,
  SchedulesSearchBlockLineFieldsFragment,
  SecondaryBlocksLabelFieldsFragment,
} from "../../../../graphql-types"

import { useIsMobile } from "../../../hooks"

import CustomBlock from "../../home/custom-block/custom-block"
import LePiloteItineraryBlock from "../le-pilote-itinerary-block/le-pilote-itinerary-block"
import LePiloteNextTripsSearchBlock from "../le-pilote-next-trips-search-block/le-pilote-next-trips-search-block"
import LePiloteTrafficParentBlock from "../le-pilote-traffic-block/le-pilote-traffic-parent-block"
import SchedulesSearchBlock from "../schedules-search-block/schedules-search-block"
import TerDakarItinerarySearchBlock from "../ter-dakar-itinerary-search-block/ter-dakar-itinerary-search-block"
import TrafficParentBlock from "../traffic-block/traffic-parent-block"
import MainBlocksMobileTabs from "./main-blocks-mobile-tabs"
import InstantSystemWidgetBlock from "../instant-system-widget-block/instant-system-widget-block"
import * as styles from "./main-blocks.module.scss"

type RenderProps = {
  mainBlocks: MainBlocksFieldsFragment[]
  secondaryBlocksLabels: SecondaryBlocksLabelFieldsFragment
  colors: MainBlocksCardColorsFieldsFragment
  lines: SchedulesSearchBlockLineFieldsFragment[]
  assets: LePiloteInfoTrafficLinesIconsFieldsFragment[]
  locale: string
  defaultLocale: string
}

const MainBlocks: React.FC<RenderProps> = ({
  mainBlocks,
  secondaryBlocksLabels,
  colors: { mainBlockGradientColor, mainBlockShadowColor, mainBlockMobileTabsBackgroundColor },
  lines,
  assets,
  locale,
  defaultLocale,
}) => {
  const getHighlightedIndex = (intersection: { [p: number]: number }): number => {
    if (typeof window === "undefined" || !("IntersectionObserver" in window)) {
      return -1
    }
    const biggestRatio = Object.entries(intersection).reduce(
      (previousValue, [index, ratio]) => {
        if (ratio > previousValue.ratio) {
          return { ratio, index }
        }
        return previousValue
      },
      { index: 0, ratio: 0 }
    )
    return Number(biggestRatio.index)
  }

  const [intersectionRatios, setIntersectionRatios] = useState<{
    [key: number]: number
  }>({})
  const highlightedIndex = getHighlightedIndex(intersectionRatios)
  const intersectionRatioChanged = (index: number, ratio: number) => {
    setIntersectionRatios(previousIntersectionRatios => ({
      ...previousIntersectionRatios,
      [index]: ratio,
    }))
  }

  useEffect(() => {
    const root = document.documentElement
    root.style.setProperty("--main-block-gradient-color", mainBlockGradientColor.hex)
    root.style.setProperty("--main-block-shadow-color", `${mainBlockShadowColor.hex}66`)
    root.style.setProperty("--main-block-shadow-color-arrow", `${mainBlockShadowColor.hex}40`)
    root.style.setProperty("--main-block-mobile-tabs-background-color", mainBlockMobileTabsBackgroundColor.hex)
  }, [mainBlockGradientColor, mainBlockShadowColor, mainBlockMobileTabsBackgroundColor])

  const [activeBlock, setActiveBlock] = useState(mainBlocks[1].id)
  const isMobile: boolean = useIsMobile(920)

  return (
    <div className="container">
      <div className={styles.mainBlocks}>
        <MainBlocksMobileTabs mainBlocks={mainBlocks} onActiveTabChange={setActiveBlock} />

        {mainBlocks.map((block, index) => {
          if (isMobile && block.id !== activeBlock) {
            // On mobile, only display the active block
            return null
          }
          switch (block.__typename) {
            case "DatoCmsCustomBlockExternalPage":
            case "DatoCmsCustomBlockInternalPage":
              return (
                <CustomBlock
                  block={block}
                  isHighlighted={highlightedIndex === index}
                  intersectionRatioCallback={ratio => intersectionRatioChanged(index, ratio)}
                  key={block.id}
                />
              )
            case "DatoCmsSchedulesSearchBlock":
              return (
                <SchedulesSearchBlock
                  block={block}
                  lines={lines}
                  intersectionRatioCallback={ratio => intersectionRatioChanged(index, ratio)}
                  locale={locale}
                  defaultLocale={defaultLocale}
                  key={block.id}
                />
              )
            case "DatoCmsTrafficInfoBlock":
              return (
                <TrafficParentBlock
                  block={block}
                  isHighlighted={highlightedIndex === index}
                  intersectionRatioCallback={ratio => intersectionRatioChanged(index, ratio)}
                  labels={secondaryBlocksLabels}
                  key={block.id}
                />
              )
            case "DatoCmsLePiloteTrafficInfoBlock":
              return (
                <LePiloteTrafficParentBlock
                  block={block}
                  isHighlighted={highlightedIndex === index}
                  intersectionRatioCallback={ratio => intersectionRatioChanged(index, ratio)}
                  linesIconsData={assets}
                  labels={secondaryBlocksLabels}
                  key={block.id}
                />
              )
            case "DatoCmsTerDakarItinerarySearchBlock":
              return (
                <TerDakarItinerarySearchBlock
                  block={block}
                  isHighlighted={highlightedIndex === index}
                  intersectionRatioCallback={ratio => intersectionRatioChanged(index, ratio)}
                  key={block.id}
                />
              )
            case "DatoCmsLePiloteNextTripsSearchBlock":
              return (
                <LePiloteNextTripsSearchBlock
                  block={block}
                  intersectionRatioCallback={ratio => intersectionRatioChanged(index, ratio)}
                  key={block.id}
                />
              )
            case "DatoCmsLePiloteItineraryBlock":
              return (
                <LePiloteItineraryBlock
                  block={block}
                  intersectionRatioCallback={ratio => intersectionRatioChanged(index, ratio)}
                  key={block.id}
                />
              )
            case "DatoCmsInstantSystemWidgetBlock":
              return (
                <InstantSystemWidgetBlock
                  block={block}
                  intersectionRatioCallback={ratio => intersectionRatioChanged(index, ratio)}
                  key={block.id}
                />
              )
          }
        })}
      </div>
    </div>
  )
}

export const fragments = graphql`
  fragment MainBlocksFields on DatoCmsUnionForDatoCmsHomeMainBlocks {
    __typename
    ... on DatoCmsCustomBlockExternalPage {
      ...CustomBlockExternalPageFields
    }
    ... on DatoCmsCustomBlockInternalPage {
      ...CustomBlockInternalPageFields
    }
    ... on DatoCmsSchedulesSearchBlock {
      ...SchedulesSearchBlockFields
    }
    ... on DatoCmsTrafficInfoBlock {
      ...TrafficInfoBlockFields
    }
    ... on DatoCmsLePiloteTrafficInfoBlock {
      ...LePiloteTrafficInfoBlockFields
    }
    ... on DatoCmsLePiloteNextTripsSearchBlock {
      ...LePiloteNextTripsSearchBlockFields
    }
    ... on DatoCmsLePiloteItineraryBlock {
      ...LePiloteItineraryBlockFields
    }
    ... on DatoCmsTerDakarItinerarySearchBlock {
      ...TerDakarItinerarySearchBlockFields
    }
    ... on DatoCmsInstantSystemWidgetBlock {
      ...InstantSystemWidgetBlockFields
    }
  }

  fragment MainBlocksCardColorsFields on DatoCmsHome {
    mainBlockGradientColor {
      hex
    }
    mainBlockShadowColor {
      hex
    }
    mainBlockMobileTabsBackgroundColor {
      hex
    }
  }
`

export default MainBlocks
