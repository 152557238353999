import { graphql } from "gatsby"
import { CarouselProvider, Dot, Slide, Slider } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import React from "react"
import { CarouselSlideFieldsFragment } from "../../../../graphql-types"
import { pushGtmEvent } from "../../../gtm/interaction-event"
import { useInternalExternalLink } from "../../../hooks"
import * as styles from "./carousel.module.scss"

type RenderProps = {
  slides: CarouselSlideFieldsFragment[]
  label: string
  previousLabel: string
  nextLabel: string
  pauseLabel: string
  playLabel: string
  locale: string
  defaultLocale: string
}

const Carousel: React.FC<RenderProps> = ({ slides, label, locale, defaultLocale }) => {
  return (
    <CarouselProvider
      className={styles.carousel}
      naturalSlideWidth={800}
      naturalSlideHeight={300}
      totalSlides={slides.length}
      isPlaying={true}
      infinite={true}
      interval={5000}
    >
      <div className={styles.controls}>
        {slides.map((_, index) => (
          <Dot
            key={index}
            slide={index}
            onClick={() => pushGtmEvent("eventga", "Homepage", "Carousel", "Scroll Button Click")}
          />
        ))}
      </div>

      <Slider className={styles.sliderTray} aria-roledescription="carousel" aria-live={null} aria-label={label}>
        {slides.map((slide, index) => {
          const link = useInternalExternalLink(slide.internalExternalLink[0], locale, defaultLocale)
          return (
            <Slide
              className={styles.slide}
              key={index}
              index={index}
              role="option"
              aria-roledescription="slide"
              aria-label={`${index + 1} sur ${slides.length}`}
              aria-selected={null}
            >
              <a href={link} onClick={() => pushGtmEvent("eventga", "Homepage", "Carousel", link)}>
                <img src={slide.image.url} alt={slide.image.alt || ""} />
              </a>
            </Slide>
          )
        })}
      </Slider>
    </CarouselProvider>
  )
}

export const fragment = graphql`
  fragment CarouselSlideFields on DatoCmsSlide {
    image {
      alt
      url
    }
    internalExternalLink {
      ...InternalExternalLinkFields
    }
  }
`

export default Carousel
