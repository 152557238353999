import * as inputsStyles from "../inputs.module.scss"
import React from "react"
import { RegisterOptions, UseFormRegisterReturn } from "react-hook-form"

type RenderProps = {
  id?: string
  name: string
  required?: boolean
  className?: string
  placeholder?: string
  hasErrors?: boolean
  onFocus?: (value: any) => void
} & (
  | {
      value?: string
      onChange?: (value: string) => void
      register: (name: string, options?: RegisterOptions) => UseFormRegisterReturn
    }
  | { value: string; onChange: (value: string) => void; register: undefined }
)

const Textarea: React.FC<RenderProps> = ({
  id,
  name,
  required,
  className = "",
  placeholder = "",
  value,
  onChange,
  register,
  hasErrors,
  onFocus,
}) => {
  return (
    <textarea
      id={id}
      name={name}
      placeholder={placeholder}
      required={required}
      className={inputsStyles.textarea + " " + (hasErrors ? inputsStyles.fieldInError + " " : "") + className}
      onChange={event => onChange && onChange(event.target.value)}
      value={value}
      {...register(name, { required })}
      onFocus={event => {
        onFocus && onFocus(event.target.id)
      }}
    />
  )
}

export default Textarea
