import React from "react"
import * as styles from "./side-block.module.scss"
import { ReactSVG } from "react-svg"

type RenderProps = {
  image: string
  title: string
  link: string
  isInternal: boolean
}

const SideBlock: React.FC<RenderProps> = ({ image, title, link, isInternal }) => {
  return (
    <a className={styles.secondaryBlock} href={link} target={isInternal ? "_self" : "_blank"}>
      <ReactSVG
        src={image}
        className={styles.svgImage}
        beforeInjection={svg => svg.setAttribute("aria-hidden", "true")}
      />
      <span className={styles.link}>{title}</span>
    </a>
  )
}

export default SideBlock
