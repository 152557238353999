import * as inputsStyles from "../inputs.module.scss"
import React from "react"
import { RegisterOptions, UseFormRegisterReturn } from "react-hook-form"

type RenderProps = {
  id?: string
  name: string
  required?: boolean
  className?: string
  value?: string
  onChange?: (value: string) => void
  checked?: boolean
  label: string
  register?: (name: string, options?: RegisterOptions) => UseFormRegisterReturn
  hasErrors?: boolean
  onFocus?: (value: any) => void
}

const RadioButton: React.FC<RenderProps> = ({
  id,
  name,
  required,
  className = "",
  value,
  label,
  checked,
  onChange,
  register,
  hasErrors,
  onFocus,
}) => {
  return (
    <label className={inputsStyles.radioButton + (hasErrors ? " " + inputsStyles.fieldInError : "")}>
      {label}
      <input
        tabIndex={0}
        type="radio"
        id={id}
        name={name}
        required={required}
        className={className}
        onChange={() => onChange(value)}
        value={value}
        checked={checked}
        {...register(name, { required })}
        onFocus={event => {
          onFocus && onFocus(event.target.id)
        }}
      />
      <span tabIndex={0} className={inputsStyles.checkmark} />
    </label>
  )
}

export default RadioButton
