export const useIsToday = (min: number, max?: number) => {
  if (max) {
    const date = new Date()
    const start = new Date(min)
    const end = new Date(max)
    const startIso = start.toISOString()
    const endIso = end.toISOString()
    return date.toISOString() >= startIso && date.toISOString() <= endIso
  } else {
    const date = new Date()
    const start = new Date(min)
    date.setHours(23, 59, 59, 999)
    return date.getTime() >= start.getTime()
  }
}
