import React, { useRef, useState } from "react"

import * as styles from "./search-form.module.scss"
import * as inputsStyles from "../layout/inputs.module.scss"
import Input from "../layout/form/input"

type RenderProps = {
  query: string
  buttonLabel: string
  onChange: (value: string) => void
  locale: string
  defaultLocale: string
}

const SearchForm: React.FC<RenderProps> = ({ query, buttonLabel, onChange, locale, defaultLocale }) => {
  const [value, setValue] = useState(query)
  const buttonRef = useRef<HTMLButtonElement>()

  const handleSubmit = event => {
    onChange(value)
    buttonRef.current.blur()
    event.preventDefault()
  }

  return (
    <form
      action={locale === defaultLocale ? `/search` : `/${locale}/search`}
      method="get"
      className={styles.form}
      onSubmit={handleSubmit}
      role="search"
    >
      <Input type="text" name="query" value={value} onChange={setValue} className={styles.searchInput} />
      <button type="submit" className={`${inputsStyles.primaryButton} ${styles.searchButton}`} ref={buttonRef}>
        {buttonLabel}
      </button>
    </form>
  )
}

export default SearchForm
