import React from "react"
import { FooterMenuFieldsFragment, HeaderMenuFieldsFragment } from "../../../graphql-types"
import * as styles from "./website-map.module.scss"

type RenderProps = {
  menuData: HeaderMenuFieldsFragment & FooterMenuFieldsFragment
}

const WebsiteMap: React.FC<RenderProps> = ({ menuData }) => {
  const buildWebsiteMapMenu = (mainMenuLabel: string, mainMenuPage, submenu) => {
    const shouldDisplaySubmenu = (submenuItem): boolean => {
      return submenuItem.page && (submenuItem.headerDisplay || submenuItem.footerDisplay)
    }

    return (
      <li className={styles.menu}>
        {mainMenuPage && mainMenuPage.slug ? (
          <a className={styles.mainMenuLabel} href={`/${mainMenuPage.slug}`}>
            {mainMenuLabel}
          </a>
        ) : (
          <a className={styles.mainMenuLabel} href="#">
            {mainMenuLabel}
          </a>
        )}
        {submenu &&
          submenu.filter(shouldDisplaySubmenu).map(submenuItem => (
            <a key={submenuItem.id} className={styles.submenulabel} href={`/${submenuItem.page.slug}`}>
              {submenuItem.label}
            </a>
          ))}
      </li>
    )
  }

  return (
    <ul className={styles.websiteMap}>
      {(menuData.headerDisplay1 || menuData.footerDisplay1) &&
        buildWebsiteMapMenu(menuData.label, menuData.page, menuData.column1)}
      {(menuData.headerDisplay2 || menuData.footerDisplay2) &&
        buildWebsiteMapMenu(menuData.label2, menuData.page2, menuData.column2)}
      {(menuData.headerDisplay3 || menuData.footerDisplay3) &&
        buildWebsiteMapMenu(menuData.label3, menuData.page3, menuData.column3)}
      {(menuData.headerDisplay4 || menuData.footerDisplay4) &&
        buildWebsiteMapMenu(menuData.label4, menuData.page4, menuData.column4)}
      {(menuData.headerDisplay5 || menuData.footerDisplay5) &&
        buildWebsiteMapMenu(menuData.label5, menuData.page5, menuData.column5)}
    </ul>
  )
}

export default WebsiteMap
