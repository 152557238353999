import React from "react"
import { TypePageQuery } from "../../../../graphql-types"
import * as styles from "./news-blocks.module.scss"
import { format } from "date-fns"
import * as config from "../../../../config"

type RenderProps = {
  label: string
  news: TypePageQuery["allDatoCmsNews"]["nodes"]
  locale: string
  defaultLocale: string
}

const NewsBlocks: React.FC<RenderProps> = ({ news, label, locale, defaultLocale }) => {
  function _createNewsBlock(id: string, title: string, slug: string, publicationDate: string) {
    return (
      <li className={styles.newsBlock} key={id}>
        <a href={slug}>
          <div className={styles.newsDate}>{publicationDate}</div>
          <div className={styles.newsTitle}>{title}</div>
        </a>
      </li>
    )
  }

  return (
    <>
      <h2 className={styles.newsBlocksTitle}>{label}</h2>
      <ul className={styles.newsBlocks}>
        {news.map(newsItem => {
          // Error displayed by date-format => RangeError: Use `yyyy` instead of `YYYY` (in `dd/MM/YYYY`) for formatting years to the input
          const dateFormat =
            config.date_format[config.locale] === "dd/MM/YYYY" ? "dd/MM/yyyy" : config.date_format[config.locale]
          const publicationDate = format(new Date(newsItem.publicationDate), dateFormat)
          const slug = locale === defaultLocale ? `/news/${newsItem.slug}` : `/${locale}/news/${newsItem.slug}`

          return newsItem.title ? _createNewsBlock(newsItem.id, newsItem.title, slug, publicationDate) : null
        })}
      </ul>
    </>
  )
}

export default NewsBlocks
