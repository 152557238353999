import React from "react"
import { ScriptsFieldsFragment } from "../../graphql-types"

type RenderProps = {
  scriptData: ScriptsFieldsFragment
}

const LoadScripts: React.FC<RenderProps> = ({ scriptData }) => {
  return (
    <>
      {scriptData?.scripts?.map(script => {
        return script.elements.map(scriptPart => {
          switch (scriptPart.__typename) {
            case "DatoCmsScriptPartUrl":
              return <script src={scriptPart.url} key={scriptPart.id} />
            case "DatoCmsScriptPartJavascript":
              return (
                <script type="text/javascript" key={scriptPart.id}>
                  {scriptPart.js}
                </script>
              )
          }
        })
      })}
    </>
  )
}

export default LoadScripts
