import React, { useRef, useState } from "react"
import { ReactSVG } from "react-svg"
import sanitizeHtml from "sanitize-html"
import { cross } from "../../../../static/images"
import { ArrowLeft, ArrowRight } from "../../layout/icons"
import TrafficBlock from "./traffic-block"
import * as styles from "./traffic-blocks.module.scss"
import { LineRelatedToTraffic } from "./traffic-parent-block"

type RenderProps = {
  blocks: LineRelatedToTraffic[]
  label: string
  previousLabel: string
  nextLabel: string
  closeButtonLabel: string
}

const TrafficBlocks: React.FC<RenderProps> = ({ blocks, label, previousLabel, nextLabel, closeButtonLabel }) => {
  const container = useRef<HTMLUListElement>(null)
  const scrollToNextItem = () => {
    const itemWidth = container.current.children[0].clientWidth
    container.current.scrollBy({ left: itemWidth, top: 0, behavior: "smooth" })
  }
  const scrollToPreviousItem = () => {
    const itemWidth = container.current.children[0].clientWidth
    container.current.scrollBy({ left: -itemWidth, top: 0, behavior: "smooth" })
  }

  const [tab, setTab] = useState(true)
  const [trafficId, setTrafficId] = useState("")
  const openTrafficIdRef = useRef<HTMLDivElement>()

  const handleChildClick = (lineId: string) => {
    setTab(false)
    setTrafficId(lineId)
    setTimeout(focusEventTitle)
  }

  const focusEventTitle = () => {
    if (typeof window === "undefined" || !openTrafficIdRef.current) {
      return
    }
    openTrafficIdRef.current.focus()
  }

  const exitTrafficDetails = (keyDown?: boolean) => {
    setTab(true)
    if (keyDown) {
      setTimeout(() => focusPreviouslyOpenInfoTraffic(trafficId))
    }
  }

  const focusPreviouslyOpenInfoTraffic = (id: string) => {
    if (typeof window === "undefined" || !id) {
      return
    }
    document.getElementById(`traffic-block-button-${id}`).focus()
  }

  const splitArrayIntoChunksOfLen = (arr: LineRelatedToTraffic[], len: number) => {
    let chunks = [],
      i = 0,
      n = arr.length
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)))
    }
    return chunks
  }

  const perturbationGrids = splitArrayIntoChunksOfLen(blocks, 8)

  let trafficData: any

  perturbationGrids.forEach(element => {
    element.map(item => {
      if (item.line.id === trafficId) {
        trafficData = item
      }
    })
  })

  return tab ? (
    <div className={blocks.length > 8 ? styles.gridBlocks : styles.oneGridBlock}>
      {blocks.length > 8 && (
        <div className={styles.controls}>
          <button
            aria-label={previousLabel}
            title={previousLabel}
            onClick={scrollToPreviousItem}
            className={styles.customBlockArrows}
          >
            <ArrowLeft />
          </button>
        </div>
      )}

      <ul aria-labelledby={label} className={styles.secondaryBlocks} ref={container}>
        {perturbationGrids.map((block, index) => {
          return (
            <li className={styles.secondaryBlock} key={index}>
              <TrafficBlock block={block} onPerturbationClicked={handleChildClick} />
            </li>
          )
        })}
      </ul>
      {blocks.length > 8 && (
        <div className={styles.controls}>
          <button
            aria-label={nextLabel}
            title={nextLabel}
            onClick={scrollToNextItem}
            className={styles.customBlockArrows}
          >
            <ArrowRight />
          </button>
        </div>
      )}
    </div>
  ) : (
    <div className={styles.detailsCard}>
      <div className={styles.detailsCardHeader}>
        <div className={styles.detailsCardHeaderContent}>
          <img
            src={trafficData.line.pictogram.url}
            alt={trafficData.line.pictogram.alt || ""}
            className={styles.svgImage}
          />
          <h4 className={styles.detailsCardHeaderTitle} tabIndex={-1} ref={openTrafficIdRef}>
            {trafficData.line.name}
          </h4>
          <div className={styles.exitIconContainer}>
            <button
              className={styles.exitButton}
              onClick={() => exitTrafficDetails(false)}
              onKeyDown={() => exitTrafficDetails(true)}
            >
              <img src={cross} alt={closeButtonLabel} className={styles.exitIcon} />
            </button>
          </div>
        </div>
        <div className={styles.detailsCardBodyWrapper} tabIndex={0}>
          {trafficData.traffic.map((item, index) => {
            return (
              <div key={index}>
                <div className={styles.detailsCardBody}>
                  {item.pictogram.format === "svg" && (
                    <ReactSVG
                      src={item.pictogram.url}
                      className={styles.svgImage}
                      beforeInjection={svg => svg.setAttribute("aria-label", item.pictogram.alt)}
                      width={item.pictogram.width}
                      height={item.pictogram.height}
                    />
                  )}
                  <p className={styles.infoTrafficTitle}>{item.title}</p>
                </div>
                <div
                  className={styles.shortDescription}
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.shortDescription) }}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TrafficBlocks
