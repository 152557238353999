import * as inputsStyles from "../inputs.module.scss"
import React, { ReactElement } from "react"
import { Control } from "react-hook-form/dist/types"
import { Controller } from "react-hook-form"
import DatePicker from "react-datepicker"
import * as config from "../../../../config"

type RenderProps = {
  id?: string
  name: string
  required?: boolean
  className?: string
  hasErrors?: boolean
  isItineraryForm?: boolean
  timeValue?: Date
  onFocus?: (value: any) => void
} & (
  | {
      value?: string
      onChange?: (value: string) => void
      control: Control
    }
  | { value: string; onChange: (value: string) => void; control?: Control }
)

const TimeInput: React.FC<RenderProps> = ({
  id,
  name,
  required,
  className = "",
  value,
  onChange,
  control,
  hasErrors,
  onFocus,
  isItineraryForm,
  timeValue,
}) => {
  return wrapWithController(name, control, value, ({ handleChange, handleBlur, value }) => (
    <DatePicker
      id={id}
      name={name}
      className={inputsStyles.input + " " + (hasErrors ? inputsStyles.fieldInError + " " : "") + className}
      showTimeSelect
      showTimeSelectOnly
      // condition true == only for Aixenbus subsidiary Le Pilote itinerary search
      selected={!isItineraryForm ? value : value || timeValue}
      onChange={value => {
        handleChange(value)
        onChange && onChange(value)
      }}
      onBlur={handleBlur}
      dateFormat={config.time_format[config.locale]}
      required={required}
      autoComplete="off"
      onFocus={event => {
        onFocus && onFocus(event.target.id)
      }}
    />
  ))
}

function wrapWithController<T>(
  name: string,
  control: Control | null,
  value: T | null,
  renderInput: ({ handleChange, handleBlur, value }) => ReactElement
) {
  if (!control) {
    return renderInput({ handleChange: () => {}, handleBlur: () => {}, value })
  }
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) =>
        renderInput({
          handleChange: onChange,
          handleBlur: onBlur,
          value: value,
        })
      }
    />
  )
}

export default TimeInput
