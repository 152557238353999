import React, { useState, useEffect } from "react"
import * as styles from "./info-traffic-le-pilote.module.scss"
import { graphql } from "gatsby"
import { LePiloteInfoTrafficLinesIconsFieldsFragment } from "../../../graphql-types"
import sanitizeHtml from "sanitize-html"
import { getInfoTraffic, getLines } from "../../api/le-pilote/le-pilote-info-traffic-api"
import config from "../../../config"

type RenderProps = {
  linesIconsData: LePiloteInfoTrafficLinesIconsFieldsFragment[]
}

const InfoTrafficLePilote: React.FC<RenderProps> = ({ linesIconsData }) => {
  const [linesWithInfoTraffic, setLinesWithInfoTraffic] = useState([])

  useEffect(() => {
    getExternalData(config.locale)
  }, [])

  async function getExternalData(lang) {
    const lines = await getLines(lang)
    const traffic = await getInfoTraffic(lang)
    const formattedData = parseTrafficData(lines.Data, traffic.Data)
    setLinesWithInfoTraffic(formattedData)
  }

  function getAllAffectedLines(infoTrafficData) {
    const lines = []
    infoTrafficData.map(infoTraffic => {
      infoTraffic.DisruptedLines.map(line => {
        lines.push(line)
      })
    })
    return lines
  }

  function getInfoTrafficFormatted(disruptedLines, infoTrafficData, linesDetails) {
    const myLinesWithTraffic = []
    disruptedLines.map(line => {
      const infoTrafficArray = []
      infoTrafficData.map(infoTraffic => {
        infoTraffic.DisruptedLines.map(subline => {
          if (line.LineId == subline.LineId) {
            const completeLineData = linesDetails.find(x => x.Id == line.LineId)
            if (completeLineData) {
              const icone = getLineIcon(completeLineData.Code)
              completeLineData.icone = icone[0]
              infoTrafficArray.push(infoTraffic)
              myLinesWithTraffic.push({
                line: completeLineData,
                traffic: infoTrafficArray,
              })
            }
          }
        })
      })
    })
    return myLinesWithTraffic
  }

  function removeDuplicateLinesFromArray(infoTrafficFormatted) {
    return infoTrafficFormatted.filter(
      (ele, index) => index === infoTrafficFormatted.findIndex(elem => elem.line === ele.line)
    )
  }

  function removeDuplicateTrafficInfoFromArray(formattedTrafficWithUniqueLines) {
    return formattedTrafficWithUniqueLines.map(item => {
      return {
        line: item.line,
        traffic: item.traffic.filter(
          (element, index) => index === item.traffic.findIndex(elem => elem.Id === element.Id)
        ),
      }
    })
  }

  function parseTrafficData(linesDetails: Array<any>, infoTrafficData: Array<any>): Array<object> {
    const disruptedLines = getAllAffectedLines(infoTrafficData)
    const trafficWithLines = getInfoTrafficFormatted(disruptedLines, infoTrafficData, linesDetails)
    const formattedTrafficWithUniqueLines = removeDuplicateLinesFromArray(trafficWithLines)
    return removeDuplicateTrafficInfoFromArray(formattedTrafficWithUniqueLines)
  }

  function getLineIcon(lineCode) {
    const lineCodeLowercase = lineCode.toLowerCase()
    return linesIconsData.filter(x => x.basename.endsWith(`${lineCodeLowercase}`))
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{config.locale.info_traffic_content_title}</h3>
      {linesWithInfoTraffic.map(disruption => {
        return (
          <div className={styles.line} key={disruption.line.Id}>
            <div className={styles.pictoBoxTop}>
              {disruption.line.icone ? (
                <img src={disruption.line.icone.url} alt={disruption.line.Code} />
              ) : (
                disruption.line.Code
              )}
            </div>
            <ul className={styles.lineDivider}>
              {disruption.traffic.map(traffic => {
                return (
                  <li className={styles.trafficContainer} key={traffic.Id}>
                    <div className={styles.descriptionContainer}>
                      {traffic.Description ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(traffic.Description),
                          }}
                        />
                      ) : (
                        <h4>{traffic.Name}</h4>
                      )}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default InfoTrafficLePilote
export const fragments = graphql`
  fragment LePiloteInfoTrafficLinesIconsFields on DatoCmsAsset {
    id
    url
    basename
  }
`
