import React, { useEffect, useRef, useState } from "react"

import { AroundMeActigraphModuleFieldsFragment } from "../../../graphql-types"
declare global {
  interface Window {
    KWH: {
      container?: string
    }
  }
}

type RenderProps = {
  data: AroundMeActigraphModuleFieldsFragment
}

const AroundMeActigraph: React.FC<RenderProps> = ({ data }) => {
  const component = useRef<HTMLDivElement>()
  function useForceUpdate() {
    const [value, setValue] = useState(0)
    return () => setValue(value + 1)
  }
  const forceUpdate = useForceUpdate()
  useEffect(() => {
    const existingScript = document.getElementById("actigraphScript")
    if (!existingScript) {
      return
    }
    const insertedIframe = document.getElementById("widgetHoraires")
    if (!insertedIframe || insertedIframe.innerHTML == "") {
      forceUpdate()
    }
  })

  return (
    <div id="" className=" " ref={component}>
      <script
        type="text/javascript"
        id={"actigraph-0"}
        dangerouslySetInnerHTML={{
          __html: "window.KWH = {}; window.KWH.container='widgetHoraires';",
        }}
      ></script>
      <script src={data.src} async={true} type="text/javascript" id={"actigraphScript"}></script>
      <div id="widgetHoraires"></div>
    </div>
  )
}

export default AroundMeActigraph
