import React from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { InformationBannerFieldsFragment } from "../../../../graphql-types"
import * as styles from "./information-banner.module.scss"
import sanitizeHtml from "sanitize-html"
import { useInternalExternalLink } from "../../../hooks"

type RenderProps = {
  informationBanner: InformationBannerFieldsFragment
  locale: string
  defaultLocale: string
}

const InformationBanner: React.FC<RenderProps> = ({ informationBanner, locale, defaultLocale }) => {
  const sanitizedContent = sanitizeHtml(informationBanner.content)

  const bannerContent = (
    <>
      <div className={styles.picto}>
        {informationBanner.image.format === "svg" ? (
          <ReactSVG
            src={informationBanner.image.url}
            className={styles.imageSvg}
            beforeInjection={svg => svg.setAttribute("aria-label", informationBanner.image.alt || "")}
          />
        ) : (
          <img src={informationBanner.image.url} alt={informationBanner.image.alt || ""} className={styles.image} />
        )}
      </div>
      <div className={styles.text}>
        <h3 className={styles.title}>{informationBanner.title}</h3>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
      </div>
    </>
  )

  if (informationBanner.internalExternalLink.length > 0) {
    return (
      <a
        href={useInternalExternalLink(informationBanner.internalExternalLink[0], locale, defaultLocale)}
        className={styles.informationBanner}
      >
        {bannerContent}
      </a>
    )
  } else {
    return <section className={styles.informationBanner}>{bannerContent}</section>
  }
}

export const fragment = graphql`
  fragment InformationBannerFields on DatoCmsInformationBanner {
    id
    isActive
    image {
      alt
      format
      url
    }
    title
    internalExternalLink {
      ...InternalExternalLinkFields
    }
    content
    location
  }
`

export default InformationBanner
