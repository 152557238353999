import React, { useEffect, useState } from "react"
import { HomeNewsFieldsFragment } from "../../../../graphql-types"
import * as styles from "./news-list.module.scss"
import News from "./news"

type RenderProps = {
  data: HomeNewsFieldsFragment[]
  label: string
  locale: string
  defaultLocale: string
}

const NewsList: React.FC<RenderProps> = ({ data, label, locale, defaultLocale }) => {
  const [news, setNews] = useState<HomeNewsFieldsFragment[]>([])

  useEffect(() => {
    // Get the 3 latest news (publicationDate most recent) that are published
    const publishedNews = data
      .filter(news => isNewsPublished(news))
      .sort((a, b) => {
        const aDate = new Date(a.publicationDate).getTime()
        const bDate = new Date(b.publicationDate).getTime()
        return bDate - aDate
      })
      .slice(0, 3)

    setNews(publishedNews)
  }, [data])

  const isNewsPublished = (news: HomeNewsFieldsFragment) => {
    const todayDate: number = new Date().getTime()
    const publicationDate: number = new Date(news.publicationDate).getTime()
    const publicationStopDate: number = new Date(news.publicationStopDate).getTime()

    return !(
      (publicationStopDate && (todayDate < publicationDate || todayDate >= publicationStopDate)) ||
      todayDate < publicationDate
    )
  }

  return (
    <ul aria-labelledby={label} className={styles.newsList}>
      {news.map(news => (
        <li className={styles.news} key={news.id}>
          <News news={news} locale={locale} defaultLocale={defaultLocale} key={news.id} />
        </li>
      ))}
    </ul>
  )
}

export default NewsList
