import React from "react"
import { graphql } from "gatsby"
import { InfoTrafficDetailQuery } from "../../../graphql-types"
import * as styles from "../type-page/type-page.module.scss"
import { useFormatLocale } from "../../hooks"
import Layout from "../../components/layout/layout"
import TitleBanner from "../../components/type-page/title-banner/title-banner"
import Breadcrumb from "../../components/type-page/breadcrumb/breadcrumb"
import ShareButtons from "../../components/type-page/share-buttons/share-buttons"
import NewsBlocks from "../../components/type-page/news-blocks/news-blocks"
import SideBlocks from "../../components/type-page/side-blocks/side-blocks"
import InfoTrafficDetails from "../../components/info-traffic/info-traffic-detail"
import { isNewsPublished } from "../../components/all-news/all-news"
import { RelativeLanguagePaths } from "../../components/layout/header/header-tool-bar/language-selector/language-selector"
import LoadScripts from "../../components/load-scripts"

type RenderProps = {
  data: InfoTrafficDetailQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    relativeLanguagePaths: RelativeLanguagePaths
  }
}

const InfoTrafficDetailPage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const twitterDisplay: boolean = data.datoCmsGlobalConfiguration.twitterDisplay
  const facebookDisplay: boolean = data.datoCmsGlobalConfiguration.fbDisplay
  const sideBlocks = data.datoCmsGlobalConfiguration.sideBlocks
  const lastThreeNewsLabel: string = data.datoCmsGlobalConfiguration.lastNewsLabel
  const lastThreeNews = data.allDatoCmsNews.nodes.filter(isNewsPublished).slice(0, 3)

  const locale = useFormatLocale(pageContext.locale)
  const defaultLocale = useFormatLocale(pageContext.defaultLocale)

  return (
    <Layout
      location={location}
      menuData={data.datoCmsMenu}
      footerData={data.datoCmsFooter}
      toolbarData={data.datoCmsGlobalConfiguration}
      scriptsData={data.datoCmsGlobalConfiguration}
      locale={locale}
      defaultLocale={defaultLocale}
      relativeLanguagePaths={pageContext.relativeLanguagePaths}
    >
      <div className={styles.typePage}>
        <TitleBanner title={data.datoCmsGlobalConfiguration.infoTrafficTitle} />
        <Breadcrumb
          menuData={data.datoCmsMenu}
          pageTitle={data.datoCmsInfoTraffic.title}
          pageSlug={data.datoCmsInfoTraffic.slug}
          locale={locale}
          defaultLocale={defaultLocale}
        />
        <div className={styles.typePageContent}>
          <div className={styles.content} style={{ width: data.datoCmsInfoTraffic.hideRightSidebar ? "100%" : "75%" }}>
            <InfoTrafficDetails data={data.datoCmsInfoTraffic} />
          </div>
          {!data.datoCmsInfoTraffic.hideRightSidebar && (
            <div className={styles.rightSidebar}>
              <ShareButtons facebookDisplay={facebookDisplay} twitterDisplay={twitterDisplay}></ShareButtons>
              <NewsBlocks
                news={lastThreeNews}
                label={lastThreeNewsLabel}
                locale={locale}
                defaultLocale={defaultLocale}
              />
              <SideBlocks blocks={sideBlocks} locale={locale} defaultLocale={defaultLocale} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data }) => {
  return <LoadScripts scriptData={data.datoCmsGlobalConfiguration} />
}

export const pageQuery = graphql`
  query InfoTrafficDetail($locale: String!, $id: String) {
    site {
      siteMetadata {
        title
      }
    }

    datoCmsMenu(locale: $locale) {
      ...HeaderMenuFields
      ...FooterMenuFields
      label
      label2
      label3
      label4
      column1 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
      column2 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
      column3 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
      column4 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
    }

    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }

    datoCmsGlobalConfiguration {
      id
      twitterDisplay
      fbDisplay
      infoTrafficTitle
      infoTrafficDetailSlug
      sideBlocks {
        ...SideBlockFields
      }
      ...AllNewsModuleConfigurationFields
      ...HeaderToolBarFields
      ...ScriptsFields
    }

    allDatoCmsNews(locale: $locale, sort: { publicationDate: DESC }) {
      nodes {
        id
        title
        slug
        publicationDate
        publicationStopDate
      }
    }
    datoCmsInfoTraffic(locale: $locale, id: { eq: $id }) {
      model {
        name
      }
      id
      title
      slug
      shortDescription
      description {
        ... on DatoCmsSimpleText {
          __typename
          id
          content
        }
        ... on DatoCmsCustomText {
          __typename
          id
          customContent {
            blocks
            links {
              __typename
              ... on DatoCmsPage {
                id: originalId
                slug
                title
              }
              ... on DatoCmsHome {
                id: originalId
                title
              }
              ... on DatoCmsDocument {
                id: originalId
                title
                document {
                  id
                  url
                  alt
                  title
                }
              }
            }
            value
          }
        }
        ... on DatoCmsTextWithImage {
          __typename
          id
          text
          image {
            alt
            format
            url
          }
        }
      }
      lines {
        id
        pictogram {
          alt
          format
          url
        }
      }
      pictogram {
        alt
        url
        format
      }
      hideRightSidebar
    }
  }
`

export default InfoTrafficDetailPage
