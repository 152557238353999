import {
  CustomFormFieldsFragment,
  CustomFormsConfigurationFieldsFragment,
  DatoCmsDatoCmsFormUserEmailContentStructuredText,
  DatoCmsDatoCmsFormSubsidiaryEmailContentStructuredText,
} from "../../../graphql-types"
import { render } from "datocms-structured-text-to-html-string"
import { StructuredText as StructuredTextGraphQlResponse } from "datocms-structured-text-utils/dist/types/types"
import sanitizeHtml from "sanitize-html"
import { decode } from "html-entities"

export function formToText(
  configuration: CustomFormsConfigurationFieldsFragment,
  content: DatoCmsDatoCmsFormUserEmailContentStructuredText | DatoCmsDatoCmsFormSubsidiaryEmailContentStructuredText,
  fields: CustomFormFieldsFragment["fieldsList"],
  values: { [key: string]: any }
) {
  const renderFormContent = (renderNode, renderText) => {
    return fields
      .filter(field => !["DatoCmsSimpleText", "DatoCmsFileField"].includes(field.__typename))
      .map(field => {
        switch (field.__typename) {
          case "DatoCmsSingleLineTextField":
          case "DatoCmsRadioButtonsField":
          case "DatoCmsSelectField":
          case "DatoCmsUserEmailField":
          case "DatoCmsMultiLinesTextField":
            return {
              label: decode(cleanHtmlTags(field.name)),
              value: decode(cleanHtmlTags(values[field.id])),
            }
          case "DatoCmsCheckboxField":
            return {
              label: decode(cleanHtmlTags(field.name)),
              value: values[field.id] ? "✓" : "✖︎",
            }
          default:
            return { label: "Unhandled field type", value: field.__typename }
        }
      })
      .map(({ label, value }, index) => {
        // Alternate row background color
        const backgroundColor = index % 2 === 0 ? "#f4f4f4" : "#ffffff"
        const trStyle = `background-color: ${backgroundColor}; font-family: "Raleway", Arial, sans-serif; font-size: 16px; ;`
        const thStyle = "padding: 8px; text-align: left; font-weight: 400; max-width:400px"
        const tdStyle = "padding: 8px; text-align: left; font-weight: 600;"
        return renderNode("tr", { style: trStyle }, [
          renderNode("th", { style: thStyle }, renderText(label)),
          renderNode("td", { style: tdStyle }, renderText(value)),
        ])
      })
  }
  const tableStyle = `border-spacing: 0; font-family: "Roboto Slab Medium", Arial, serif; font-size: 16px`
  const options = {
    renderBlock(block) {
      if (block.fragmentType === "Email content") {
        return block.renderNode(
          "table",
          { style: tableStyle },
          renderFormContent(block.adapter.renderNode, block.adapter.renderText)
        )
      }
      console.log("Unhandled fragment: " + block.fragmentType)
    },
    renderInlineRecord({ record: block, adapter: { renderNode, renderText } }) {
      if (block.fragmentType === "Form content") {
        return renderNode("table", { style: tableStyle }, renderFormContent(renderNode, renderText))
      }
      console.log("Unhandled fragment: " + block.fragmentType)
    },
  }
  const emailBody = render(content as unknown as StructuredTextGraphQlResponse, options)
  // Add the subsidiary logo at the bottom of the email
  if (configuration.logoSubsidiary) {
    const logo = `<img src="${configuration.logoSubsidiary.url}" alt="${configuration.logoSubsidiary.alt}" style="max-width: 150px; margin-top: 50px; margin-bottom: 20px;"/>`
    return emailBody + logo
  }
  return emailBody
}

function cleanHtmlTags(value: string) {
  return sanitizeHtml(value, {
    allowedTags: [],
    allowedAttributes: {},
  })
}
