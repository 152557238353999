import React, { useEffect, useState } from "react"
import RichDatoCmsContent from "../rich-datocms-content/rich-datocms-content"
import * as styles from "./info-traffic-detail.module.scss"
import sanitizeHtml from "sanitize-html"
import { useQuerySubscription } from "react-datocms"
import config from "../../../config.js"

type RenderProps = {}

const InfoTrafficDetailModule: React.FC<RenderProps> = () => {
  const [info, setInfo] = useState(null)
  const [dataInfo, setData] = useState(null)
  const [trafficId, setTrafficId] = useState(null)

  useEffect(() => {
    let id
    if (!trafficId) {
      if (window.location.hash) {
        const queryString = window.location.hash
        if (queryString) {
          id = queryString.split("#")[1]
          setTrafficId(id)
        }
      }
    }
  }, [trafficId])

  useEffect(() => {
    if (dataInfo) {
      const parsedInfo = dataInfo.infoTraffic
      setInfo(parsedInfo)
    }
  }, [dataInfo])

  const { data } = useQuerySubscription({
    enabled: !!trafficId,
    query: `
      query infoQuery {
        infoTraffic(locale: ${config.locale}, filter: {id: {eq: "${trafficId}"}}) {
          id
          title
          disruptionType
          pictogram {
            url
            alt
            format
          }
          lines {
            id
            name
            number
            pictogram {
              url
              alt
              format
            }     
          }
          shortDescription
          slug
          description {
            ... on SimpleTextRecord {
              __typename
              id
              content
            }
            ... on CustomTextRecord {
              __typename
              id
              customContent {
                blocks
                links {
                  __typename
                  ... on PageRecord {
                    id
                    slug
                    title
                  }
                  ... on HomeRecord {
                    id
                    title
                  }
                  ... on DocumentRecord {
                    id
                    title
                    document {
                      id
                      url
                      alt
                      title
                    }       
                  }
                }
                value
              }
            }
            ... on TextWithImageRecord {
              __typename
              id
              text
              image {
                format
                url
                alt
              }
            }
          }
        }
      }`,
    token: config.readOnlyApi,
  })

  if (data) {
    if (data != dataInfo) {
      setData(data)
    }
  }

  return (
    info && (
      <div className={styles.container}>
        <ul className={styles.linesPictoContainer}>
          {info &&
            info.lines.map(line => {
              return (
                <li key={line.id} className={styles.listNoStyle}>
                  <div className={styles.linePictoBox}>
                    <img className={styles.linePicto} src={line.pictogram.url} alt={line.pictogram.alt} />
                  </div>
                </li>
              )
            })}
        </ul>
        <div className={styles.titleBox}>
          <div className={styles.pictoBoxTraffic}>
            <img src={info.pictogram.url} alt={info.pictogram.alt} />
          </div>
          <h3 className={styles.title}>{info.title}</h3>
        </div>

        <div
          className={styles.shortDescription}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(info.shortDescription),
          }}
        />
        <RichDatoCmsContent data={info.description}></RichDatoCmsContent>
      </div>
    )
  )
}

export default InfoTrafficDetailModule
