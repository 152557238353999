import React from "react"
import { ItineraryModuleConfigurationFieldsFragment } from "../../../graphql-types"
import { graphql } from "gatsby"
import * as styles from "./itinerary-module.module.scss"

type RenderProps = {
  label: string
  moduleConfiguration: ItineraryModuleConfigurationFieldsFragment
}

const ItineraryModule: React.FC<RenderProps> = ({ label, moduleConfiguration }) => {
  return (
    <iframe
      className={styles.iframe}
      title={label}
      src={moduleConfiguration.iframeLink}
      sandbox="allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts allow-downloads"
    />
  )
}

export const fragments = graphql`
  fragment ItineraryModuleConfigurationFields on DatoCmsItineraryModuleConfiguration {
    iframeLink
  }
`

export default ItineraryModule
