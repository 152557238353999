import React from "react"
import * as styles from "./rectangular-button.module.scss"

type RenderProps = {
  label: string
  redirectUrl: string
}

const RectangularButton: React.FC<RenderProps> = ({ label, redirectUrl }) => {
  return (
    <a href={redirectUrl} className={styles.rectangularButton}>
      {label}
    </a>
  )
}

export default RectangularButton
