import React from "react"

export const ArrowLeft = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden={true}>
    <path
      d="M8.40007 10.9138L16.1973 8.13716L15.6323 6.47888L7.83505 9.37124C7.04402 9.71832 6.479 10.451 6.479 11.338C6.479 12.225 7.04402 12.9963 7.83505 13.3048L15.6323 16.1972L16.1973 14.5389L8.40007 11.7237C8.24939 11.6466 8.17406 11.4923 8.17406 11.2995C8.17406 11.1066 8.24939 10.991 8.40007 10.9138Z"
      fill="white"
    />
  </svg>
)

export const ArrowRight = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden={true}>
    <path
      d="M14.5999 12.0862L6.80269 14.8628L7.3677 16.5211L15.165 13.6288C15.956 13.2817 16.521 12.549 16.521 11.662C16.521 10.775 15.956 10.0037 15.165 9.69516L7.3677 6.80281L6.80269 8.46109L14.5999 11.2763C14.7506 11.3534 14.8259 11.5077 14.8259 11.7005C14.8259 11.8934 14.7506 12.009 14.5999 12.0862Z"
      fill="white"
    />
  </svg>
)

export const Pause = () => (
  <svg width="23" height="30" viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden={true}>
    <line x1="3" x2="3" y2="30" stroke="white" strokeWidth="6" />
    <line x1="20" x2="20" y2="30" stroke="white" strokeWidth="6" />
  </svg>
)
