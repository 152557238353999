import React, { useState } from "react"
import {
  LinesListFieldsFragment,
  SchedulesSearchLinkIconFragment,
  SchedulesSearchModuleFieldsFragment,
  SchedulesSearchPictogramFragment,
} from "../../../../graphql-types"
import * as styles from "./schedules-search-results-line.module.scss"

import { ReactSVG } from "react-svg"
import bell from "../../../../static/images/bell.svg"

import { useLinesSubscription } from "../../../hooks/use-lines-subscription"
import Popup from "../../popup/popup"
import { detectWebView } from "../../../utils"

type Line = LinesListFieldsFragment

type RenderProps = {
  line: Line
  moduleConfiguration: SchedulesSearchModuleFieldsFragment
}

const SchedulesSearchResultsLine: React.FC<RenderProps> = ({ line, moduleConfiguration }) => {
  const { toggleSubscription, isSubscribed } = useLinesSubscription()
  const isWebView = detectWebView()
  const [showPopup, setShowPopup] = useState(false)
  const [pendingLine, setPendingLine] = useState<Line | null>(null)
  const [popupInfos, setPopupInfos] = useState({
    popupTitle: "",
    popupMessage: "",
    popupIconStyle: "",
  })

  const togglePopup = () => {
    setShowPopup(!showPopup)
  }

  const handleSubscription = (line: Line) => {
    setPendingLine(line)
    if (isSubscribed(line.id)) {
      setPopupInfos({
        popupTitle: "Désabonnement validé avec succès",
        popupMessage: `Vous avez retiré la ligne : <span>${line.name}</span> de vos alertes. Vous ne recevrez plus de notifications concernant cette ligne`,
        popupIconStyle: styles.bellProps,
      })
    } else {
      setPopupInfos({
        popupTitle: "Abonnement validé avec succès",
        popupMessage: `Vous avez ajouté la ligne : <span>${line.name}</span> dans vos alertes. Vous recevrez des notifications afin de vous informer de toutes évolutions du traffic`,
        popupIconStyle: `${styles.bellFull} ${styles.bellProps}`,
      })
    }
    togglePopup()
  }

  const handlePopupClose = () => {
    // We use a pending state to ensure the popup is shown,
    // because the subscription will provoke a re-render in some cases (like in my-alert page),
    // preventing the popup to be shown
    togglePopup()
    if (pendingLine) {
      toggleSubscription(pendingLine.id)
      setPendingLine(null)
    }
  }

  return (
    <section className={styles.linePanel}>
      <div className={`${styles.overlay} ${showPopup ? styles.active : ""}`}></div>
      {showPopup && isWebView && (
        <Popup
          title={popupInfos.popupTitle}
          message={popupInfos.popupMessage}
          icon={{ src: bell, alt: "subscription bell", style: popupInfos.popupIconStyle }}
          button1={{ label: "OK", action: handlePopupClose }}
        />
      )}
      <h5 className={styles.title}>
        {displayImage(line.pictogram, styles.pictogram)} {line.name}
        {isWebView && (
          <ReactSVG
            src={bell}
            className={`${styles.bell} ${isSubscribed(line.id) ? styles.bellFull : ""}`}
            onClick={() => handleSubscription(line)}
          />
        )}
      </h5>
      <ul className={styles.schedulesList}>
        {line.schedules.map(schedule => (
          <li key={schedule.id} className={styles.schedule}>
            {displayImage(moduleConfiguration.scheduleIcon, styles.linkIcon)}
            <a href={(schedule.file && schedule.file.url) || schedule.link} target="_blank">
              {schedule.label}
            </a>
          </li>
        ))}
        {line.lineMap && (
          <li key={line.id} className={styles.schedule}>
            {displayImage(moduleConfiguration.lineMapIcon, styles.linkIcon)}
            <a href={line.lineMap.url} download>
              {moduleConfiguration.lineMapLinkLabel}
            </a>
          </li>
        )}
      </ul>
    </section>
  )
}

const displayImage = (image: SchedulesSearchPictogramFragment | SchedulesSearchLinkIconFragment, className: string) => {
  if (image.format === "svg") {
    return (
      <ReactSVG
        src={image.url}
        className={className}
        beforeInjection={svg => svg.setAttribute("aria-label", image.alt || "")}
      />
    )
  }
  return <img src={image.url} alt={image.alt || ""} className={className} aria-hidden="true" />
}

export default SchedulesSearchResultsLine
