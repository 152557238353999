import React from "react"
import * as styles from "./traffic-block.module.scss"
import { ReactSVG } from "react-svg"

type RenderProps = {
  block: any
  onPerturbationClicked: (lineId: string) => void
}

const TrafficBlock: React.FC<RenderProps> = ({ block, onPerturbationClicked }) => {
  const linesWithUniqueDisruptions = block.map(lineWithTrafficInfos => {
    const disruptions = lineWithTrafficInfos.traffic.reduce((trafficInfos, traffic) => {
      trafficInfos.set(traffic.disruptionType, traffic)
      return trafficInfos
    }, new Map<string, Traffic>())
    return {
      line: lineWithTrafficInfos,
      disruptions: Array.from(disruptions.values()),
    }
  })

  return (
    <ul className={styles.trafficBlock}>
      {linesWithUniqueDisruptions.map((item, index) => {
        return (
          <li className={styles.blockContent} key={item.line.line.name}>
            <button
              className={styles.buttonNoStyle}
              key={item.line.line.id}
              id={"traffic-block-button-" + item.line.line.id}
              onClick={() => onPerturbationClicked(item.line.line.id)}
            >
              <img
                className={styles.linePicto}
                src={item.line.line.pictogram.url}
                alt={item.line.line.pictogram.alt}
                key={index}
              />
              <span className={styles.perturbationPictogram}>
                {item.disruptions.map((element, idx) => {
                  return (
                    element.pictogram.format === "svg" && (
                      <ReactSVG
                        src={element.pictogram.url}
                        className={styles.pictoChildSvg}
                        beforeInjection={svg => svg.setAttribute("aria-label", element.pictogram.alt || "")}
                        wrapper={"span"}
                        key={idx}
                      />
                    )
                  )
                })}
              </span>
            </button>
          </li>
        )
      })}
    </ul>
  )
}

export default TrafficBlock
