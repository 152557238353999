import { graphql } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { ReactSVG } from "react-svg"
import * as config from "../../../../config"
import {
  LePiloteInfoTrafficLinesIconsFieldsFragment,
  LePiloteTrafficInfoBlockFieldsFragment,
  SecondaryBlocksLabelFieldsFragment,
} from "../../../../graphql-types"
import { getInfoTraffic, getLines } from "../../../api/le-pilote/le-pilote-info-traffic-api"
import { pushGtmEvent } from "../../../gtm/interaction-event"
import { useIsToday } from "../../../hooks"
import * as inputsStyles from "../../layout/inputs.module.scss"
import * as globalBlockStyles from "../main-blocks/main-blocks.module.scss"
import * as styles from "../traffic-block/traffic-parent-block.module.scss"
import LePiloteTrafficBlocks from "./le-pilote-traffic-blocks"

export enum SelectedTab {
  NOW = "now",
  TOMORROW = "tomorrow",
}

type RenderProps = {
  block: LePiloteTrafficInfoBlockFieldsFragment
  isHighlighted: boolean
  intersectionRatioCallback: (ratio: number) => void
  linesIconsData: LePiloteInfoTrafficLinesIconsFieldsFragment[]
  labels: SecondaryBlocksLabelFieldsFragment
}

const LePiloteInfoTrafficBlock: React.FC<RenderProps> = ({
  block,
  intersectionRatioCallback,
  linesIconsData,
  labels,
}) => {
  const component = useRef<HTMLElement>()
  const observer = useRef<IntersectionObserver>()
  const [tabState, setSelectedTab] = useState<SelectedTab>(SelectedTab.NOW)
  const nowBlockRef = useRef<HTMLDivElement>()
  const tomorrowBlockRef = useRef<HTMLDivElement>()

  const [linesWithInfoTraffic, setLinesWithInfoTraffic] = useState(null)

  useEffect(() => {
    getExternalData(config.locale)
  }, [])

  useEffect(() => {
    if (!("IntersectionObserver" in window)) {
      return
    }
    const options = { threshold: [0.2, 0.4, 0.6, 0.8, 1.0] }
    observer.current = new IntersectionObserver(entries => {
      intersectionRatioCallback(entries[entries.length - 1].intersectionRatio)
    }, options)
    observer.current.observe(component.current)

    return () => observer.current.disconnect()
  }, [])

  async function getExternalData(lang: string) {
    const lines = await getLines(lang)
    const traffic = await getInfoTraffic(lang)
    const formattedData = parseTrafficData(lines.Data, traffic.Data)
    if (formattedData) {
      formattedData["today"].map(x => {
        if (x.traffic && x.traffic.length && x.traffic.length > 1) {
          x.traffic = [...Array.from(new Set(x.traffic))]
        }
      })
      formattedData["tomorrow"].map(x => {
        if (x.traffic && x.traffic.length && x.traffic.length > 1) {
          x.traffic = [...Array.from(new Set(x.traffic))]
        }
      })
    }
    setLinesWithInfoTraffic(formattedData)
    return formattedData
  }

  const getAllAffectedLines = infoTrafficData => {
    const lines = []
    infoTrafficData.map(infoTraffic => {
      infoTraffic.DisruptedLines.map(line => lines.push(line))
    })
    return lines
  }

  const getInfoTrafficFormatted = (disruptedLines, infoTrafficData, linesDetails) => {
    const myLinesWithTrafficToday = []
    const myLinesWithTrafficTomorrow = []

    disruptedLines.map(line => {
      const infoTrafficArrayToday = []
      const infoTrafficArrayTomorrow = []
      infoTrafficData.map(infoTraffic => {
        infoTraffic.DisruptedLines.map(subline => {
          if (line.LineId === subline.LineId) {
            if (useIsToday(infoTraffic.BeginValidityDate, infoTraffic.EndValidityDate)) {
              const completeLineData = linesDetails.find(x => x.Id === line.LineId)
              if (completeLineData) {
                const icone = getLineIcon(completeLineData.Code)
                completeLineData.icone = icone[0]
                infoTrafficArrayToday.push(infoTraffic)
                myLinesWithTrafficToday.push({
                  line: completeLineData,
                  traffic: infoTrafficArrayToday,
                })
              }
            }
            if (isTomorrow(infoTraffic.BeginValidityDate, infoTraffic.EndValidityDate)) {
              const completeLineData = linesDetails.find(x => x.Id === line.LineId)
              if (completeLineData) {
                const icone = getLineIcon(completeLineData.Code)
                completeLineData.icone = icone[0]
                infoTrafficArrayTomorrow.push(infoTraffic)
                myLinesWithTrafficTomorrow.push({
                  line: completeLineData,
                  traffic: infoTrafficArrayTomorrow,
                })
              }
            }
          }
        })
      })
    })
    const uniqueTrafficEntriesToday = deleteDuplicates(myLinesWithTrafficToday)
    const uniqueTrafficEntriesTomorrow = deleteDuplicates(myLinesWithTrafficTomorrow)
    return {
      today: uniqueTrafficEntriesToday,
      tomorrow: uniqueTrafficEntriesTomorrow,
    }
  }

  const deleteDuplicates = (linesArray: any[]) =>
    linesArray.filter((ele, index) => index === linesArray.findIndex(elem => elem.line === ele.line))

  const parseTrafficData = (linesDetails: Array<any>, infoTrafficData: Array<any>): object => {
    const disruptedLines = getAllAffectedLines(infoTrafficData)
    return getInfoTrafficFormatted(disruptedLines, infoTrafficData, linesDetails)
  }

  const getLineIcon = lineCode => {
    const lineCodeLowercase = lineCode.toLowerCase()
    return linesIconsData.filter(
      x => x.basename.endsWith(`${lineCodeLowercase}`) || x.basename.endsWith(`${lineCodeLowercase}-ineo`)
    )
  }

  const getDate = (date: string) => parseInt(date.substring(date.lastIndexOf("(") + 1, date.lastIndexOf(")")))

  const isTomorrow = (min, max) => {
    let date = new Date()
    let tomorrow = new Date(date.getTime() + 24 * 60 * 60 * 1000)
    tomorrow.setHours(0, 0, 0, 0)
    const start = new Date(getDate(min))
    const end = new Date(getDate(max))
    return tomorrow.getTime() >= start.getTime() && tomorrow.getTime() <= end.getTime()
  }

  const handleTabChange = tabValue => {
    if (tabValue == "now") {
      setSelectedTab(SelectedTab.NOW)
      if (typeof window !== "undefined") {
        setTimeout(() => {
          nowBlockRef.current && nowBlockRef.current.focus()
        })
      }
    } else {
      setSelectedTab(SelectedTab.TOMORROW)
      if (typeof window !== "undefined") {
        setTimeout(() => {
          tomorrowBlockRef.current && tomorrowBlockRef.current.focus()
        })
      }
    }
  }

  return linesWithInfoTraffic !== null ? (
    <section className={`${styles.block} ${styles.customBlock} ${globalBlockStyles.mainBlocksCard}`} ref={component}>
      <div className={styles.blockHeader}>
        <ReactSVG
          src={block.blockPictogram.url}
          className={styles.blockPicto}
          beforeInjection={svg => svg.setAttribute("aria-label", block.blockPictogram.alt || "")}
        />
        <h3 className={styles.title}>{block.title}</h3>
      </div>
      <div className={styles.tabButtonsContainer}>
        <button
          className={tabState === SelectedTab.NOW ? styles.selectedButton : styles.unselectedButton}
          aria-pressed={tabState === SelectedTab.NOW}
          onClick={() => handleTabChange(SelectedTab.NOW)}
        >
          {block.firstTab}
        </button>
        <button
          className={tabState === SelectedTab.TOMORROW ? styles.selectedButton : styles.unselectedButton}
          aria-pressed={tabState === SelectedTab.TOMORROW}
          onClick={() => handleTabChange(SelectedTab.TOMORROW)}
        >
          {block.secondTab}
        </button>
      </div>
      {tabState === SelectedTab.NOW && (
        <div className={styles.infoTrafficBlock} ref={nowBlockRef} tabIndex={-1}>
          <div className={styles.myContainer}>
            <LePiloteTrafficBlocks
              blocks={linesWithInfoTraffic["today"]}
              previousLabel={labels.secondaryBlocksPreviousLabel}
              nextLabel={labels.secondaryBlocksNextLabel}
              closeButtonLabel={block.closeButtonLabel}
            />
          </div>
        </div>
      )}
      {tabState === SelectedTab.TOMORROW && (
        <div className={styles.infoTrafficBlock} ref={tomorrowBlockRef} tabIndex={-1}>
          <div className={styles.myContainer}>
            <LePiloteTrafficBlocks
              blocks={linesWithInfoTraffic["tomorrow"]}
              previousLabel={labels.secondaryBlocksPreviousLabel}
              nextLabel={labels.secondaryBlocksNextLabel}
              closeButtonLabel={block.closeButtonLabel}
            />
          </div>
        </div>
      )}
      <div className={styles.trafficStatusLabel}>{block.disruptedTrafficLabel}</div>
      <a
        className={`${styles.link} ${styles.linkTraffic} ${inputsStyles.primaryButton}`}
        href={block.internalLink.slug}
      >
        {block.linkLabel}
      </a>
    </section>
  ) : (
    <section className={`${styles.block} ${styles.customBlock}`} ref={component}>
      <h3 className={styles.title}>{block.title}</h3>
      {block.image.format === "svg" ? (
        <ReactSVG
          src={block.image.url}
          className={styles.svgImage}
          beforeInjection={svg => svg.setAttribute("aria-label", block.image.alt || "")}
        />
      ) : (
        <img src={block.image.url} className={styles.image} alt={block.image.alt || ""} />
      )}
      <div className={styles.trafficStatusLabel}>{block.normalTrafficLabel}</div>
      <a
        className={`${styles.link} ${inputsStyles.primaryButton}`}
        href={block.internalLink.slug}
        onClick={() => pushGtmEvent("eventga", "Homepage", "Bloc Principal", block.linkLabel)}
      >
        {block.linkLabel}
      </a>
    </section>
  )
}

export const fragments = graphql`
  fragment LePiloteTrafficInfoBlockFields on DatoCmsLePiloteTrafficInfoBlock {
    __typename
    id
    title
    linkLabel
    internalLink {
      slug
    }
    firstTab
    secondTab
    disruptedTrafficLabel
    normalTrafficLabel
    closeButtonLabel
    blockPictogram {
      alt
      url
    }
    image {
      alt
      format
      url
    }
  }

  fragment LePiloteInfoTrafficLinesIconsFields on DatoCmsAsset {
    id
    url
    basename
  }
`

export default LePiloteInfoTrafficBlock
