import * as inputsStyles from "../inputs.module.scss"
import React from "react"
import { RegisterOptions, UseFormRegisterReturn } from "react-hook-form"

type RenderProps = {
  id?: string
  type: string
  name: string
  required?: boolean
  className?: string
  placeholder?: string
  validationType?: ValidationType
  hasErrors?: boolean
  onFocus?: (value: any) => void
  autocomplete?: boolean
} & (
  | {
      value?: string
      onChange?: (value: string) => void
      register: (name: string, options?: RegisterOptions) => UseFormRegisterReturn
    }
  | { value: string; onChange: (value: string) => void; register: undefined }
)

export enum ValidationType {
  None,
  Email,
  PhoneNumber,
  PhoneNumberE164,
}

const Input: React.FC<RenderProps> = ({
  id,
  type,
  name,
  required,
  className = "",
  placeholder = "",
  value,
  onChange,
  register,
  validationType,
  hasErrors,
  onFocus,
  autocomplete,
}) => {
  const validation = {
    required,
    pattern: getValidationPattern(validationType),
  }

  return register != undefined ? (
    <input
      id={id}
      type={type}
      name={name}
      aria-describedby={id}
      placeholder={placeholder}
      required={required}
      autoComplete={validationType === ValidationType.PhoneNumber ? "tel-national" : autocomplete ? name : null}
      className={
        inputsStyles.input +
        " " +
        (isNotBlank(value) ? inputsStyles.notBlank + " " : "") +
        (hasErrors ? inputsStyles.fieldInError + " " : "") +
        className
      }
      onChange={event => {
        onChange && onChange(event.target.value)
      }}
      {...register(name, { ...validation })}
      value={value}
      onFocus={event => {
        onFocus && onFocus(event.target.id)
      }}
    />
  ) : (
    <input
      id={id}
      type={type}
      name={name}
      aria-describedby={id}
      autoComplete={validationType === ValidationType.PhoneNumber ? "tel-national" : autocomplete ? name : null}
      placeholder={placeholder}
      required={required}
      className={
        inputsStyles.input +
        " " +
        (isNotBlank(value) ? inputsStyles.notBlank + " " : "") +
        (hasErrors ? inputsStyles.fieldInError + " " : "") +
        className
      }
      onChange={event => {
        onChange && onChange(event.target.value)
      }}
      value={value}
      onFocus={event => {
        onFocus && onFocus(event.target.id)
      }}
    />
  )
}

function getValidationPattern(validationType: ValidationType) {
  switch (validationType) {
    case ValidationType.Email:
      return /.+@.+/
    case ValidationType.PhoneNumber:
      return /([\s.-]*[0-9][\s.-]*){10}/
    case ValidationType.PhoneNumberE164:
      return /^\+[1-9]\d{1,14}$/
    default:
      return undefined
  }
}

function isNotBlank(value: string) {
  return value && value.trim().length > 0
}

export default Input
