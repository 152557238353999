import * as inputsStyles from "../inputs.module.scss"
import React from "react"
import { RegisterOptions, UseFormRegisterReturn } from "react-hook-form"

type RenderProps = {
  id?: string
  name: string
  required?: boolean
  className?: string
  onChange?: (value: boolean) => void
  checked?: boolean
  label: string
  register?: (name: string, options?: RegisterOptions) => UseFormRegisterReturn
  hasErrors?: boolean
  onFocus?: (value: any) => void
}

const Checkbox: React.FC<RenderProps> = ({
  id,
  name,
  required,
  className = "",
  label,
  checked,
  onChange,
  register,
  hasErrors,
  onFocus,
}) => {
  return (
    <label htmlFor={id} className={`${inputsStyles.checkbox} ${hasErrors ? " " + inputsStyles.fieldInError : ""}`}>
      {label}
      <input
        type="checkbox"
        id={id}
        name={name}
        required={required}
        className={className}
        onChange={() => onChange(!checked)}
        value={label}
        checked={checked}
        {...register(name, { required })}
        onFocus={event => {
          onFocus && onFocus(event.target.id)
        }}
      />
      <span className={inputsStyles.checkmark} />
    </label>
  )
}

export default Checkbox
