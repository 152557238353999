import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { useQuerySubscription } from "react-datocms"
import * as config from "../../../config"
import { DatoCmsInfoTraffic, HomeQuery, InformationBannerFieldsFragment } from "../../../graphql-types"
import AlertBanner from "../../components/home/alert-banner/alert-banner"
import Carousel from "../../components/home/carousel/carousel"
import InformationBanner from "../../components/home/information-banner/information-banner"
import MainBlocks from "../../components/home/main-blocks/main-blocks"
import NewsList from "../../components/home/news/news-list"
import SecondaryBlocks from "../../components/home/secondary-blocks/secondary-blocks"
import * as inputsStyles from "../../components/layout/inputs.module.scss"
import Layout from "../../components/layout/layout"
import LoadScripts from "../../components/load-scripts"
import SEO from "../../components/seo"
import { pushGtmPageEvent } from "../../gtm/interaction-event"
import { useDetectScroll, useFormatLocale, useIsToday } from "../../hooks"
import { v4 as uuidv4 } from "uuid"
import * as styles from "./index.module.scss"

type RenderProps = {
  data: HomeQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
  }
}

enum BannerPosition {
  BelowMainBlocks,
  BelowSecondaryBlocks,
  BelowCarrousel,
}

enum CarouselPosition {
  BelowMainBlocks,
  BelowSecondaryBlocks,
}

const isBannerForPosition = (position: BannerPosition) => {
  const getBannerPosition = (informationBanner: InformationBannerFieldsFragment) => {
    switch (informationBanner.location) {
      case "Below main blocks":
        return BannerPosition.BelowMainBlocks
      case "Below secondary blocks":
        return BannerPosition.BelowSecondaryBlocks
      case "Below carrousel":
        return BannerPosition.BelowCarrousel
      default:
        return BannerPosition.BelowMainBlocks
    }
  }

  return (informationBanner: InformationBannerFieldsFragment) => getBannerPosition(informationBanner) == position
}

const isCarouselForPosition = (carouselPositionString: string) => {
  const getCarouselPosition = (position: string) => {
    switch (position) {
      case "Below main blocks":
        return CarouselPosition.BelowMainBlocks
      case "Below secondary blocks":
        return CarouselPosition.BelowSecondaryBlocks
      default:
        return CarouselPosition.BelowMainBlocks
    }
  }

  const carouselPosition = getCarouselPosition(carouselPositionString)
  return (position: CarouselPosition) => carouselPosition === position
}

const Index: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const locale = useFormatLocale(pageContext.locale)
  const defaultLocale = useFormatLocale(pageContext.defaultLocale)

  const displayBanner = (informationBanner: InformationBannerFieldsFragment) => {
    return (
      <div className={styles.informationBanner} key={informationBanner.id}>
        <InformationBanner informationBanner={informationBanner} locale={locale} defaultLocale={defaultLocale} />
      </div>
    )
  }

  const displayedBanners = data.datoCmsHome.informationBanners.filter(banner => banner.isActive)
  const belowMainBlockBanners = displayedBanners.filter(isBannerForPosition(BannerPosition.BelowMainBlocks))
  const belowSecondaryBlockBanners = displayedBanners.filter(isBannerForPosition(BannerPosition.BelowSecondaryBlocks))
  const belowMainCarrouselBanners = displayedBanners.filter(isBannerForPosition(BannerPosition.BelowCarrousel))
  const displayCarouselIfDisplayed = data.datoCmsHome.carouselActive
    ? isCarouselForPosition(data.datoCmsHome.carouselPosition)
    : () => false
  const carousel = (
    <Carousel
      slides={data.datoCmsHome.carouselSlides}
      label={data.datoCmsHome.carouselLabel}
      nextLabel={data.datoCmsHome.carouselNextLabel}
      previousLabel={data.datoCmsHome.carouselPreviousLabel}
      pauseLabel={data.datoCmsHome.carouselPauseLabel}
      playLabel={data.datoCmsHome.carouselPlayLabel}
      locale={locale}
      defaultLocale={defaultLocale}
    />
  )

  const [dataInfo, setData] = useState(null)
  const { data: realTimeData } = useQuerySubscription({
    enabled: true,
    query: `
      query infoQuery {
        allInfoTraffics(locale: ${config.locale}, orderBy: [end_DESC]) {
          start
          end
          id
          title
          disruptionType
          displayInHome
          pictogram {
            url
            alt
            format
          }
          lines {
            id
            number
            name
            pictogram {
              url
              alt
              format
            }
          }
          shortDescription
          slug
        }
      }`,
    token: config.readOnlyApi,
  })

  if (realTimeData) {
    if (realTimeData !== dataInfo) {
      setData(realTimeData)
    }
  }

  let infoTrafficDisplayInHome: DatoCmsInfoTraffic
  if (dataInfo) {
    infoTrafficDisplayInHome = dataInfo.allInfoTraffics.find(
      element => element.displayInHome && element.disruptionType == "major" && useIsToday(element.start, element.end)
    ) as DatoCmsInfoTraffic
  }

  useDetectScroll()

  const [pageView, setPageView] = useState(false)

  useEffect(() => {
    if (pageView || !window) {
      return
    }
    let host = ""
    if (window.location) {
      host = window.location.host
    }
    const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches
    const searchPattern = new RegExp("^" + "qksa")
    const isQualificationStage = searchPattern.test(host)
    pushGtmPageEvent(
      "pageview",
      config.locale,
      data.site.siteMetadata.siteUrl,
      isMobile ? "mobile" : "desktop",
      isQualificationStage ? "qualification" : "production",
      data.datoCmsHome.title,
      window.location.hostname,
      null,
      "Homepage",
      ""
    )
    setPageView(true)
  }, [])

  useEffect(() => {
    let deviceID = localStorage.getItem("deviceID")

    if (!deviceID || deviceID === "null") {
      deviceID = uuidv4()
      localStorage.setItem("deviceID", deviceID)
    }
  }, [])

  return (
    <Layout
      location={location}
      menuData={data.datoCmsMenu}
      footerData={data.datoCmsFooter}
      toolbarData={data.datoCmsGlobalConfiguration}
      scriptsData={data.datoCmsGlobalConfiguration}
      locale={locale}
      defaultLocale={defaultLocale}
    >
      {(infoTrafficDisplayInHome || data.datoCmsHome.alertBannerIsActive) && (
        <AlertBanner
          infoTrafficData={infoTrafficDisplayInHome}
          buttonText={data.datoCmsGlobalConfiguration.redirectionButtonText}
          alertBannerConfig={data.datoCmsHome}
          defaultLocale={defaultLocale}
        />
      )}
      {!data.datoCmsHome.areMainBlocksHidden && (
        <div className={styles.mainBlocks}>
          <MainBlocks
            mainBlocks={data.datoCmsHome.mainBlocks}
            secondaryBlocksLabels={data.datoCmsHome}
            colors={data.datoCmsHome}
            lines={data.allDatoCmsLine.nodes}
            assets={data.allDatoCmsAsset.nodes}
            locale={locale}
            defaultLocale={defaultLocale}
          />
        </div>
      )}
      {belowMainBlockBanners.length > 0 && <div className="container">{belowMainBlockBanners.map(displayBanner)}</div>}
      <SecondaryBlocks
        blocks={data.datoCmsHome.secondaryBlocks}
        label={data.datoCmsHome.secondaryBlocksLabel}
        locale={locale}
        defaultLocale={defaultLocale}
      />
      {displayCarouselIfDisplayed(CarouselPosition.BelowMainBlocks) && (
        <div className={`container ${styles.carousel}`}>{carousel}</div>
      )}
      <div className="container">{belowSecondaryBlockBanners.map(displayBanner)}</div>
      {displayCarouselIfDisplayed(CarouselPosition.BelowSecondaryBlocks) && (
        <div className={`container ${styles.carousel}`}>{carousel}</div>
      )}
      {data.datoCmsHome.displayNews && data.allDatoCmsNews.nodes.length > 0 && (
        <div className="container">
          <NewsList
            data={data.allDatoCmsNews.nodes}
            label={data.datoCmsHome.newsLabel}
            locale={locale}
            defaultLocale={defaultLocale}
          />
        </div>
      )}
      {data.datoCmsHome.allNewsDisplay && data.datoCmsHome.allNewsButtonLabel.length > 0 && (
        <div className="container">
          <a
            className={`${inputsStyles.primaryButton} ${styles.allNewsButton}`}
            href={data.datoCmsHome.allNewsPage.slug}
          >
            {data.datoCmsHome.allNewsButtonLabel}
          </a>
        </div>
      )}
      <div className="container">{belowMainCarrouselBanners.map(displayBanner)}</div>
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data }) => {
  return (
    <>
      <SEO />
      <meta name="viewport" content="width=device-width" />
      <link rel="canonical" href={data.site.siteMetadata.siteUrl} />
      <LoadScripts scriptData={data.datoCmsGlobalConfiguration} />
    </>
  )
}

export const pageQuery = graphql`
  query Home($locale: String!) {
    site {
      siteMetadata {
        siteUrl
        title
        websiteConfig {
          primary_color {
            red
            green
            blue
          }
        }
      }
    }
    datoCmsHome(locale: $locale) {
      title
      areMainBlocksHidden
      ...MainBlocksCardColorsFields
      mainBlocks {
        ...MainBlocksFields
      }

      informationBanners {
        ...InformationBannerFields
      }

      ...SecondaryBlocksLabelFields
      secondaryBlocks {
        ...SecondaryBlockFields
      }

      carouselActive
      carouselSlides {
        ...CarouselSlideFields
      }
      carouselPosition
      carouselLabel
      carouselPreviousLabel
      carouselNextLabel
      carouselPauseLabel
      carouselPlayLabel

      allNewsDisplay
      allNewsButtonLabel
      allNewsPage {
        slug
      }
      displayNews
      newsLabel
      ...AlertBannerFields
    }

    allDatoCmsNews(locale: $locale, sort: { publicationDate: DESC }, limit: 10) {
      nodes {
        ...HomeNewsFields
      }
    }

    datoCmsMenu(locale: $locale) {
      ...HeaderMenuFields
      ...FooterMenuFields
    }

    datoCmsGlobalConfiguration(locale: $locale) {
      ...HeaderToolBarFields
      ...ScriptsFields
      redirectionButtonText
    }

    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }

    allDatoCmsLine(locale: $locale, sort: { position: ASC }) {
      nodes {
        ...SchedulesSearchBlockLineFields
      }
    }

    allDatoCmsAsset(filter: { isImage: { eq: true }, basename: { regex: "/^picto-/" } }) {
      nodes {
        ...LePiloteInfoTrafficLinesIconsFields
      }
    }
  }
`

export default Index
