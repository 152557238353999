import React, { useEffect } from "react"
import * as styles from "./layout.module.scss"
import Header, { enableDidomi, enableGoogleTagManagerBody } from "./header/header"
import Footer from "./footer/footer"
import { useIsMobile } from "../../hooks"
import HeaderToolBar from "./header/header-tool-bar/header-tool-bar"
import {
  FooterFieldsFragment,
  FooterMenuFieldsFragment,
  HeaderMenuFieldsFragment,
  HeaderToolBarFieldsFragment,
  ScriptsFieldsFragment,
} from "../../../graphql-types"
import { graphql } from "gatsby"
import { RelativeLanguagePaths } from "./header/header-tool-bar/language-selector/language-selector"
import { LinesSubscriptionProvider } from "../../context/lines-subscription-context"

type RenderProps = {
  location: Location
  menuData: HeaderMenuFieldsFragment & FooterMenuFieldsFragment
  footerData: FooterFieldsFragment
  toolbarData: HeaderToolBarFieldsFragment
  scriptsData: ScriptsFieldsFragment
  locale: string
  defaultLocale: string
  relativeLanguagePaths?: RelativeLanguagePaths
  children?: React.ReactNode
}

const Layout: React.FC<RenderProps> = ({
  location,
  menuData,
  footerData,
  toolbarData,
  scriptsData,
  locale,
  defaultLocale,
  relativeLanguagePaths,
  children,
}) => {
  const isMobile = useIsMobile(1000)
  // @ts-ignore
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  useEffect(() => {
    enableDidomi()
    enableGoogleTagManagerBody()
  }, [scriptsData])

  const svgBackground = `
    <svg width="2777" height="576" viewBox="0 0 2777 576" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8490_1105)">
        <rect width="2777" height="576" fill="white"/>
        <path d="M0 0H2777V576H0V0Z" fill="url(#paint0_linear_8490_1105)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M187.747 393.767L57.9926 1487.9L236.819 1471.79L358.517 376.936C371.088 265.979 328.648 166.783 246.094 135.153C163.54 103.524 65.3478 148.839 0.0918606 239.61L-645.555 1133.72L-523.619 1265.06L115.564 366.111C128.808 347.827 148.488 338.78 165.289 345.217C182.09 351.655 190.412 371.43 188.162 393.926" fill="${menuData.svgColor?.hex ?? "white"}" fill-opacity="${menuData.svgColor ? "1" : "0.2"}"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2637.67 246.791L4017.51 -77.4332L3931.63 -313.776L2553.9 21.7569C2414.19 55.4375 2309.18 158.706 2301.27 285.704C2293.36 412.702 2384.9 525.776 2519.56 573.144L3847.1 1043.63L3961.79 817.294L2630.75 357.835C2603.59 348.158 2585.29 325.479 2586.9 299.633C2588.51 273.786 2609.49 253.388 2637.71 246.153" fill="${menuData.svgColor?.hex ?? "white"}" fill-opacity="${menuData.svgColor ? "1" : "0.2"}"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_8490_1105" x1="1388.5" y1="0" x2="1388.5" y2="576" gradientUnits="userSpaceOnUse">
          <stop stop-color="${menuData.primaryColor?.hex ?? scriptsData.primaryColor?.hex ?? "#0C7D7B"}"/> 
          <stop offset="1" stop-color="${menuData.gradientPrimaryColor?.hex ?? scriptsData.gradientPrimaryColor?.hex ?? "#005F7D"}"/> 
        </linearGradient>
        <clipPath id="clip0_8490_1105">
          <rect width="2777" height="576" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  `

  const svgDataUrl = `data:image/svg+xml;base64,${btoa(svgBackground)}`

  return (
    <LinesSubscriptionProvider>
      <div className={styles.globalWrapper} data-is-root-path={isRootPath}>
        <nav role="navigation">
          <ul className={styles.bypassLinks} data-datocms-noindex={true}>
            <li>
              <a href="#menu">{menuData.menuBypassLink}</a>
            </li>
            <li>
              <a href="#main">{menuData.mainContentBypassLink}</a>
            </li>
          </ul>
        </nav>

        <div className={styles.gradientHeader} />
        <header id="menu" className={styles.header} role="banner" data-datocms-noindex={true}>
          {!isMobile && (
            <HeaderToolBar
              toolbarData={toolbarData}
              locale={locale}
              defaultLocale={defaultLocale}
              data-datocms-noindex={true}
            />
          )}
          <Header
            menuData={menuData}
            locale={locale}
            defaultLocale={defaultLocale}
            relativeLanguagePaths={relativeLanguagePaths}
          />
        </header>
        {isMobile ? (
          <HeaderToolBar
            toolbarData={toolbarData}
            locale={locale}
            defaultLocale={defaultLocale}
            data-datocms-noindex={true}
          />
        ) : null}
        <main id="main" role="main">
          {children}
        </main>
        <div className={styles.footer} style={{ backgroundImage: `url(${svgDataUrl})` }} data-datocms-noindex={true}>
          <Footer data={footerData} menuData={menuData} locale={locale} defaultLocale={defaultLocale} />
        </div>
      </div>
    </LinesSubscriptionProvider>
  )
}

export const fragments = graphql`
  fragment ScriptsFields on DatoCmsGlobalConfiguration {
    scripts {
      id
      elements {
        ... on DatoCmsScriptPartUrl {
          id
          __typename
          url
        }
        ... on DatoCmsScriptPartJavascript {
          id
          __typename
          js
        }
      }
    }
    primaryColor {
      hex
    }
    gradientPrimaryColor {
      hex
    }
  }
`

export default Layout
