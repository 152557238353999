import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { SideBlockFieldsFragment } from "../../../../graphql-types"
import * as styles from "./side-blocks.module.scss"
import SideBlock from "./side-block/side-block"
import { useInternalExternalLink } from "../../../hooks"

type RenderProps = {
  blocks: SideBlockFieldsFragment[]
  locale: string
  defaultLocale: string
}

const SideBlocks: React.FC<RenderProps> = ({ blocks, locale, defaultLocale }) => {
  return (
    <ul className={styles.sideBlocks} aria-label={"sideBlocks"}>
      {blocks.map((b, index) => {
        const sideLink = b.sideBlockInternalExternalLink[0]
        const sideLinkBis = b.sideBlockInternalExternalLinkBis[0]
        const link = useInternalExternalLink(sideLink, locale, defaultLocale)
        const linkBis = useInternalExternalLink(sideLinkBis, locale, defaultLocale)

        return (
          <Fragment key={index}>
            <li className={styles.sideBlock}>
              <SideBlock
                image={b.sideBlockImage.url}
                title={b.sideBlockTitle}
                link={link}
                isInternal={sideLink.isInternalPage}
              />
            </li>
            <li className={styles.sideBlock}>
              <SideBlock
                image={b.sideBlockImageBis.url}
                title={b.sideBlockTitleBis}
                link={linkBis}
                isInternal={sideLinkBis.isInternalPage}
              />
            </li>
          </Fragment>
        )
      })}
    </ul>
  )
}

export const fragment = graphql`
  fragment SideBlockFields on DatoCmsSideBlock {
    sideBlockTitle
    sideBlockImage {
      url
    }
    sideBlockInternalExternalLink {
      ...InternalExternalLinkFields
    }
    sideBlockTitleBis
    sideBlockImageBis {
      url
    }
    sideBlockInternalExternalLinkBis {
      ...InternalExternalLinkFields
    }
  }
`

export default SideBlocks
