import React from "react"
import { graphql } from "gatsby"
import { HomeNewsFieldsFragment } from "../../../../graphql-types"
import * as config from "../../../../config"
import * as styles from "./news.module.scss"
import { format, parse } from "date-fns"

type RenderProps = {
  news: HomeNewsFieldsFragment
  locale: string
  defaultLocale: string
}

const News: React.FC<RenderProps> = ({ news, locale, defaultLocale }) => {
  const categories = news.categories.map(category => category.title).join(", ")
  // Error displayed by date-format => RangeError: Use `yyyy` instead of `YYYY` (in `dd/MM/YYYY`) for formatting years to the input
  const dateFormat =
    config.date_format[config.locale] === "dd/MM/YYYY" ? "dd/MM/yyyy" : config.date_format[config.locale]
  const formattedDate = format(parse(news.publicationDate, "yyyy-MM-dd", new Date()), dateFormat)

  const slugLanguagePrefix = locale === defaultLocale ? "" : `/${locale}`
  return (
    <a href={`${slugLanguagePrefix}/news/${news.slug}`} className={styles.news}>
      <img src={news.image.url} alt={news.image.alt || ""} className={styles.image} />
      <div className={styles.content}>
        <h3 className={styles.title}>{news.title}</h3>
        {categories && <span>{categories}</span>}
        <time dateTime={news.publicationDate} className={styles.publicationDate}>
          {formattedDate}
        </time>
      </div>
    </a>
  )
}

export const homeNewsFragment = graphql`
  fragment HomeNewsFields on DatoCmsNews {
    __typename
    id
    title
    slug
    publicationDate
    publicationStopDate
    categories {
      title
    }
    image {
      alt
      url
    }
  }
`

export default News
