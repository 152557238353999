import React, { useEffect, useMemo, useRef, useState } from "react"
import { HeaderMenuFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./dropdown-menu-bar.module.scss"

type Props = {
  headerMenu: HeaderMenuFieldsFragment
  locale: string
  defaultLocale: string
}

export const KEY_CODES = Object.freeze({
  TAB: 9,
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  END: 35,
  HOME: 36,
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
})

type SubMenuType = NonNullable<
  NonNullable<
    HeaderMenuFieldsFragment["menuSection"]
  >[number]
>["subMenu"];

interface DisplayedMenu {
  label: string
  link: string | null
  subMenu: SubMenuType
  open: boolean
  target: string
}

const DropdownNavbar: React.FC<Props> = ({ headerMenu, locale, defaultLocale }) => {
  const [menus, setMenus] = useState<DisplayedMenu[]>([])
  const openByKeyboard = useRef<boolean>(false)
  const isMenuOpen = useMemo(() => menus.some(menu => menu.open), [menus])

  const extractDisplayedMenus = (selectedHeaderMenu: HeaderMenuFieldsFragment): DisplayedMenu[] => {
    return (selectedHeaderMenu.menuSection || [])
        .filter(menuItem => menuItem?.headerDisplay)
        .map(menuItem => ({
            label: menuItem?.label,
            link: menuItem?.page
                ? locale === defaultLocale
                    ? `/${menuItem.page.slug}`
                    : `/${locale}/${menuItem.page.slug}`
                : menuItem?.externalPage,
            subMenu: menuItem?.subMenu,
            open: false,
            target: menuItem?.page ? "_self" : menuItem?.externalPage ? "_blank" : null,
        }));
}

  // Extract menu displayed in header
  useEffect(() => {
    setMenus(extractDisplayedMenus(headerMenu))
  }, [headerMenu])

  // When the menu lose focus, we need to close it
  useEffect(() => {
    if (!isMenuOpen || typeof window === "undefined") {
      return
    }
    const onFocus = event => {
      if (!event.target.classList || !event.target.classList.contains("jsMenuFocusable")) {
        closeAllMenus()
        window.removeEventListener("focus", onFocus, true)
      }
    }
    window.addEventListener("focus", onFocus, true)
    return () => window.removeEventListener("focus", onFocus, true)
  }, [isMenuOpen])

  // Close menu on ESC
  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    const onKeyDown = event => {
      if (event.keyCode === KEY_CODES.ESC) {
        event.preventDefault()
        event.stopPropagation()
        closeAllMenus()
        return
      }
      return
    }

    window.addEventListener("keydown", onKeyDown)
    return () => window.removeEventListener("keydown", onKeyDown)
  }, [menus])

  function openMenu(menuIndex: number) {
    setMenus(
      menus.map((menu, index) => {
        return { ...menu, open: index === menuIndex }
      })
    )
  }

  function closeAllMenus() {
    openByKeyboard.current = false
    setMenus(menus.map(menu => ({ ...menu, open: false })))
  }

  function buildDropdownButton(displayedMenu: DisplayedMenu, menuIndex: number) {
    const parsedSubMenu = displayedMenu.subMenu?.filter(item => {
      return item.headerDisplay && ((item.page && item.page.slug) || item.externalPage)
    })

    function redirectToMenuPage(link) {
      if (!link) {
        return
      }
      if (typeof window !== "undefined") {
        window.location = link
      }
      return
    }

    return (
      <li className={styles.dropdownMenu} key={menuIndex}>
        <div onClick={() => redirectToMenuPage(displayedMenu.link)}>
          <a
            id={"dropdownButton-" + menuIndex}
            className={"jsMenuFocusable " + styles.dropdownMenuLabel}
            type="button"
            aria-controls={"menu" + menuIndex}
            aria-expanded={displayedMenu.open}
            tabIndex={0}
            onFocus={() => {
              openByKeyboard.current = true
              openMenu(menuIndex)
            }}
            onMouseEnter={() => openMenu(menuIndex)}
            href={displayedMenu.link}
            target={displayedMenu.target}
          >
            {displayedMenu.label}
          </a>
        </div>
        <div
          className={displayedMenu.open ? `${styles.dropdownMenuContent} ${styles.active}` : styles.dropdownMenuContent}
        >
          {parsedSubMenu?.length > 0 && (
            <ul
              id={"menu" + menuIndex}
              className={styles.subMenuItemList}
              aria-labelledby={"dropdownButton-" + menuIndex.toString()}
            >
              {parsedSubMenu.map(subMenu => buildDropdownSubmenuItem(subMenu))}
            </ul>
          )}
        </div>
        {parsedSubMenu?.length > 0 && <div onMouseEnter={!openByKeyboard.current ? closeAllMenus : undefined} />}
      </li>
    )
  }

  function buildDropdownSubmenuItem(subMenuItem) {
    if (subMenuItem.headerDisplay) {
      return (
        <li key={subMenuItem.id} className={styles.subMenuItem}>
          <a
            className={"jsMenuFocusable"}
            tabIndex={0}
            href={
              subMenuItem.page
                ? locale === defaultLocale
                  ? `/${subMenuItem.page.slug}`
                  : `/${locale}/${subMenuItem.page.slug}`
                : subMenuItem.externalPage
            }
            target={subMenuItem.page ? "_self" : subMenuItem.externalPage ? "_blank" : null}
          >
            {subMenuItem.label}
          </a>
        </li>
      )
    }
  }

  return (
    <nav className={styles.navbar} role="navigation" onMouseLeave={closeAllMenus}>
      <ul id="dropDownMenu" className={styles.navbarDropdownMenuList}>
        {menus.map(buildDropdownButton)}
      </ul>
    </nav>
  )
}

export default DropdownNavbar
