import React from "react"
import { graphql } from "gatsby"
import * as styles from "../type-page/type-page.module.scss"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

import TitleBanner from "../../components/type-page/title-banner/title-banner"
import Breadcrumb from "../../components/type-page/breadcrumb/breadcrumb"
import ShareButtons from "../../components/type-page/share-buttons/share-buttons"
import NewsBlocks from "../../components/type-page/news-blocks/news-blocks"
import SideBlocks from "../../components/type-page/side-blocks/side-blocks"

import RichDatoCmsContent from "../../components/rich-datocms-content/rich-datocms-content"
import { NewsListFieldsFragment, NewsPageQuery } from "../../../graphql-types"
import { isNewsPublished } from "../../components/all-news/all-news"
import { format } from "date-fns"
import * as config from "../../../config"
import * as menuInfo from "../../components/type-page/breadcrumb/breadcrumb"
import { useDetectScroll, useFormatLocale } from "../../hooks"
import pageViewEvent from "../../gtm/page-view-event"
import { RelativeLanguagePaths } from "../../components/layout/header/header-tool-bar/language-selector/language-selector"
import LoadScripts from "../../components/load-scripts"

type RenderProps = {
  data: NewsPageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    relativeLanguagePaths: RelativeLanguagePaths
  }
}

const NewsPage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const page = data.datoCmsNews
  const menuData = data.datoCmsMenu
  const lastThreeNews = data.allDatoCmsNews.nodes
    .filter((newsElement: NewsListFieldsFragment) => isNewsPublished(newsElement))
    .slice(0, 3)
  // Error displayed by date-format => RangeError: Use `yyyy` instead of `YYYY` (in `dd/MM/YYYY`) for formatting years to the input
  const dateFormat =
    config.date_format[config.locale] === "dd/MM/YYYY" ? "dd/MM/yyyy" : config.date_format[config.locale]
  const publicationDate = format(new Date(page.publicationDate), dateFormat)
  let sideBlocks = data.datoCmsNews.sideBlocks

  if (!sideBlocks || sideBlocks.length == 0) {
    sideBlocks = data.datoCmsGlobalConfiguration.sideBlocks
  }

  useDetectScroll()

  pageViewEvent(config.locale, data.site.siteMetadata.siteUrl, menuInfo, page.title)

  return (
    <Layout
      location={location}
      menuData={data.datoCmsMenu}
      footerData={data.datoCmsFooter}
      toolbarData={data.datoCmsGlobalConfiguration}
      scriptsData={data.datoCmsGlobalConfiguration}
      locale={useFormatLocale(pageContext.locale)}
      defaultLocale={useFormatLocale(pageContext.defaultLocale)}
      relativeLanguagePaths={pageContext.relativeLanguagePaths}
    >
      <div className={styles.typePage}>
        <TitleBanner title={page.title} />
        <Breadcrumb
          menuData={menuData}
          pageTitle={page.title}
          pageSlug={page.slug}
          locale={useFormatLocale(pageContext.locale)}
          defaultLocale={useFormatLocale(pageContext.defaultLocale)}
        />
        <div className={styles.typePageContent}>
          <div className={styles.content} style={{ width: data.datoCmsNews.hideRightSidebar ? "100%" : "75%" }}>
            <p>{publicationDate}</p>
            <p dangerouslySetInnerHTML={{ __html: page.newsIntroduction }} />
            <RichDatoCmsContent data={page.content} />
          </div>
          {!data.datoCmsNews.hideRightSidebar && (
            <div className={styles.rightSidebar}>
              <ShareButtons
                facebookDisplay={data.datoCmsGlobalConfiguration.fbDisplay}
                twitterDisplay={data.datoCmsGlobalConfiguration.twitterDisplay}
              />
              <NewsBlocks
                news={lastThreeNews}
                label={data.datoCmsGlobalConfiguration.lastNewsLabel}
                locale={useFormatLocale(pageContext.locale)}
                defaultLocale={useFormatLocale(pageContext.defaultLocale)}
              />
              <SideBlocks
                blocks={sideBlocks}
                locale={useFormatLocale(pageContext.locale)}
                defaultLocale={useFormatLocale(pageContext.defaultLocale)}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data }) => {
  return (
    <>
      <SEO title={data.datoCmsNews.title} />
      <LoadScripts scriptData={data.datoCmsGlobalConfiguration} />
    </>
  )
}

export const newsQuery = graphql`
  query NewsPage($locale: String!, $id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }

    datoCmsMenu(locale: $locale) {
      ...HeaderMenuFields
      ...FooterMenuFields
    }

    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }

    datoCmsGlobalConfiguration(locale: $locale) {
      fbDisplay
      twitterDisplay
      sideBlocks {
        ...SideBlockFields
      }
      ...AllNewsModuleConfigurationFields
      ...HeaderToolBarFields
      ...ScriptsFields
    }

    allDatoCmsNews(locale: $locale, sort: { publicationDate: DESC }) {
      nodes {
        __typename
        id
        title
        slug
        publicationDate
        publicationStopDate
      }
    }

    datoCmsNews(locale: $locale, id: { eq: $id }) {
      __typename
      title
      slug
      publicationDate
      publicationStopDate
      newsIntroduction
      hideRightSidebar
      sideBlocks {
        ...SideBlockFields
      }
      content {
        __typename
        ... on DatoCmsSimpleText {
          ...SimpleTextFields
        }
        ... on DatoCmsTextWithImage {
          ...TextWithImageFields
        }
        ... on DatoCmsTextWithVideo {
          ...TextWithVideoFields
        }
        ... on DatoCmsHighlight {
          ...HighlightFields
        }
        ... on DatoCmsVideoOnly {
          ...VideoOnlyFields
        }
        ... on DatoCmsEmbeddedIframe {
          ...EmbeddedIframeFields
        }
        ... on DatoCmsCustomText {
          ...CustomTextFields
        }
      }
    }
  }
`

export default NewsPage
