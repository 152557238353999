// extracted by mini-css-extract-plugin
export var categories = "all-news-module--categories--f9274";
export var images = "all-news-module--images--bb9cf";
export var link = "all-news-module--link--b5b1f";
export var newsCard = "all-news-module--newsCard--79a27";
export var newsCardContent = "all-news-module--newsCardContent--53cf1";
export var newsCardDescription = "all-news-module--newsCardDescription--d6b50";
export var newsCardDescriptionContent = "all-news-module--newsCardDescriptionContent--652a6";
export var newsCardHeader = "all-news-module--newsCardHeader--195a2";
export var newsCardImageBox = "all-news-module--newsCardImageBox--ffb22";
export var newsCardList = "all-news-module--newsCardList--9eae7";
export var newsTitle = "all-news-module--newsTitle--d6328";
export var publicationDate = "all-news-module--publicationDate--dbb7e";
export var truncateContent = "all-news-module--truncate-content--3f141";
export var truncateOverflow = "all-news-module--truncate-overflow--76760";