import { useEffect, useState } from "react"
import { pushGtmPageEvent } from "../gtm/interaction-event"

const pageViewEvent = (locale: string, urlOriginal: string, menuData: any, title: string) => {
  const [pageView, setPageView] = useState(false)

  useEffect(() => {
    if (pageView || !window) {
      return
    }
    let host = ""
    if (window.location) {
      host = window.location.host
    }
    let pages = menuData?.pageInfo
    const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches
    const searchPattern = new RegExp("^" + "qksa")
    const isQualificationStage = searchPattern.test(host)
    let validPath = pages.parent && pages.parentSlug && pages.selected
    pushGtmPageEvent(
      "pageview",
      locale,
      urlOriginal,
      isMobile ? "mobile" : "desktop",
      isQualificationStage ? "qualifcation" : "production",
      title,
      window.location.hostname,
      null,
      validPath ? pages.parent : pages?.selected || window.location.pathname.split("/")[1],
      validPath ? pages.parentSlug + "/" + pages.selected.split(" ").join("-").toLowerCase() + "/" : ""
    )
    setPageView(true)
  })
}

export default pageViewEvent
