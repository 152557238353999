import React, { useEffect, useState } from "react"
import { SecondaryBlockFieldsFragment } from "../../../../graphql-types"
import * as styles from "./secondary-blocks.module.scss"
import { useIsMobile } from "../../../hooks"
import SecondaryBlock from "./secondary-block"

type RenderProps = {
  blocks: SecondaryBlockFieldsFragment[]
  label: string
  locale: string
  defaultLocale: string
}

const SecondaryBlocks: React.FC<RenderProps> = ({ blocks, label, locale, defaultLocale }) => {
  const isMobile = useIsMobile()
  const [isActive, setIsActive] = useState(0)

  useEffect(() => handleScrollAnimation, [isMobile])

  const handleScrollAnimation = () => {
    if (!isMobile) return

    const container = document.getElementsByClassName(styles.secondaryBlocks)[0]
    container.addEventListener("scroll", () => {
      const allBlocks = Array.from(document.getElementsByClassName(styles.secondaryBlock))
      allBlocks.forEach((block, i) => {
        if (block.getBoundingClientRect().left < 10) {
          setIsActive(i + 1)
        }
      })
    })
  }

  return (
    <div className={styles.secondaryBlocksWrapper}>
      <ul className={styles.secondaryBlocks} aria-labelledby={label} onScroll={handleScrollAnimation}>
        {blocks.map((block, i) => (
          <li className={styles.secondaryBlock} key={block.id}>
            <SecondaryBlock
              block={block}
              isActive={isMobile && isActive === i}
              locale={locale}
              defaultLocale={defaultLocale}
            />
          </li>
        ))}
        {isMobile && <div className={styles.secondaryBlock} />}
      </ul>
    </div>
  )
}

export default SecondaryBlocks
