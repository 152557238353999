import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import {
  DatoCmsCriterionList,
  DatoCmsSearchCriterion,
  TransportTitleListFieldsFragment,
  TransportTitleSearchModuleConfigFieldsFragment,
} from "../../../graphql-types"
import * as styles from "./transport-title-search.module.scss"
import { useIsMobile } from "../../hooks"
import Capsule from "./capsule/capsule"
import TransportTitle from "./transport-title/transport-title"
import { searchIcon } from "../../../static/images"

type RenderProps = {
  label: string
  transportTitleSearchModuleConfig: TransportTitleSearchModuleConfigFieldsFragment
  transportTitleSearchData: TransportTitleListFieldsFragment[]
}

const TransportTitleSearch: React.FC<RenderProps> = ({
  label,
  transportTitleSearchData,
  transportTitleSearchModuleConfig,
}) => {
  const isMobile = useIsMobile()
  const transportTitles: TransportTitleListFieldsFragment[] = transportTitleSearchData
  const [filters, setFilters] = useState<string[]>([])
  const [filteredTransportTitles, setFilteredTransportTitles] = useState(transportTitles)

  const buildTransportTitleCards = () => (
    <div className={styles.transportTitles}>
      {filteredTransportTitles.map((transportTitle: TransportTitleListFieldsFragment) => (
        <div key={transportTitle.id} className={styles.transportTitle}>
          <TransportTitle transportTitleData={transportTitle} />
        </div>
      ))}
    </div>
  )

  const updateFilters = (criterion: string, criterionGroup: DatoCmsCriterionList) => {
    if (!filters.includes(criterion)) {
      const criterionTitles = criterionGroup.criterion.map(item => item.title)
      const filteredArray = filters.filter(item => !criterionTitles.includes(item))
      setFilters([...filteredArray, criterion])
    } else {
      const removeSelectedFilter = filters.filter(item => item !== criterion)
      setFilters(removeSelectedFilter)
    }
  }

  const updateTransportTitles = (searchFilters: string[]) => {
    const newTransportTitles = transportTitles.filter((transportTitle: TransportTitleListFieldsFragment) => {
      const transportTitleCriteria: string[] = transportTitle.searchCriterion.map((item: DatoCmsSearchCriterion) => {
        return item.title
      })
      return searchFilters.every((criterion: string) => transportTitleCriteria.includes(criterion))
    })
    setFilteredTransportTitles(newTransportTitles)
  }

  useEffect(() => {
    updateTransportTitles(filters)
  }, [filters])

  return (
    <>
      <div className={styles.transportTitleSearch}>
        {!isMobile && (
          <div className={styles.transportTitleSearchBanner}>
            <img className={styles.searchIcon} src={searchIcon} alt="Search icon" />
            {label}
          </div>
        )}
        <div className={styles.transportTitlesCriteria}>
          {transportTitleSearchModuleConfig.searchCriterionList.map((criterionGroup: DatoCmsCriterionList, index) => (
            <div className={styles.searchSection} key={index}>
              <div className={styles.searchSectionLabel}>{criterionGroup.label}</div>
              <div className={styles.searchSectionCapsules}>
                {criterionGroup.criterion.map((criterion: DatoCmsSearchCriterion, idx) => (
                  <Capsule
                    key={idx}
                    label={criterion.title}
                    handleClick={(e: React.MouseEvent<HTMLInputElement>) =>
                      updateFilters(e.currentTarget.value, criterionGroup)
                    }
                    filters={filters}
                  />
                ))}
              </div>
            </div>
          ))}
          {!isMobile && filteredTransportTitles && buildTransportTitleCards()}
        </div>
      </div>
      {isMobile && filteredTransportTitles && buildTransportTitleCards()}
    </>
  )
}

export const fragments = graphql`
  fragment TransportTitleSearchModuleConfigFields on DatoCmsTitleAndPrice {
    searchCriterionList {
      id
      label
      criterion {
        id
        title
      }
    }
  }

  fragment TransportTitleListFields on DatoCmsTransportTitle {
    __typename
    id
    title
    description
    redirectionUrl
    redirectionLabel
    picture {
      format
      url
      alt
    }
    searchCriterion {
      title
      id
    }
    position
  }
`

export default TransportTitleSearch
