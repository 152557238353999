import React, { useEffect, useState } from "react"
import { LinesListFieldsFragment, SchedulesSearchModuleFieldsFragment } from "../../../graphql-types"
import { graphql } from "gatsby"
import * as styles from "./schedules-search.module.scss"
import sanitizeHtml from "sanitize-html"
import SchedulesSearchResults from "./results/schedules-search-results"
import SchedulesSearchForm, { FormResult } from "./form/schedules-search-form"
import { StringParam, useQueryParam } from "use-query-params"

type Line = LinesListFieldsFragment

type RenderProps = {
  lines: Line[]
  label: string
  moduleConfiguration: SchedulesSearchModuleFieldsFragment
}

const SchedulesSearch: React.FC<RenderProps> = ({ lines, label, moduleConfiguration }) => {
  const [lineId] = useQueryParam("line", StringParam)
  const [municipalityId] = useQueryParam("municipality", StringParam)
  const [showResults, setShowResults] = useState(false)
  const [searchCriteria, setSearchCriteria] = useState<FormResult>({
    line: null,
    municipality: null,
    pointOfInterest: null,
  })
  const linesWithSchedules = lines.filter(line => line.schedules.length > 0)
  useEffect(() => {
    if (lineId || municipalityId) {
      setShowResults(true)
    }
    const matchingLine = linesWithSchedules.find(line => line.id === lineId)
    const matchingMunicipality = linesWithSchedules.reduce(
      (foundMunicipality, line) =>
        foundMunicipality || line.municipalities.find(municipality => municipality.id === municipalityId),
      null
    )
    setSearchCriteria({
      ...searchCriteria,
      line: matchingLine,
      municipality: matchingMunicipality,
    })
  }, [lineId, municipalityId])

  function onCriteriaChange(criteria) {
    setShowResults(true)
    setSearchCriteria(criteria)
    if (typeof window !== "undefined") {
      document.getElementById("js-schedules-search-result-label")?.focus()
    }
  }

  return (
    <section aria-label={label}>
      <div className={styles.searchForm}>
        <h2 className={styles.title}>{moduleConfiguration.blockTitle}</h2>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(moduleConfiguration.description),
          }}
        />
        <SchedulesSearchForm
          lines={lines}
          moduleConfiguration={moduleConfiguration}
          onChange={onCriteriaChange}
          lineValue={searchCriteria.line}
          municipalityValue={searchCriteria.municipality}
        />
      </div>
      <div className="results" id="schedules-search-results">
        {showResults && (
          <SchedulesSearchResults
            lines={linesWithSchedules}
            selectedLine={searchCriteria.line}
            selectedMunicipality={searchCriteria.municipality}
            selectedPointOfInterest={searchCriteria.pointOfInterest}
            moduleConfiguration={moduleConfiguration}
          />
        )}
      </div>
    </section>
  )
}

export const fragments = graphql`
  fragment LinesListFields on DatoCmsLine {
    id
    name
    number
    pictogram {
      ...SchedulesSearchPictogram
    }
    transportMethod {
      ...SchedulesSearchTransportMethodsFields
    }
    lineType {
      id
      label
    }
    schedules {
      id
      label
      link
      file {
        url
      }
    }
    pointsOfInterest {
      id
      name
      municipality {
        id
        name
      }
    }
    municipalities {
      id
      name
    }
    lineMap {
      url
    }
  }

  fragment SchedulesSearchTransportMethodsFields on DatoCmsTransportMethod {
    id
    name
    pictogram {
      ...SchedulesSearchTransportMethodPictogram
    }
  }

  fragment SchedulesSearchModuleFields on DatoCmsSchedulesSearchModule {
    blockTitle
    description
    linesFieldLabel
    linesFieldPlaceholder
    linesFieldIcon {
      ...SchedulesSearchFormIcon
    }
    municipalitiesFieldLabel
    municipalitiesFieldPlaceholder
    municipalitiesFieldIcon {
      ...SchedulesSearchFormIcon
    }
    pointsOfInterestFieldLabel
    pointsOfInterestFieldPlaceholder
    pointsOfInterestFieldIcon {
      ...SchedulesSearchFormIcon
    }
    submitButtonLabel
    scheduleIcon {
      ...SchedulesSearchLinkIcon
    }
    lineMapIcon {
      ...SchedulesSearchLinkIcon
    }
    lineMapLinkLabel
    resultsLabel
    noResultLabel
    lineErrorMessage
    municipalityErrorMessage
  }

  fragment SchedulesSearchPictogram on DatoCmsFileField {
    alt
    format
    url
  }

  fragment SchedulesSearchFormIcon on DatoCmsFileField {
    alt
    url
    format
  }

  fragment SchedulesSearchLinkIcon on DatoCmsFileField {
    alt
    url
    format
  }

  fragment SchedulesSearchTransportMethodPictogram on DatoCmsFileField {
    alt
    format
    url
  }
`

export default SchedulesSearch
