const operatorIds = 7
const lePiloteBaseUrl = "https://www.lepilote.com/"

export const getInfoTraffic = async (lang: string) => {
  const apiUrl = `https://tsvc.pilote3.cityway.fr:443/api/transport/v3/disruption/GetActiveDisruptions/json?Lang=${lang}&OperatorIds=${operatorIds}`
  const requestOptions = { method: "GET" }
  return fetch(apiUrl, requestOptions).then(async response => {
    const data = await response.json()
    if (!response.ok) {
      const error = data || response.statusText
      return Promise.reject(error)
    }
    return includeBaseUrlInLinks(data)
  })
}

export const getLines = (lang: string) => {
  const apiUrl = `https://tsvc.pilote3.cityway.fr:443/api/transport/v3/line/GetLines/json?Lang=${lang}&OperatorIds=${operatorIds}`
  const requestOptions = { method: "GET" }
  return fetch(apiUrl, requestOptions).then(async response => {
    const data = await response.json()
    if (!response.ok) {
      const error = data || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}

const includeBaseUrlInLinks = infoTrafficData => {
  return {
    ...infoTrafficData,
    Data: infoTrafficData.Data.map(infoTraffic => ({
      ...infoTraffic,
      Description: infoTraffic.Description?.replace(/href="\//g, 'href="' + lePiloteBaseUrl),
    })),
  }
}
