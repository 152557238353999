import React from "react"
import { NextDeparturesModuleConfigurationFieldsFragment } from "../../../graphql-types"
import { graphql } from "gatsby"
import * as styles from "./next-departures-module.module.scss"

type RenderProps = {
  label: string
  moduleConfiguration: NextDeparturesModuleConfigurationFieldsFragment
}

const NextDeparturesModule: React.FC<RenderProps> = ({ label, moduleConfiguration }) => {
  return (
    <section className={styles.nextDeparturesModule}>
      <iframe
        className={styles.iframe}
        title={label}
        src={moduleConfiguration.iframeLink + (typeof window !== "undefined" ? window.location.search : "")}
        sandbox="allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts allow-downloads"
      />
    </section>
  )
}

export const fragments = graphql`
  fragment NextDeparturesModuleConfigurationFields on DatoCmsNextDeparturesModuleConfiguration {
    iframeLink
  }
`

export default NextDeparturesModule
