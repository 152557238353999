import React from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { SecondaryBlockFieldsFragment } from "../../../../graphql-types"
import * as styles from "./secondary-block.module.scss"
import { pushGtmEvent } from "../../../gtm/interaction-event"
import { useInternalExternalLink } from "../../../hooks"

type RenderProps = {
  block: SecondaryBlockFieldsFragment
  isActive: boolean
  locale: string
  defaultLocale: string
}

const SecondaryBlock: React.FC<RenderProps> = ({ block, isActive, locale, defaultLocale }) => {
  return (
    <a
      href={useInternalExternalLink(block.internalExternalLink[0], locale, defaultLocale)}
      className={`${styles.secondaryBlock} ${isActive && styles.isActive}`}
      onClick={() => pushGtmEvent("eventga", "Homepage", "Bloc secondaire", block.label)}
    >
      {block.image.format === "svg" ? (
        <ReactSVG
          src={block.image.url}
          className={styles.svgImage}
          beforeInjection={svg => svg.setAttribute("aria-label", block.image.alt || "")}
        />
      ) : (
        <img src={block.image.url} className={styles.image} alt={block.image.alt || ""} />
      )}
      <span className={styles.link}>{block.label}</span>
    </a>
  )
}

export const fragment = graphql`
  fragment SecondaryBlockFields on DatoCmsSecondaryBlock {
    id
    label
    image {
      alt
      format
      url
    }
    internalExternalLink {
      ...InternalExternalLinkFields
    }
  }

  fragment SecondaryBlocksLabelFields on DatoCmsHome {
    secondaryBlocksLabel
    secondaryBlocksPreviousLabel
    secondaryBlocksNextLabel
  }
`

export default SecondaryBlock
