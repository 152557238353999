// extracted by mini-css-extract-plugin
export var arrowBox = "footer-module--arrowBox--52e4d";
export var arrowBoxOpen = "footer-module--arrowBoxOpen--8a1f5";
export var arrowIcon = "footer-module--arrowIcon--f6ec3";
export var collapsibleContent = "footer-module--collapsibleContent--dcfa0";
export var collapsibleContentOpen = "footer-module--collapsibleContentOpen--238b4";
export var collapsibleFooter = "footer-module--collapsibleFooter--8aaa4";
export var collapsibleFooterLogos = "footer-module--collapsibleFooterLogos--00ebb";
export var globalFooter = "footer-module--globalFooter--21f0d";
export var hideOnMobile = "footer-module--hideOnMobile--e0f61";
export var keolisLogo = "footer-module--keolisLogo--3c6ca";
export var organizingAuthorityLogo = "footer-module--organizingAuthorityLogo--5b857";
export var organizingAuthorityLogoBox = "footer-module--organizingAuthorityLogoBox--18dfe";
export var organizingAuthorityTitle = "footer-module--organizingAuthorityTitle--ac4e2";
export var showOnMobile = "footer-module--showOnMobile--89058";
export var socialNetworkIcon = "footer-module--socialNetworkIcon--dde9e";
export var socialNetworksIcons = "footer-module--socialNetworksIcons--e8171";
export var svgImage = "footer-module--svgImage--c5e69";
export var transportIcon = "footer-module--transportIcon--1e86f";
export var transportIcons = "footer-module--transportIcons--7b273";