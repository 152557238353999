import React from "react"
import { graphql } from "gatsby"
import { HeaderToolBarFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./header-tool-bar.module.scss"
import { searchIcon } from "../../../../../static/images"

type RenderProps = {
  toolbarData: HeaderToolBarFieldsFragment
  locale: string
  defaultLocale: string
}

const HeaderToolBar: React.FC<RenderProps> = ({ toolbarData, locale, defaultLocale }) => {
  // FIELDS TO BE DELETED ?
  /*const phoneNumber: string = toolbarData.contactPhoneNumber
  const contactLabel: string = toolbarData.contactLabel*/

  const [searchTerm, setSearchTerm] = React.useState("")

  const handleChange = event => {
    setSearchTerm(event.target.value)
  }

  React.useEffect(() => {}, [searchTerm])

  const focusInput = () => {
    if (searchTerm && searchTerm.length == 0) {
      document.getElementById("searchBar").focus()
    }
  }

  return (
    <div className={styles.headerToolBar}>
      <div className={styles.headerToolBarContent}>
        <div className={styles.searchBarBox}>
          <form action={locale === defaultLocale ? `/search` : `/${locale}/search`} method="get" role="search">
            <input
              id="searchBar"
              type="text"
              name="query"
              placeholder="Vous recherchez..."
              value={searchTerm}
              title={toolbarData.noSearchLabel}
              onChange={handleChange}
            />
            <button type="submit" onClick={focusInput}>
              <img src={searchIcon} alt={toolbarData.searchButtonLabel} />
            </button>
          </form>
        </div>
        {/*{contactLabel || phoneNumber ? (
          <div className={styles.phoneBox}>
            <a
              href={`tel:${phoneNumber}`}
              onClick={() =>
                pushGtmEvent(
                  "eventga",
                  "Header",
                  "Renseignement",
                  typeof window !== "undefined" ? window.location.href : ""
                )
              }
            >
              <img src={phoneIcon} alt={toolbarData.altText}></img>
            </a>
            {!isMobile ? (
              phoneNumber ? (
                <a
                  href={`tel:${phoneNumber}`}
                  onClick={() =>
                    pushGtmEvent(
                      "eventga",
                      "Header",
                      "Renseignement",
                      typeof window !== "undefined" ? window.location.href : ""
                    )
                  }
                >
                  {contactLabel} {phoneNumber}
                </a>
              ) : (
                <label
                  onClick={() =>
                    pushGtmEvent(
                      "eventga",
                      "Header",
                      "Renseignement",
                      typeof window !== "undefined" ? window.location.href : ""
                    )
                  }
                >
                  {contactLabel} {phoneNumber}
                </label>
              )
            ) : null}
          </div>
        ) : null}*/}
      </div>
    </div>
  )
}

export const queryFragments = graphql`
  fragment HeaderToolBarFields on DatoCmsGlobalConfiguration {
    contactLabel
    contactPhoneNumber
    altText
    searchButtonLabel
    noSearchLabel
    primaryColor {
      hex
    }
    subsidiaryLogo {
      url
      alt
    }
  }
`

export default HeaderToolBar
