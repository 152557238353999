import { graphql } from "gatsby"
import React from "react"
import { InfoTrafficFieldsFragment } from "../../../graphql-types"
import RichDatoCmsContent from "../rich-datocms-content/rich-datocms-content"
import * as styles from "./info-traffic-detail.module.scss"
import sanitizeHtml from "sanitize-html"

type RenderProps = {
  data: InfoTrafficFieldsFragment
}

/*
  PREVIOUS IMPLEMENTATION OF INFO TRAFFIC DETAILS
  ONLY CALLED WHEN INFO TRAFIC (IN REAL TIME)IS NOT CORRECTLY SET ON DATOCMS
*/

const InfoTrafficDetails: React.FC<RenderProps> = ({ data }) => {
  const infoTraffic = data

  return (
    <div className={styles.container}>
      <ul className={styles.linesPictoContainer}>
        {infoTraffic.lines.map(line => {
          return (
            <li key={line.id} className={styles.listNoStyle}>
              <div className={styles.linePictoBox}>
                <img className={styles.linePicto} src={line.pictogram.url} alt={line.pictogram.alt} />
              </div>
            </li>
          )
        })}
      </ul>
      <div className={styles.titleBox}>
        <div className={styles.pictoBoxTraffic}>
          <img src={infoTraffic.pictogram.url} alt={infoTraffic.pictogram.alt} />
        </div>
        <h3 className={styles.title}>{infoTraffic.title}</h3>
      </div>

      <div
        className={styles.shortDescription}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(infoTraffic.shortDescription),
        }}
      />
      <RichDatoCmsContent data={infoTraffic.description}></RichDatoCmsContent>
    </div>
  )
}

export default InfoTrafficDetails

export const fragments = graphql`
  fragment InfoTrafficFields on DatoCmsInfoTraffic {
    model {
      name
    }
    id
    title
    disruptionType
    pictogram {
      alt
      url
    }
    lines {
      id
      name
      number
      pictogram {
        alt
        url
      }
    }
    shortDescription
    slug
    description {
      ... on DatoCmsSimpleText {
        __typename
        id
        content
      }
      ... on DatoCmsCustomText {
        __typename
        id
        customContent {
          blocks
          links {
            __typename
            ... on DatoCmsPage {
              id: originalId
              slug
              title
            }
            ... on DatoCmsHome {
              id: originalId
              title
            }
            ... on DatoCmsDocument {
              id: originalId
              title
              document {
                id
                url
                alt
                title
              }
            }
          }
          value
        }
      }
      ... on DatoCmsTextWithImage {
        __typename
        id
        text
        image {
          alt
          format
          url
        }
      }
    }
  }
`
