import React, { useEffect, useState } from "react"
import * as styles from "./autocomplete.module.scss"
import * as inputsStyles from "../layout/inputs.module.scss"
import Autosuggest from "react-autosuggest"
import { ReactSVG } from "react-svg"
import { arrowDown } from "../../../static/images"
import { Controller, Control } from "react-hook-form"

type RenderProps<T> = {
  id: string
  name?: string
  placeholder?: string
  title?: string
  isItineraryForm?: boolean
  suggestFunction: (query: string) => T[]
  toLabel: (item: T) => string
  onFocus?: (value: any) => void
} & (
  | {
      value?: T
      onChange?: (item: T | null) => void
      control: Control
    }
  | {
      value?: T
      onChange: (item: T | null) => void
      control?: Control
    }
)

function Autocomplete<T>({
  id,
  name,
  suggestFunction,
  onChange,
  toLabel,
  placeholder,
  title,
  value,
  control,
  onFocus,
  isItineraryForm,
}: RenderProps<T>) {
  const [fieldValue, setFieldValue] = useState<string>("")
  const [suggestions, setSuggestions] = useState<T[]>([])
  useEffect(() => {
    setFieldValue(value ? toLabel(value) : "")
  }, [value])
  return (
    <div className={isItineraryForm ? styles.wrapper + " " + styles.wrapperFullWidth : styles.wrapper}>
      {!isItineraryForm ? (
        <ReactSVG
          src={arrowDown}
          className={inputsStyles.arrowDown}
          beforeInjection={svg => svg.setAttribute("aria-hidden", "true")}
        />
      ) : null}
      {wrapWithController(name, control, value, ({ handleChange, handleBlur }) => (
        <Autosuggest
          name={name}
          id={`autoSuggest${id}`}
          suggestions={suggestions}
          onBlur={handleBlur}
          onFocus={event => {
            onFocus && onFocus(event.target.id)
          }}
          onSuggestionsFetchRequested={({ value: valueFetched }) => {
            const suggestionsList = suggestFunction(valueFetched)
            const exactMatch = suggestionsList.find(
              suggestion => toLabel(suggestion).toLowerCase() === valueFetched.toLowerCase()
            )
            handleChange(exactMatch)
            onChange && onChange(exactMatch)
            setSuggestions(suggestionsList)
          }}
          onSuggestionsClearRequested={() => setSuggestions([])}
          getSuggestionValue={toLabel}
          renderSuggestion={suggestion => <span>{toLabel(suggestion)}</span>}
          onSuggestionSelected={(_, { suggestion }) => {
            handleChange(suggestion)
            onChange && onChange(suggestion)
          }}
          highlightFirstSuggestion={true}
          inputProps={{
            id: id,
            title: title,
            value: fieldValue,
            onChange(_, { newValue }) {
              return setFieldValue(newValue)
            },
            placeholder: placeholder,
          }}
          shouldRenderSuggestions={() => true}
          theme={{
            container: styles.container,
            input: styles.input,
            inputFocused: styles.inputFocused,
            suggestionsContainer: styles.suggestionsContainer,
            suggestionsContainerOpen: styles.suggestionsContainerOpen,
            suggestionsList: styles.suggestionsList,
            suggestion: styles.suggestion,
            suggestionHighlighted: styles.suggestionHighlighted,
          }}
        />
      ))}
    </div>
  )
}

function wrapWithController<T>(
  name: string,
  control: Control | null,
  value: T | null,
  renderInput: ({ handleChange, handleBlur }) => any
) {
  if (!control) {
    return renderInput({ handleChange: () => {}, handleBlur: () => {} })
  }
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={value}
      render={({ field: { onChange, onBlur } }) => renderInput({ handleChange: onChange, handleBlur: onBlur })}
    />
  )
}

export default Autocomplete
