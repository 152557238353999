import React, { useEffect } from "react"
import { TerDakarItinerarySearchModuleFieldsFragment } from "../../../graphql-types"
import * as styles from "./ter-dakar-itinerary-search-module.module.scss"

type RenderProps = {
  data: TerDakarItinerarySearchModuleFieldsFragment
}

const TerDakarItinerarySearchModule: React.FC<RenderProps> = ({ data }) => {
  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }
    const script = document.createElement("script")
    document.getElementsByTagName("body")[0].appendChild(script)
    script.async = true
    script.type = "text/javascript"
    script.src = `https://56.preprod-sim.instant-system.com/fr/widget/place-journey-map?token=${data.token}`
  }, [])

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }
    const dakarWidget = document.getElementById("is-WidgetWrapper")
    dakarWidget.parentElement.style.zIndex = "2"
  }, [])

  return (
    <div>
      <h3 className={styles.title}>{data.title}</h3>
      <div className={styles.widgetWrapper} id="is-WidgetWrapper" />
      <div className={styles.widgetWrapper} id="is-MapWrapper" />
    </div>
  )
}

export default TerDakarItinerarySearchModule
