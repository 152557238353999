import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { FooterBarMenuFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./footer-bar-menu.module.scss"
import sanitizeHtml from "sanitize-html"

declare const Didomi: any

type RenderProps = {
  barMenu: FooterBarMenuFieldsFragment[]
  locale: string
  defaultLocale: string
}

const FooterBarMenu: React.FC<RenderProps> = ({ barMenu, locale, defaultLocale }) => {
  useEffect(() => {
    barMenu.forEach(x => {
      if (x.__typename === "DatoCmsFooterPage") {
        x.label = x.label.replace(" cookies", '&nbsp;<span lang="en">cookies</span>')
      }
    })
  }, [])

  return (
    <ul className={styles.collapsibleContentBottomMenu}>
      {barMenu.map((barMenuItem, index) =>
        barMenuItem.__typename === "DatoCmsFooterPage" ? (
          <li className={styles.barMenuItem} key={index}>
            {barMenuItem.page ? (
              <a
                href={locale === defaultLocale ? `/${barMenuItem.page.slug}` : `/${locale}/${barMenuItem.page.slug}`}
                className={styles.barMenuLabel}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(barMenuItem.label, { allowedAttributes: { span: ["lang"] } }),
                }}
              ></a>
            ) : (
              <div key={barMenuItem.label} className={styles.barMenuLabel}>
                {barMenuItem.label}
              </div>
            )}
          </li>
        ) : (
          barMenuItem.__typename === "DatoCmsBannerDidomi" && (
            <li className={styles.barMenuItem} key={index}>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  Didomi.notice.show()
                }}
                className={styles.barMenuLabel}
              >
                {barMenuItem.footerLabel}
              </a>
            </li>
          )
        )
      )}
    </ul>
  )
}

export const fragment = graphql`
  fragment FooterBarMenuFields on DatoCmsUnionForDatoCmsFooterBarMenu {
    ... on DatoCmsFooterPage {
      __typename
      label
      page {
        slug
      }
    }
    ... on DatoCmsBannerDidomi {
      __typename
      footerLabel
    }
  }
`

export default FooterBarMenu
