import React, { useState } from "react"
import {
  SmsAlertInfoTrafficFormConfigurationFieldsFragment,
  CustomFormsConfigurationFieldsFragment,
  SmsAlertFormFieldsFragment,
} from "../../../graphql-types"
import { graphql } from "gatsby"
import * as styles from "./sms-alert-form.module.scss"
import { useForm } from "react-hook-form"
import Input, { ValidationType } from "../layout/form/input"
import * as inputsStyles from "../layout/inputs.module.scss"
import FormError from "../layout/form/form-error"
import Autocomplete from "../autocomplete/autocomplete"
import DateInput from "../layout/form/date-input"
import Checkbox from "../layout/form/checkbox"
import { registerCustomerToSmsAlertRequest } from "../../api/mobireport-alert-sms"
import { pushGtmEvent } from "../../gtm/interaction-event"

type RenderProps = {
  moduleConfiguration: SmsAlertInfoTrafficFormConfigurationFieldsFragment
  formsConfiguration: CustomFormsConfigurationFieldsFragment
  title: SmsAlertFormFieldsFragment["title"]
  lines: any
  singleLineData: any
  secondSingleLineData: any
}

const SubscribeSmsAlertInfoTrafficForm: React.FC<RenderProps> = ({
  lines,
  title,
  moduleConfiguration,
  formsConfiguration,
  singleLineData,
  secondSingleLineData,
}) => {
  const [selectedLines, setSelectedLines] = useState([])
  let sortingArr = [
    "A",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "M1",
    "M2",
    "M3",
    "3sp",
    "5sp",
    "8sp",
    "9sp",
    "10sp",
    "12sp",
    "15sp",
    "26sp",
    "m3sp",
  ]

  function sortLines() {
    lines.push(singleLineData)
    lines.push(secondSingleLineData)
    let sortedLines = lines.filter(
      e =>
        sortingArr.includes(e.reference) ||
        sortingArr.includes(e.reference?.replace(/^0+/, "")?.toLowerCase()) ||
        sortingArr.includes(e.reference?.toLowerCase())
    )
    let ordered = sortingArr.map(element =>
      sortedLines.find(x => x.reference?.replace(/^0+/, "")?.toLowerCase() == element || x.reference == element)
    )
    return ordered
  }

  const orderedLines = sortLines()

  const [lineError, setLineError] = useState(false)
  const [firstClick, setFirstClick] = useState(true)

  const [formState, setFormState] = useState<FormState>(FormState.Pending)
  const [allNetworkState, setNetworkSelected] = useState(false)
  const [formValues, setFormValues] = useState<Map<string, string | boolean | FileList>>(new Map())
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  let pickedLines = []
  function searchLine(query: string) {
    pickedLines.push(orderedLines.filter(result => result.reference.toLowerCase().indexOf(query.toLowerCase()) !== -1))
    setSelectedLines(pickedLines)
    setLineError(false)
    return orderedLines.filter(result => result?.reference.toLowerCase().indexOf(query.toLowerCase()) !== -1)
  }

  function pushGtmFirstClickEvent() {
    if (firstClick) {
      pushGtmEvent("Form", "Form", "Form 1st click", title)
      setFirstClick(false)
    }
  }

  function onSubmit(formData) {
    if ((!selectedLines && allNetworkState === false) || (selectedLines.length === 0 && allNetworkState === false)) {
      setLineError(true)
      return
    } else {
      setLineError(false)
    }
    pushGtmEvent("eventga", "Form", "Form Submit", title)
    let requestReponse = registerCustomerToSmsAlertRequest(formData)
    return Promise.all([requestReponse]).then(
      () => setFormState(FormState.Success),
      () => setFormState(FormState.Error)
    )
  }

  let lineSelectionFields = []
  for (var i = 0; i < 5; i++) {
    lineSelectionFields.push(
      <div className={styles.textField}>
        <Autocomplete
          id={"lineField"}
          name={"line" + i.toString()}
          toLabel={line => line.reference}
          control={control}
          suggestFunction={searchLine}
        />
        <div className={inputsStyles.formError}>{lineError ? formsConfiguration.requiredFieldError : ""}</div>
      </div>
    )
  }

  function onChange(fieldId, value) {
    const newValues = new Map(formValues)
    newValues.set(fieldId, value)
    setFormValues(newValues)
    if (fieldId == "allNetworkCheckboxField") {
      setNetworkSelected(value)
      if (allNetworkState == false && (!selectedLines || selectedLines.length === 0)) {
        setLineError(false)
      } else {
        setLineError(true)
      }
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.customForm} noValidate={true}>
        <h2>{title}</h2>

        <div className={inputsStyles.formPart + " " + styles.formPart}>
          <label htmlFor="family-name" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
            {moduleConfiguration.nameFieldLabel}
          </label>
          <Input
            id="family-name"
            type="text"
            name="family-name"
            autocomplete={true}
            placeholder={moduleConfiguration.nameFieldPlaceholder}
            required={true}
            register={register}
            hasErrors={!!errors["family-name"]}
            className={styles.textField}
            onFocus={pushGtmFirstClickEvent}
          />
          <FormError errors={errors} name="family-name" type="required">
            {formsConfiguration.requiredFieldError}
          </FormError>
        </div>

        <div className={inputsStyles.formPart + " " + styles.formPart}>
          <label htmlFor="given-name" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
            {moduleConfiguration.firstnameFieldLabel}
          </label>
          <Input
            id="given-name"
            type="text"
            name="given-name"
            autocomplete={true}
            placeholder={moduleConfiguration.firstnameFieldPlaceholder}
            required={true}
            register={register}
            hasErrors={!!errors["given-name"]}
            className={styles.textField}
            onFocus={pushGtmFirstClickEvent}
          />
          <FormError errors={errors} name="given-name" type="required">
            {formsConfiguration.requiredFieldError}
          </FormError>
        </div>

        <div className={inputsStyles.formPart + " " + styles.formPart}>
          <label htmlFor="bday" className={inputsStyles.label}>
            {moduleConfiguration.birthdateFieldLabel}
          </label>
          <DateInput
            id="bday"
            name="bday"
            autocomplete={true}
            required={false}
            control={control}
            maxDate={new Date()}
            className={styles.birthdateInput}
            minDate={null}
            previousMonthAlt={moduleConfiguration.calendarPreviousMonthButtonAlt}
            nextMonthAlt={moduleConfiguration.calendarNextMonthButtonAlt}
            onFocus={pushGtmFirstClickEvent}
          />
        </div>

        <div className={inputsStyles.formPart + " " + styles.formPart}>
          <label htmlFor="address" className={inputsStyles.label}>
            {moduleConfiguration.addressFieldLabel}
          </label>
          <Input
            id="address"
            type="text"
            name="address"
            //autocomplete={true}
            placeholder={moduleConfiguration.addressFieldPlaceholder}
            register={register}
            className={styles.textField}
            onFocus={pushGtmFirstClickEvent}
          />
        </div>

        <div className={inputsStyles.formPart + " " + styles.formPart}>
          <label htmlFor="city" className={inputsStyles.label}>
            {moduleConfiguration.cityFieldLabel}
          </label>
          <Input
            id="city"
            type="text"
            name="city"
            placeholder={moduleConfiguration.cityFieldPlaceholder}
            register={register}
            className={styles.textField}
            onFocus={pushGtmFirstClickEvent}
          />
        </div>

        <div className={inputsStyles.formPart + " " + styles.formPart}>
          <label htmlFor="postal-code" className={inputsStyles.label}>
            {moduleConfiguration.zipCodeFieldLabel}
          </label>
          <Input
            id="postal-code"
            type="text"
            name="postal-code"
            autocomplete={true}
            placeholder={moduleConfiguration.zipCodeFieldPlaceholder}
            register={register}
            className={styles.textField}
            onFocus={pushGtmFirstClickEvent}
          />
        </div>

        <div className={inputsStyles.formPart + " " + styles.formPart}>
          <label htmlFor="tel" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
            {moduleConfiguration.phoneFieldLabel}
          </label>
          <Input
            id="tel"
            type="text"
            name="tel"
            autocomplete={true}
            placeholder={moduleConfiguration.phoneFieldPlaceholder}
            required={true}
            register={register}
            hasErrors={!!errors["tel"]}
            className={styles.textField}
            validationType={ValidationType.PhoneNumberE164}
            onFocus={pushGtmFirstClickEvent}
          />
          <FormError errors={errors} name="tel" type="pattern">
            {moduleConfiguration.invalidPhoneErrorMessage}
          </FormError>
          <FormError errors={errors} name="tel" type="required">
            {formsConfiguration.requiredFieldError}
          </FormError>
        </div>

        <div className={inputsStyles.formPart + " " + styles.formPart}>
          <label htmlFor="email" className={inputsStyles.label}>
            {moduleConfiguration.emailFieldLabel}
          </label>
          <Input
            id="email"
            type="text"
            name="email"
            autocomplete={true}
            placeholder={moduleConfiguration.emailFieldPlaceholder}
            register={register}
            validationType={ValidationType.Email}
            hasErrors={!!errors["email"]}
            className={styles.textField}
            onFocus={pushGtmFirstClickEvent}
          />
          <FormError errors={errors} name="email" type="pattern">
            {formsConfiguration.emailFieldError}
          </FormError>
        </div>

        <div className={inputsStyles.formPart + " " + styles.formPart}>
          <label
            htmlFor="lineField"
            className={
              selectedLines.length === 0 ? inputsStyles.label + " " + inputsStyles.labelRequired : inputsStyles.label
            }
          >
            {moduleConfiguration.lineFieldLabel}
          </label>
          <div>{lineSelectionFields}</div>
        </div>

        <div className={inputsStyles.formPart + " " + styles.formPart + " " + styles.allNetworkCheckbox}>
          <Checkbox
            id={"allNetworkCheckboxField"}
            label={moduleConfiguration.allNetworkCheckboxFieldLabel}
            name={"allNetworkCheckbox"}
            /*             checked={!!formValues.get("allNetworkCheckboxField")}
            onChange={value => onChange("allNetworkCheckboxField", value)} */
            className={inputsStyles.label}
            register={register}
            onFocus={pushGtmFirstClickEvent}
          />
        </div>

        <div className={inputsStyles.formPart + " " + styles.formPart + " " + styles.formCheckbox}>
          <Checkbox
            id={"userAgreementCheckboxField"}
            label={moduleConfiguration.userAgreementCheckboxFieldLabel}
            name={"userAgreementCheckboxField"}
            required={true}
            /*             checked={!!formValues.get("userAgreementCheckboxField")}
            onChange={value => onChange("userAgreementCheckboxField", value)} */
            hasErrors={!!errors["userAgreementCheckboxField"]}
            register={register}
            className={inputsStyles.label}
            onFocus={pushGtmFirstClickEvent}
          />
          <a
            href={
              moduleConfiguration.userAgreementLink[0].page
                ? moduleConfiguration.userAgreementLink[0].page.slug
                : moduleConfiguration.userAgreementLink[0].document
                  ? moduleConfiguration.userAgreementLink[0].document.url
                  : ""
            }
          >
            {moduleConfiguration.userAgreementLink[0].label}
          </a>

          <FormError errors={errors} name={"userAgreementCheckboxField"} type="required">
            {formsConfiguration.requiredFieldError}
          </FormError>
        </div>

        <div className={inputsStyles.formPart + " " + styles.formPart + " " + styles.formCheckbox}>
          <Checkbox
            id={"otherOffersCheckboxField"}
            label={moduleConfiguration.otherOffersCheckboxFieldLabel}
            name={"otherOffersCheckboxField"}
            /*             checked={!!formValues.get("otherOffersCheckboxField")}
            onChange={value => onChange("otherOffersCheckboxField", value)} */
            register={register}
            className={inputsStyles.label}
            onFocus={pushGtmFirstClickEvent}
          />
        </div>

        <button
          type="submit"
          className={inputsStyles.primaryButton + " " + styles.submitButton}
          disabled={formState === FormState.Submitting || formState === FormState.Success}
        >
          {moduleConfiguration.submitButtonLabel}
        </button>
        {formState === FormState.Error && (
          <div className={inputsStyles.formError + " " + styles.globalError} aria-live="assertive" role="alert">
            {moduleConfiguration.errorMessage}
          </div>
        )}
        {formState === FormState.Success && (
          <div className={styles.successMessage} aria-live="polite">
            {moduleConfiguration.successMessage}
          </div>
        )}
      </form>
    </div>
  )
}

export const fragments = graphql`
  fragment SmsAlertInfoTrafficFormConfigurationFields on DatoCmsSmsAlertInfoTrafficFormConfiguration {
    id
    nameFieldPlaceholder
    nameFieldLabel
    firstnameFieldPlaceholder
    firstnameFieldLabel
    birthdateFieldLabel
    birthdateFieldPlaceholder
    addressFieldLabel
    addressFieldPlaceholder
    cityFieldLabel
    cityFieldPlaceholder
    errorMessage
    emailFieldPlaceholder
    emailFieldLabel
    zipCodeFieldPlaceholder
    zipCodeFieldLabel
    successMessage
    submitButtonLabel
    phoneFieldPlaceholder
    phoneFieldLabel
    invalidPhoneErrorMessage
    lineFieldLabel
    allNetworkCheckboxFieldLabel
    otherOffersCheckboxFieldLabel
    userAgreementCheckboxFieldLabel
    userAgreementLink {
      label
      page {
        slug
      }
      document {
        url
      }
    }
    loadingErrorMessage
    calendarPreviousMonthButtonAlt
    calendarNextMonthButtonAlt
  }

  fragment SmsAlertFormFields on DatoCmsSmsAlertForm {
    __typename
    id
    title
  }

  fragment CustomFormsConfigurationFields on DatoCmsFormsConfiguration {
    requiredFieldError
    phoneNumberFieldError
    emailFieldError
    logoSubsidiary {
      alt
      url
    }
  }
`

enum FormState {
  Pending,
  Submitting,
  Success,
  Error,
}

export default SubscribeSmsAlertInfoTrafficForm
