import React, { useMemo, useState } from "react"
import {
  TicketOfficeSearchFieldsFragment,
  TicketOfficeSearchModuleConfigurationFieldsFragment,
} from "../../../graphql-types"
import { graphql } from "gatsby"
import * as styles from "./ticket-office-search.module.scss"
import "mapbox-gl/dist/mapbox-gl.css"
import TicketOfficeSearchResults from "./results/ticket-office-search-results"
import TicketOfficeSearchForm, { FormResult } from "./form/ticket-office-search-form"
import TicketOfficeSearchMap from "./map/ticket-office-search-map"

type TicketOffice = TicketOfficeSearchFieldsFragment
type Municipality = TicketOffice["municipality"]
type Category = TicketOffice["category"]

type RenderProps = {
  ticketOffices: TicketOffice[]
  label: string
  moduleConfiguration: TicketOfficeSearchModuleConfigurationFieldsFragment
  mapboxToken: string
}

const TicketOfficeSearch: React.FC<RenderProps> = ({ label, moduleConfiguration, ticketOffices, mapboxToken }) => {
  const [searchCriteria, setSearchCriteria] = useState<FormResult>({
    municipality: null,
    categories: [],
  })

  const filteredTicketOffices = useMemo(
    () => ticketOffices.filter(filterMatchingTicketOffice(searchCriteria.municipality, searchCriteria.categories)),
    [searchCriteria, ticketOffices]
  )

  return (
    <section aria-label={label} className={styles.ticketOfficeSearch}>
      <div className={styles.searchForm}>
        <h2 className={styles.title}>{moduleConfiguration.title}</h2>
        <TicketOfficeSearchForm
          ticketOffices={ticketOffices}
          moduleConfiguration={moduleConfiguration}
          onChange={setSearchCriteria}
        />
      </div>

      <div className={styles.ticketOfficeList} id="schedules-search-results" tabIndex={0}>
        <TicketOfficeSearchResults ticketOffices={filteredTicketOffices} moduleConfiguration={moduleConfiguration} />
      </div>

      <div className={styles.map}>
        <TicketOfficeSearchMap
          mapboxToken={mapboxToken}
          ticketOffices={filteredTicketOffices}
          moduleConfiguration={moduleConfiguration}
          selectedMunicipality={searchCriteria.municipality}
        />
      </div>
    </section>
  )
}

function filterMatchingTicketOffice(selectedMunicipality: Municipality | null, selectedCategories: Category[]) {
  return ticketOffice => {
    const matchMunicipality = !selectedMunicipality || ticketOffice.municipality.id === selectedMunicipality.id
    const matchCategories =
      selectedCategories.length === 0 || selectedCategories.some(category => category.id === ticketOffice.category.id)

    return matchMunicipality && matchCategories
  }
}

export const fragments = graphql`
  fragment TicketOfficeSearchMapConfigurationFields on DatoCmsGlobalConfiguration {
    mapboxToken
  }

  fragment TicketOfficeSearchFields on DatoCmsTicketOffice {
    id
    name
    category {
      id
      name
      pictogram {
        url
      }
    }
    email
    location {
      latitude
      longitude
    }
    municipality {
      ...TicketOfficeSearchMunicipalitiesFields
    }
    extraFields {
      ... on DatoCmsExtraFieldsBlock {
        title
        other
      }
    }
    address
    zipcode
    phoneNumber
    picture {
      ...TicketOfficePicture
    }
  }

  fragment TicketOfficeSearchMunicipalitiesFields on DatoCmsMunicipality {
    id
    name
    location {
      latitude
      longitude
    }
  }

  fragment TicketOfficeSearchModuleConfigurationFields on DatoCmsTicketOfficeModule {
    title
    municipalityLabel
    municipalityPlaceholder
    municipalityIcon {
      ...TicketOfficeFormIcon
    }
    categoryLabel
    categoryIcon {
      ...TicketOfficeFormIcon
    }
    addressLabel
    addressIcon {
      ...TicketOfficeAddressContactIcon
    }
    contactLabel
    contactIcon {
      ...TicketOfficeAddressContactIcon
    }
    mapCenter {
      latitude
      longitude
    }
  }

  fragment TicketOfficePicture on DatoCmsFileField {
    alt
    format
    url
  }

  fragment TicketOfficeFormIcon on DatoCmsFileField {
    alt
    format
    url
  }

  fragment TicketOfficeAddressContactIcon on DatoCmsFileField {
    alt
    format
    url
  }
`

export default TicketOfficeSearch
