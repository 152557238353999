export function getFieldName(fieldName) {
  if (fieldName == undefined || !fieldName) {
    return undefined
  }
  let cleanFieldName = fieldName.toLowerCase().replaceAll("é", "e")
  const tel = ["tel", "telephone", "phone", "tel.", "tel-national", "numero de telephone"]
  const email = ["email", "mail", "e-mail", "courriel", "courriel electronique"]
  const givenName = ["prenom", "firstname", "first-name", "givenname", "given-name", "given name"]
  const familyName = ["nom de famille", "nom", "patronyme", "name", "family name", "family-name"]
  const country = ["pays", "country", "pays de residence"]
  const prefix = ["civilite", "civ.", "civi."]
  let newRegex = new RegExp(`${cleanFieldName}`)
  let realName = undefined

  tel.forEach(element => {
    if (newRegex.test(element)) {
      realName = "tel-national"
    }
  })
  email.forEach(element => {
    if (newRegex.test(element)) {
      realName = "email"
    }
  })
  givenName.forEach(element => {
    if (newRegex.test(element)) {
      realName = "given-name"
    }
  })
  familyName.forEach(element => {
    if (newRegex.test(element)) {
      realName = "family-name"
    }
  })
  prefix.forEach(element => {
    if (newRegex.test(element)) {
      realName = "honorific-prefix"
    }
  })
  country.forEach(element => {
    if (newRegex.test(element)) {
      realName = "country-name"
    }
  })
  return realName
}
