import React, { createContext, useState, useEffect } from "react"

type LinesSubscriptionContextData = {
  subscribedLines: string[]
  toggleSubscription: (lineId: string) => void
  isSubscribed: (lineId: string) => boolean
}

export const LinesSubscriptionContext = createContext<LinesSubscriptionContextData | undefined>(undefined)

export const LinesSubscriptionProvider = ({ children }) => {
  const [subscribedLines, setSubscribedLines] = useState(() => {
    try {
      const savedSubscriptions = JSON.parse(localStorage.getItem("subscribedLines")) || []
      return savedSubscriptions
    } catch (error) {
      console.error("Error while retrieving subscribed lines :", error)
      return []
    }
  })

  useEffect(() => {
    const handleStorageChange = event => {
      if (event.key === "subscribedLines") {
        try {
          const newSubscribedLines = event.newValue ? JSON.parse(event.newValue) : []
          setSubscribedLines(newSubscribedLines)
        } catch (error) {
          console.error("Error while updating subscribed lines :", error)
        }
      }
    }
    window.addEventListener("storage", handleStorageChange)
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  const normalizeDatoCmsId = lineId => {
    if (lineId.startsWith("DatoCmsLine-")) {
      return lineId.replace("DatoCmsLine-", "")
    }
    return lineId
  }

  const toggleSubscription = (lineId: string) => {
    if (!lineId) {
      console.error("Invalid lineId:", lineId)
      return
    }
    lineId = normalizeDatoCmsId(lineId)

    setSubscribedLines(prevSubscriptions => {
      let updatedSubscriptions
      if (prevSubscriptions.includes(lineId)) {
        // Unsubscribe
        updatedSubscriptions = prevSubscriptions.filter(sub => sub !== lineId)
      } else {
        // Subscribe
        updatedSubscriptions = [...prevSubscriptions, lineId]
      }
      localStorage.setItem("subscribedLines", JSON.stringify(updatedSubscriptions))
      return updatedSubscriptions
    })
  }

  const isSubscribed = (lineId: string) => {
    if (!lineId) {
      console.error("Invalid lineId:", lineId)
      return
    }
    return subscribedLines.includes(normalizeDatoCmsId(lineId))
  }

  return (
    <LinesSubscriptionContext.Provider value={{ subscribedLines, toggleSubscription, isSubscribed }}>
      {children}
    </LinesSubscriptionContext.Provider>
  )
}
