import React, { useEffect, useState } from "react"
import {
  LinesListFieldsFragment,
  SchedulesSearchFormIconFragment,
  SchedulesSearchModuleFieldsFragment,
} from "../../../../graphql-types"
import Autocomplete from "../../autocomplete/autocomplete"
import * as styles from "./schedules-search-form.module.scss"
import * as inputsStyles from "../../layout/inputs.module.scss"
import { ReactSVG } from "react-svg"
import { pushGtmEvent } from "../../../gtm/interaction-event"

type Line = LinesListFieldsFragment
type Municipality = LinesListFieldsFragment["municipalities"][0]
type PointOfInterest = LinesListFieldsFragment["pointsOfInterest"][0]

type RenderProps = {
  lines: Line[]
  moduleConfiguration: SchedulesSearchModuleFieldsFragment
  lineValue: Line | null
  municipalityValue: Municipality | null
  onChange: (value: FormResult) => void
}

export type FormResult = {
  line: Line | null
  municipality: Municipality | null
  pointOfInterest: PointOfInterest | null
}

const SchedulesSearchForm: React.FC<RenderProps> = ({
  lines,
  moduleConfiguration,
  lineValue,
  municipalityValue,
  onChange,
}) => {
  const [selectedLine, setSelectedLine] = useState(null)
  const [selectedMunicipality, setSelectedMunicipality] = useState(null)
  const [selectedPointOfInterest, setSelectedPointOfInterest] = useState(null)
  const [municipalityError, setMunicipalityError] = useState(false)
  const [lineError, setLineError] = useState(false)

  const linesWithSchedulesOrPlan = lines.filter(line => line.schedules.length > 0 || line.lineMap)

  useEffect(() => {
    setSelectedLine(lineValue)
    if (selectedLine || lineValue) {
      setLineError(false)
    }
  }, [lineValue])
  useEffect(() => {
    setSelectedMunicipality(municipalityValue)
    if (selectedMunicipality || municipalityValue) {
      setMunicipalityError(false)
    }
  }, [municipalityValue])

  useEffect(() => {
    if (selectedLine) {
      setLineError(false)
    }
  }, [selectedLine])

  useEffect(() => {
    if (selectedMunicipality) {
      setMunicipalityError(false)
    }
  }, [selectedMunicipality])

  function searchLines(query) {
    const filteredLines = filterLines(linesWithSchedulesOrPlan, selectedMunicipality, selectedPointOfInterest)
    if (!selectedLine) {
      setLineError(true)
    }
    return search(query, filteredLines, toLabel)
  }

  function searchMunicipalities(query) {
    const filteredMunicipalities = filterMunicipalities(linesWithSchedulesOrPlan, selectedLine, selectedPointOfInterest)
    if (!selectedMunicipality) {
      setMunicipalityError(true)
    }
    return search(query, filteredMunicipalities, toLabel)
  }

  function searchPointsOfInterest(query) {
    const filteredPointsOfInterest = filterPointOfInterests(
      linesWithSchedulesOrPlan,
      selectedLine,
      selectedMunicipality
    )
    return search(query, filteredPointsOfInterest, toLabel)
  }

  function sendChange(event) {
    pushGtmEvent(
      "eventga",
      "Schedule Search",
      `${selectedLine ? selectedLine.number : ""}_${selectedMunicipality ? selectedMunicipality.name : ""}_${
        selectedPointOfInterest ? selectedPointOfInterest.name : ""
      }`,
      ""
    )
    onChange({
      line: selectedLine,
      municipality: selectedMunicipality,
      pointOfInterest: selectedPointOfInterest,
    })
    event.preventDefault()
  }

  return (
    <form onSubmit={sendChange}>
      <div className={styles.searchFormFields}>
        {/* Lines */}
        {filterLines(lines).length > 0 && (
          <div className={styles.formPart}>
            {displayImage(moduleConfiguration.linesFieldIcon)}
            <div className={styles.field}>
              <label htmlFor="linesInput">{moduleConfiguration.linesFieldLabel}</label>
              <Autocomplete
                onChange={setSelectedLine}
                suggestFunction={searchLines}
                toLabel={toLabel}
                id="linesInput"
                value={lineValue}
                placeholder={moduleConfiguration.linesFieldPlaceholder}
                title={moduleConfiguration.linesFieldPlaceholder}
              />
              <div className={styles.errorMessage}>{lineError ? moduleConfiguration.lineErrorMessage : null}</div>
            </div>
          </div>
        )}

        {/* Municipalities */}
        {filterMunicipalities(lines).length > 0 && (
          <div className={styles.formPart}>
            {displayImage(moduleConfiguration.municipalitiesFieldIcon)}
            <div className={styles.field}>
              <label htmlFor="municipalitiesInput">{moduleConfiguration.municipalitiesFieldLabel}</label>
              <Autocomplete
                onChange={setSelectedMunicipality}
                suggestFunction={searchMunicipalities}
                toLabel={toLabel}
                id="municipalitiesInput"
                value={municipalityValue}
                placeholder={moduleConfiguration.municipalitiesFieldPlaceholder}
                title={moduleConfiguration.municipalitiesFieldPlaceholder}
              />
              <div className={styles.errorMessage}>
                {municipalityError ? moduleConfiguration.municipalityErrorMessage : null}
              </div>
            </div>
          </div>
        )}

        {/* Points of interest */}
        {filterPointOfInterests(lines).length > 0 && (
          <div className={styles.formPart}>
            {displayImage(moduleConfiguration.pointsOfInterestFieldIcon)}
            <div className={styles.field}>
              <label htmlFor="pointsOfInterestInput">{moduleConfiguration.pointsOfInterestFieldLabel}</label>
              <Autocomplete
                onChange={setSelectedPointOfInterest}
                suggestFunction={searchPointsOfInterest}
                toLabel={toLabel}
                id="pointsOfInterestInput"
                placeholder={moduleConfiguration.pointsOfInterestFieldPlaceholder}
                title={moduleConfiguration.pointsOfInterestFieldPlaceholder}
              />
            </div>
          </div>
        )}
      </div>
      <button type="submit" className={inputsStyles.primaryButton + " " + styles.searchButton}>
        {moduleConfiguration.submitButtonLabel}
      </button>
    </form>
  )
}

const displayImage = (image: SchedulesSearchFormIconFragment) => {
  if (image.format === "svg") {
    return (
      <ReactSVG
        src={image.url}
        className={styles.image}
        beforeInjection={svg => svg.setAttribute("aria-label", image.alt || "")}
      />
    )
  }

  return <img src={image.url} alt={image.alt || ""} className={styles.image} aria-hidden="true" />
}

function filterMunicipalities(
  lines: Line[],
  selectedLine?: Line | null,
  selectedPointOfInterest?: PointOfInterest | null
): Municipality[] {
  if (selectedPointOfInterest) {
    if (!selectedLine) {
      return [selectedPointOfInterest.municipality]
    }
    return selectedLine.municipalities.filter(
      municipality => municipality.id === selectedPointOfInterest.municipality.id
    )
  }
  return Array.from(
    lines
      .reduce((municipalities, line) => {
        if (selectedLine && selectedLine.id !== line.id) {
          return municipalities
        }
        line.municipalities.forEach(municipality => municipalities.set(municipality.id, municipality))
        return municipalities
      }, new Map<string, Municipality>())
      .values()
  ).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
}

function filterPointOfInterests(
  lines: Line[],
  selectedLine?: Line | null,
  selectedMunicipality?: Municipality | null
): PointOfInterest[] {
  if (selectedLine) {
    return selectedLine.pointsOfInterest.filter(pointOfInterest => {
      return !selectedMunicipality || selectedMunicipality.id === pointOfInterest.municipality.id
    })
  }
  return Array.from(
    lines
      .reduce((pointsOfInterest, line) => {
        const isNotSelectedLine = selectedLine && selectedLine.id !== line.id
        if (isNotSelectedLine) {
          return pointsOfInterest
        }
        line.pointsOfInterest.forEach(pointOfInterest => {
          const matchesSelectedMunicipality =
            !selectedMunicipality || selectedMunicipality.id === pointOfInterest.municipality.id
          if (matchesSelectedMunicipality) {
            pointsOfInterest.set(pointOfInterest.id, pointOfInterest)
          }
        })
        return pointsOfInterest
      }, new Map<string, PointOfInterest>())
      .values()
  )
}

function filterLines(
  lines: Line[],
  selectedMunicipality?: Municipality | null,
  selectedPointOfInterest?: PointOfInterest | null
): Line[] {
  return lines.filter(line => {
    const matchesSelectedMunicipality =
      !selectedMunicipality || line.municipalities.some(municipality => municipality.id === selectedMunicipality.id)
    const matchesSelectedPointOfInterest =
      !selectedPointOfInterest ||
      line.pointsOfInterest.some(pointOfInterest => pointOfInterest.id === selectedPointOfInterest.id)
    return matchesSelectedMunicipality && matchesSelectedPointOfInterest
  })
}

function search<T>(query: string, elements: T[], toLabelFn: (element: T) => string): T[] {
  return elements.filter(result => toLabelFn(result).toLowerCase().indexOf(query.toLowerCase()) !== -1)
}

function toLabel(element?: any) {
  return element ? element.name : ""
}

export default SchedulesSearchForm
