import React from "react"
import * as styles from "./footer-table-menu.module.scss"
import { FooterMenuFieldsFragment } from "../../../../../graphql-types"

type RenderProps = {
  data: FooterMenuFieldsFragment
  locale: string
  defaultLocale: string
}

const FooterTableMenu: React.FC<RenderProps> = ({ data, locale, defaultLocale }) => {
  return (
    <div className={styles.tableMenu}>
      {data.menuSection?.map((menuSection, i) => (
        <div key={`menuSection${i}`} className={styles.tableMenuItem}>
          {menuSection.externalPage || menuSection.page?.slug ? (
          <h3 className={styles.footerElementTitle}>
          <a
            id={"footerMainMenuLabel" + "Number" + i}
            href={
              menuSection.externalPage
                ? menuSection.externalPage
                : locale === defaultLocale
                ? `/${menuSection.page?.slug}`
                : `/${locale}/${menuSection.page?.slug}`
            }
          >
            <span className={styles.tableMenuLabel}>{menuSection.label}</span>
          </a>
        </h3>
        ) : (
          <h3 className={styles.footerElementTitle}>
            <span className={styles.tableMenuLabelTitle}>{menuSection.label}</span>
          </h3>
        )}
          <ul className={styles.footerListElement}>
            {menuSection.subMenu?.map((subMenuItem, index) => {
              const link = subMenuItem?.page
                ? locale === defaultLocale
                  ? `/${subMenuItem.page.slug}`
                  : `/${locale}/${subMenuItem.page.slug}`
                : subMenuItem?.externalPage
              return subMenuItem?.footerDisplay ? (
                <li
                  className={styles.footerListElement}
                  key={`subMenuLabel${i}-${index}`}
                >
                  <a
                    key={subMenuItem.label}
                    className={styles.tableMenuSubLabel}
                    href={link}
                  >
                    {subMenuItem.label}
                  </a>
                </li>
              ) : null
            })}
          </ul>
        </div>
      ))}
    </div>
  )
}

export default FooterTableMenu
