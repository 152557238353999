import {
  DatoCmsDatoCmsLostObjectsFormConfigurationSubsidiaryEmailContentStructuredText,
  DatoCmsDatoCmsLostObjectsFormConfigurationUserEmailContentStructuredText,
  LostObjectsFormConfigurationFieldsFragment,
} from "../../../graphql-types"
import { render } from "datocms-structured-text-to-html-string"
import { StructuredText as StructuredTextGraphQlResponse } from "datocms-structured-text-utils/dist/types/types"
import { format } from "date-fns"
import * as config from "../../../config"
import sanitizeHtml from "sanitize-html"
import { decode } from "html-entities"

export function lostObjectsFormToText(
  content:
    | DatoCmsDatoCmsLostObjectsFormConfigurationUserEmailContentStructuredText
    | DatoCmsDatoCmsLostObjectsFormConfigurationSubsidiaryEmailContentStructuredText,
  moduleConfiguration: LostObjectsFormConfigurationFieldsFragment,
  values: { [key: string]: any }
) {
  const renderFormContent = (renderNode, renderText) => {
    return [
      {
        label: moduleConfiguration.nameFieldLabel,
        value: values["family-name"],
      },
      {
        label: moduleConfiguration.firstnameFieldLabel,
        value: values["given-name"],
      },
      { label: moduleConfiguration.addressFieldLabel, value: values.address },
      {
        label: moduleConfiguration.zipCodeFieldLabel,
        value: values["postal-code"],
      },
      { label: moduleConfiguration.cityFieldLabel, value: values.city },
      { label: moduleConfiguration.phoneFieldLabel, value: values.tel },
      { label: moduleConfiguration.emailFieldLabel, value: values.email },
      {
        label: moduleConfiguration.dateFieldLabel,
        value: values.date && format(values.date, config.date_format[config.locale]),
      },
      {
        label: moduleConfiguration.timeFieldLabel,
        value: values.time && format(values.time, config.time_format[config.locale]),
      },
      {
        label: moduleConfiguration.lineFieldLabel,
        value: values.line && values.line.name,
      },
      {
        label: moduleConfiguration.directionFieldLabel,
        value: values.direction,
      },
      { label: moduleConfiguration.stopFieldLabel, value: values.stop },
      { label: moduleConfiguration.messageFieldLabel, value: values.message },
    ].map(({ label, value }) => {
      return renderNode("tr", {}, [
        renderNode("th", {}, renderText(decode(cleanHtmlTags(label)))),
        renderNode("td", {}, renderText(decode(cleanHtmlTags(value)))),
      ])
    })
  }
  const options = {
    renderBlock(block) {
      if (block.fragmentType === "Email content") {
        return block.renderNode("table", {}, renderFormContent(block.adapter.renderNode, block.adapter.renderText))
      } else {
        console.log("Unhandled fragment: " + block.fragmentType)
      }
    },
    renderInlineRecord({ record: block, adapter: { renderNode, renderText } }) {
      if (block.fragmentType === "Form content") {
        return renderNode("table", {}, renderFormContent(renderNode, renderText))
      } else {
        console.log("Unhandled fragment: " + block.fragmentType)
      }
    },
  }
  return render(content as unknown as StructuredTextGraphQlResponse, options)
}

function cleanHtmlTags(value: string) {
  return sanitizeHtml(value, {
    allowedTags: [],
    allowedAttributes: {},
  })
}
