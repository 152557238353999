// extracted by mini-css-extract-plugin
export var alertBanner = "alert-banner-module--alertBanner--1d58d";
export var alertBannerBody = "alert-banner-module--alertBannerBody--0fcd2";
export var alertBannerButton = "alert-banner-module--alertBannerButton--a25dc";
export var alertBannerClosed = "alert-banner-module--alertBannerClosed--1fdf9";
export var alertBannerContentWrapper = "alert-banner-module--alertBannerContentWrapper--cb855";
export var alertBannerDataContent = "alert-banner-module--alertBannerDataContent--c3aaa";
export var alertBannerLink = "alert-banner-module--alertBannerLink--3b676";
export var closeButton = "alert-banner-module--closeButton--d0b2a";
export var closeButtonWrapper = "alert-banner-module--closeButtonWrapper--eab83";
export var images = "alert-banner-module--images--5b8d5";
export var infoTrafficDate = "alert-banner-module--infoTrafficDate--18979";
export var shortDescription = "alert-banner-module--shortDescription--d9ddc";
export var title = "alert-banner-module--title--dc8a5";