import * as config from "../../config"
import { EmailReceiversFieldsFragment } from "../../graphql-types"

export const importFiles = async (file: File): Promise<SavedFile> => {
  const headers = { Accept: "application/json", "Ocp-Apim-Subscription-Key": config.notifications_service_token }
  const formData = new FormData()
  formData.append("file", file)

  const response = await fetch(config.notifications_service_import_file_endpoint, {
    method: "POST",
    headers,
    body: formData,
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json()
}

export const formatReceivers = (receivers: EmailReceiversFieldsFragment[]) => {
  const emailJson = {
    to: "",
    cc: "",
    bcc: "",
  }

  receivers.forEach(receiver => {
    switch (receiver.sendType) {
      case "Principal":
        emailJson.to += receiver.email + ";"
        break
      case "CC":
        emailJson.cc += receiver.email + ";"
        break
      case "CCI":
        emailJson.bcc += receiver.email + ";"
        break
    }
  })

  emailJson.to = emailJson.to.slice(0, -1)
  emailJson.cc = emailJson.cc.slice(0, -1)
  emailJson.bcc = emailJson.bcc.slice(0, -1)

  return emailJson
}

export const sendEmail = async (
  from: string,
  receivers: EmailReceiversFieldsFragment[],
  subject: string,
  content: string,
  files: File[],
  replyTo: string
): Promise<Response> => {
  const formattedContent = content.replace(/\n/g, "<br>")
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": config.notifications_service_token,
  }
  const filesPromise = files.length > 0 ? Promise.all(files.map(importFiles)) : Promise.resolve([])
  const importedFiles = await filesPromise

  const { to, cc, bcc } = formatReceivers(receivers)

  const response = await fetch(config.notifications_service_endpoint, {
    method: "POST",
    headers,
    body: JSON.stringify({
      notificationType: "Email",
      notificationContent: {
        from,
        to,
        replyTo,
        cc,
        bcc,
        emailType: "NoTemplateWithLogoAndAttachments",
        content: {
          subject,
          htmlMessage: formattedContent,
          attachments: importedFiles,
        },
      },
    }),
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response
}

export const sendEmailV2 = async (
  trigram: string,
  idForm: string,
  content: string,
  files: File[],
  email?: string
): Promise<Response> => {
  const formattedContent = content.replace(/\n/g, "<br>")
  const endpoint = config.serverless_sendmail_endpoint
  const ocpmSubscriptionKey = config.serverless_sendmail_token
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": ocpmSubscriptionKey,
  }
  const filesPromise = files.length > 0 ? Promise.all(files.map(importFiles)) : Promise.resolve([])
  const importedFiles = await filesPromise

  const body = JSON.stringify({
    trigram: trigram,
    idForm: idForm,
    htmlMessage: formattedContent,
    email: email || null,
    attachments: importedFiles,
  })
  console.log(body)
  const response = await fetch(endpoint, {
    method: "POST",
    headers,
    body: body,
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response
}

// We don't want to know the type because we're not supposed to do anything with it.
// If that assumption changes, see the OAS of the service (on Keolis.Notifications repository on Azure devops)
type SavedFile = unknown
