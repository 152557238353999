import React, { useEffect, useState } from "react"
import {
  SmsAlertInfoTrafficFormConfigurationFieldsFragment,
  CustomFormsConfigurationFieldsFragment,
  SmsAlertFormFieldsFragment,
} from "../../../graphql-types"
import { graphql } from "gatsby"
import SubscribeSmsAlertInfoTrafficForm from "./subscribe-sms-alert-form"
import { getLinesFromApi, getSingleLineFromApi } from "../../api/mobireport-alert-sms"
import * as styles from "./sms-alert-form.module.scss"
import * as config from "../../../config.js"

type RenderProps = {
  moduleConfiguration: SmsAlertInfoTrafficFormConfigurationFieldsFragment
  formsConfiguration: CustomFormsConfigurationFieldsFragment
  title: SmsAlertFormFieldsFragment["title"]
}

const SmsAlertInfoTrafficForms: React.FC<RenderProps> = ({ title, moduleConfiguration, formsConfiguration }) => {
  const [linesData, setLinesData] = useState([])
  const [singleLineData, setSingleLineData] = useState([])
  const [secondSingleLineData, setSecondSingleLineData] = useState([])

  const [showLoader, setShowLoader] = useState(true)
  useEffect(() => {
    const singleLineResponse = getSingleLineFromApi("26SP").then(items => {
      Promise.all([singleLineResponse]).then(
        () => setSingleLineData(items),
        () => setSingleLineData([])
      )
    })
    const secondSingleLineResponse = getSingleLineFromApi("M3SP").then(items => {
      Promise.all([secondSingleLineResponse]).then(
        () => setSecondSingleLineData(items),
        () => setSecondSingleLineData([])
      )
    })
    const requestResponse = getLinesFromApi().then(items => {
      Promise.all([requestResponse]).then(
        () => setLinesData(items),
        () => setLinesData([])
      )
    })
    setShowLoader(false)
  }, [])

  function componentToDisplay() {
    if (linesData.length != 0) {
      return (
        <SubscribeSmsAlertInfoTrafficForm
          lines={linesData}
          title={title}
          moduleConfiguration={moduleConfiguration}
          formsConfiguration={formsConfiguration}
          singleLineData={singleLineData}
          secondSingleLineData={secondSingleLineData}
        />
      )
    } else if (showLoader) {
      return (
        <div className={styles.loaderContainer}>
          <img className={styles.loaderImage} src={config.loader.url} alt={config.loader.alt}></img>
        </div>
      )
    } else {
      return <p>{moduleConfiguration.loadingErrorMessage}</p>
    }
  }

  return <div>{componentToDisplay()}</div>
}

export const fragments = graphql`
  fragment SmsAlertInfoTrafficFormConfigurationFields on DatoCmsSmsAlertInfoTrafficFormConfiguration {
    id
    nameFieldPlaceholder
    nameFieldLabel
    firstnameFieldPlaceholder
    firstnameFieldLabel
    birthdateFieldLabel
    birthdateFieldPlaceholder
    addressFieldLabel
    addressFieldPlaceholder
    cityFieldLabel
    cityFieldPlaceholder
    errorMessage
    emailFieldPlaceholder
    emailFieldLabel
    zipCodeFieldPlaceholder
    zipCodeFieldLabel
    successMessage
    submitButtonLabel
    phoneFieldPlaceholder
    phoneFieldLabel
    invalidPhoneErrorMessage
    lineFieldLabel
    allNetworkCheckboxFieldLabel
    otherOffersCheckboxFieldLabel
    userAgreementCheckboxFieldLabel
    userAgreementLink {
      label
      page {
        slug
      }
      document {
        url
      }
    }
    loadingErrorMessage
    calendarPreviousMonthButtonAlt
    calendarNextMonthButtonAlt
  }

  fragment SmsAlertFormFields on DatoCmsSmsAlertForm {
    __typename
    id
    title
  }

  fragment CustomFormsConfigurationFields on DatoCmsFormsConfiguration {
    requiredFieldError
    phoneNumberFieldError
    emailFieldError
    logoSubsidiary {
      alt
      url
    }
  }
`

export default SmsAlertInfoTrafficForms
