import { useContext } from "react"
import { LinesSubscriptionContext } from "../context/lines-subscription-context"

export const useLinesSubscription = () => {
  const context = useContext(LinesSubscriptionContext)
  if (!context) {
    throw new Error("useLinesSubscription must be used within a LinesSubscriptionProvider")
  }
  return context
}
