// extracted by mini-css-extract-plugin
export var block = "custom-block-module--block--f23c6";
export var customBlock = "custom-block-module--customBlock--af082";
export var image = "custom-block-module--image--2fbb7";
export var link = "custom-block-module--link--a83d6";
export var linkTraffic = "custom-block-module--linkTraffic--908aa";
export var selectedButton = "custom-block-module--selectedButton--90751";
export var svgImage = "custom-block-module--svgImage--5c6ba";
export var tabButtonsContainer = "custom-block-module--tabButtonsContainer--82d2f";
export var title = "custom-block-module--title--ef04f";
export var unselectedButton = "custom-block-module--unselectedButton--9ec71";