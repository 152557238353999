import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import {
  TicketOfficeSearchFieldsFragment,
  TicketOfficeSearchModuleConfigurationFieldsFragment,
} from "../../../../graphql-types"
import * as styles from "./ticket-office-search-map.module.scss"
import config from "../../../../config"

import * as mapboxgl from "mapbox-gl"
// fix issue with loading mapboxgl on production, see https://github.com/mapbox/mapbox-gl-js/issues/10173
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

type TicketOffice = TicketOfficeSearchFieldsFragment
type Municipality = TicketOffice["municipality"]

type RenderProps = {
  ticketOffices: TicketOffice[]
  mapboxToken: string
  moduleConfiguration: TicketOfficeSearchModuleConfigurationFieldsFragment
  selectedMunicipality: Municipality | null
}

const TicketOfficeSearchMap: React.FC<RenderProps> = ({
  ticketOffices,
  mapboxToken,
  moduleConfiguration,
  selectedMunicipality,
}) => {
  const [map, setMap] = useState(null)
  const [previousMunicipality, setPreviousMunicipality] = useState(null)
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>([])

  const mapRef = useRef()

  useLayoutEffect(() => {
    mapboxgl.accessToken = mapboxToken
    const mapInit = new mapboxgl.Map({
      container: mapRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [moduleConfiguration.mapCenter.longitude, moduleConfiguration.mapCenter.latitude],
      zoom: 8,
    })
    setMap(mapInit)
  }, [])

  useEffect(() => {
    if (!map) {
      return
    }
    const newMarkers = updateTicketOfficeMarkers(map, markers, ticketOffices)
    setMarkers(newMarkers)
  }, [map, ticketOffices])

  useEffect(() => {
    if (map && selectedMunicipality && (!previousMunicipality || selectedMunicipality.id !== previousMunicipality.id)) {
      centerMapToMunicipality(map, selectedMunicipality)
    }
    setPreviousMunicipality(selectedMunicipality)
  }, [selectedMunicipality])

  return <div ref={mapRef} className={styles.map} aria-hidden="true" />
}

function centerMapToMunicipality(map: mapboxgl.Map, municipality: Municipality) {
  map.jumpTo({
    center: [municipality.location.longitude, municipality.location.latitude],
    zoom: 10,
  })
}

function updateTicketOfficeMarkers(
  map: mapboxgl.Map,
  previousMarkers: mapboxgl.Marker[],
  ticketOffices: TicketOffice[]
): mapboxgl.Marker[] {
  const primaryColor = `rgb(${config.primary_color.red}, ${config.primary_color.green}, ${config.primary_color.blue})`
  previousMarkers.forEach(marker => marker.remove())
  return ticketOffices.map(ticketOffice => {
    const markerElement = document.createElement("img")
    markerElement.src = ticketOffice.category.pictogram.url
    markerElement.alt = ticketOffice.name
    markerElement.style.width = "30px"
    markerElement.style.height = "30px"
    const popupContent = `<aside class="${styles.ticketOfficePopup}">
  <h5 class="${styles.title}">${ticketOffice.name}</h5>
  <p class="${styles.content}">
    ${ticketOffice.address}
    <br>
    ${ticketOffice.municipality.name}
    ${ticketOffice.email ? "<br>" + ticketOffice.email : ""}
    ${ticketOffice.phoneNumber ? "<br>" + ticketOffice.phoneNumber : ""}
  </p>
</aside>
`
    const popup = new mapboxgl.Popup({ maxWidth: "none" }).setHTML(popupContent)
    return new mapboxgl.Marker({ color: primaryColor, element: markerElement })
      .setLngLat([ticketOffice.location.longitude, ticketOffice.location.latitude])
      .setPopup(popup)
      .addTo(map)
  })
}

export default TicketOfficeSearchMap
