exports.components = {
  "component---src-templates-404-404-tsx": () => import("./../../../src/templates/404/404.tsx" /* webpackChunkName: "component---src-templates-404-404-tsx" */),
  "component---src-templates-index-index-tsx": () => import("./../../../src/templates/index/index.tsx" /* webpackChunkName: "component---src-templates-index-index-tsx" */),
  "component---src-templates-info-traffic-detail-info-traffic-detail-tsx": () => import("./../../../src/templates/info-traffic-detail/info-traffic-detail.tsx" /* webpackChunkName: "component---src-templates-info-traffic-detail-info-traffic-detail-tsx" */),
  "component---src-templates-my-alert-my-alert-tsx": () => import("./../../../src/templates/my-alert/my-alert.tsx" /* webpackChunkName: "component---src-templates-my-alert-my-alert-tsx" */),
  "component---src-templates-news-news-tsx": () => import("./../../../src/templates/news/news.tsx" /* webpackChunkName: "component---src-templates-news-news-tsx" */),
  "component---src-templates-search-results-search-results-tsx": () => import("./../../../src/templates/search-results/search-results.tsx" /* webpackChunkName: "component---src-templates-search-results-search-results-tsx" */),
  "component---src-templates-type-page-type-page-tsx": () => import("./../../../src/templates/type-page/type-page.tsx" /* webpackChunkName: "component---src-templates-type-page-type-page-tsx" */)
}

