import React from "react"
import { ReactSVG } from "react-svg"
import {
  TicketOfficeFormIconFragment,
  TicketOfficeSearchFieldsFragment,
  TicketOfficeSearchModuleConfigurationFieldsFragment,
} from "../../../../graphql-types"
import * as styles from "./ticket-office-search-result.module.scss"
import { ticket } from "../../../../static/images"

type RenderProps = {
  ticketOffice: TicketOfficeSearchFieldsFragment
  moduleConfiguration: TicketOfficeSearchModuleConfigurationFieldsFragment
}

const TicketOfficeSearchResult: React.FC<RenderProps> = ({ ticketOffice, moduleConfiguration }) => {
  const displayImage = (image: TicketOfficeFormIconFragment) => {
    if (image.format === "svg") {
      return (
        <ReactSVG
          src={image.url}
          className={styles.image}
          beforeInjection={svg => svg.setAttribute("aria-label", image.alt || "")}
        />
      )
    }

    return <img src={image.url} className={styles.image} alt={image.alt || ""} aria-hidden="true" />
  }

  return (
    <section>
      <h4 className={styles.name}>
        {ticketOffice.picture && displayImage(ticketOffice.picture)}
        <span>{ticketOffice.name}</span>
      </h4>
      <div className={styles.infos}>
        <div className={styles.wrapper}>
          <h5 className={styles.label}>
            {displayImage(moduleConfiguration.addressIcon)}
            <span>{moduleConfiguration.addressLabel}</span>
          </h5>
          <p>
            {ticketOffice.address}
            <br />
            {ticketOffice.zipcode} {ticketOffice.municipality.name}
          </p>
        </div>
        <div className={styles.wrapper}>
          <h5 className={styles.label}>
            {displayImage(moduleConfiguration.contactIcon)}
            <span>{moduleConfiguration.contactLabel}</span>
          </h5>
          <p>
            {ticketOffice.email}
            {ticketOffice.email && ticketOffice.phoneNumber && <br />}
            {ticketOffice.phoneNumber}
          </p>
        </div>
        {ticketOffice.extraFields.length > 0 && (
          <div className={styles.wrapper}>
            <h5 className={styles.label}>
              <ReactSVG
                src={ticket}
                className={styles.image}
                beforeInjection={svg => svg.setAttribute("aria-label", "ticket")}
              />
              <span>{ticketOffice.extraFields[0].title}</span>
            </h5>
            <p>{ticketOffice.extraFields[0].other}</p>
          </div>
        )}
      </div>
    </section>
  )
}

export default TicketOfficeSearchResult
