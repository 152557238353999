import React from "react"
import * as styles from "./title-page.module.scss"
import { TitleBlockFieldsFragment } from "../../../graphql-types"
import { ReactSVG } from "react-svg"

type RenderProps = {
  titleData: TitleBlockFieldsFragment
}

const TitlePage: React.FC<RenderProps> = ({ titleData }) => {
  function getTextOrImageClass(titleBlock) {
    if (!titleBlock.textOrImage) {
      if (titleBlock.linkText) {
        return styles.titleBlockItem + " " + styles.titleText
      }
    } else if (titleBlock.textOrImage && titleBlock.linkImage) {
      return styles.titleBlockItem + " " + styles.titleImage
    }
    return styles.titleBlockItem + " " + styles.titleText
  }

  function getTextOrImageLayout(titleBlock) {
    if ((!titleBlock.linkText && !titleBlock.linkImage) || (!titleBlock.textOrImage && !titleBlock.linkText)) {
      return <div className={styles.ticketBlockText}>{titleBlock.title}</div>
    }
    if (!titleBlock.textOrImage && titleBlock.linkText) {
      return <div className={styles.ticketBlockText}>{titleBlock.linkText}</div>
    }
    if (titleBlock.textOrImage && titleBlock.linkImage) {
      return (
        <div>
          {titleBlock.linkImage.format === "svg" ? (
            <ReactSVG
              src={titleBlock.linkImage.url}
              className={styles.svgImage}
              beforeInjection={svg => svg.setAttribute("aria-label", titleBlock.linkImage.alt || "")}
            />
          ) : (
            <img src={titleBlock.linkImage.url} alt={titleBlock.linkImage.alt || ""} className={styles.image} />
          )}
        </div>
      )
    }
  }

  return (
    <div className={styles.titleRow}>
      {titleData.titleBlockLink.map(titleBlock => {
        return (
          <a
            href={
              !titleBlock.externalLink && !titleBlock.internalLink
                ? "#"
                : titleBlock.externalLink
                  ? titleBlock.externalLink
                  : titleBlock.internalLink && titleBlock.internalLink.__typename == "DatoCmsPage"
                    ? "/" + titleBlock.internalLink.slug
                    : "/"
            }
            className={getTextOrImageClass(titleBlock)}
            key={titleBlock.id}
          >
            {getTextOrImageLayout(titleBlock)}
          </a>
        )
      })}
    </div>
  )
}

export default TitlePage
