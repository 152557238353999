import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { ReactSVG } from "react-svg"
import {
  LePiloteInfoTrafficLinesIconsFieldsFragment,
  LePiloteItineraryModuleFieldsFragment,
} from "../../../graphql-types"
import * as config from "../../../config"
import * as styles from "./le-pilote-itinerary.module.scss"
import * as mapboxgl from "mapbox-gl"
import { useIsMobile } from "../../hooks"
import { updateMapItinerary, updateMapItineraryWalk } from "./draw-itinerary"
import LePiloteItineraryForm from "./le-pilote-itinerary-form"
// fix issue with loading mapboxgl on production, see https://github.com/mapbox/mapbox-gl-js/issues/10173
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

type RenderProps = {
  mapboxToken: string
  data: LePiloteItineraryModuleFieldsFragment
  linesIconsData: LePiloteInfoTrafficLinesIconsFieldsFragment[]
}

const LePiloteItinerary: React.FC<RenderProps> = ({ mapboxToken, data, linesIconsData }) => {
  const [map, setMap] = useState(null)

  const [departureMarker, setDepartureMarker] = useState<mapboxgl.Marker>()
  const [arrivalMarker, setArrivalMarker] = useState<mapboxgl.Marker>()

  const [selectedTripType, setSelectedTripType] = useState("Faster")
  const [tripsList, setTripsList] = useState(null)

  const [hideTrip, setHideTrip] = useState("")
  const mapRef = useRef()
  const isMobile = useIsMobile()

  useLayoutEffect(() => {
    mapboxgl.accessToken = mapboxToken
    const mapInit = new mapboxgl.Map({
      container: mapRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [5.447427, 43.529742],
      zoom: 10,
    })
    setMap(mapInit)
  }, [])

  useEffect(() => {
    if (!map) {
      return
    }
  }, [map])

  function getDataFromForm(trips) {
    setTripsList(trips)
    drawSelectedTrip(map, selectedTripType, trips)
    if (!isMobile) {
      map.flyTo({
        center: [5.447427 - 0.1, 43.529742],
      })
    } else {
      map.flyTo({
        center: [5.447427, 43.529742 - 0.08],
      })
    }
  }

  function drawSelectedTrip(mapToUpdate: mapboxgl.Map, selectedTripTypeValue: string, tripsListData: any[]) {
    let selectedTrip = tripsListData.find(
      element =>
        element.tripType == selectedTripTypeValue || (element.TripTitle && element.TripTitle == selectedTripTypeValue)
    )
    let walkIndex = 0
    let rideIndex = 0
    selectedTrip.sections.map((segment, index) => {
      if ("walkSegmentCoordinates" in segment[0]) {
        updateMapItineraryWalk(mapToUpdate, segment[0].walkSegmentCoordinates, "walk-route" + walkIndex)
        walkIndex++
      } else if ("rideSegmentCoordinates" in segment[0]) {
        updateMapItinerary(mapToUpdate, segment[0].rideSegmentCoordinates, "route" + rideIndex)
        rideIndex++
      }
    })
    setMap(mapToUpdate)
    const newDepartureMarker = updateMarkers(
      mapToUpdate,
      departureMarker,
      "departure",
      data,
      selectedTrip,
      selectedTripTypeValue
    )
    setDepartureMarker(newDepartureMarker)
    const newArrivalMarker = updateMarkers(
      mapToUpdate,
      arrivalMarker,
      "arrival",
      data,
      selectedTrip,
      selectedTripTypeValue
    )
    setArrivalMarker(newArrivalMarker)
    setMap(mapToUpdate)
  }

  function showItineraryDetails(e, tripType) {
    if (tripType != selectedTripType) {
      setSelectedTripType(tripType)
      drawSelectedTrip(map, tripType, tripsList)
    } else {
      if (hideTrip == selectedTripType) {
        setHideTrip("")
      } else {
        setHideTrip(selectedTripType)
      }
    }
  }

  function getTripSubtitle(departureLabel, departureTime, arrivalLabel, arrivalTime) {
    return (
      departureLabel +
      ": " +
      departureTime.split(" ")[1].split(":", 2).slice(0, 2).join(":") +
      " " +
      "> " +
      arrivalLabel +
      ": " +
      arrivalTime.split(" ")[1].split(":", 2).slice(0, 2).join(":")
    )
  }

  function getFormatedDuration(duration) {
    return duration.replace("PT", "").replace("H", ":").replace("M", ":").replace("S", "").split(":")[0]
  }

  function getTripRideSectionDetails(lineIndicationLabel, lineNumber, durationIndicationLabel, duration, arrival) {
    return (
      lineIndicationLabel +
      " " +
      lineNumber +
      " " +
      durationIndicationLabel +
      " " +
      getFormatedDuration(duration) +
      data.minuteLabel +
      " " +
      data.destinationIndicationLabel +
      " " +
      arrival
    )
  }

  return (
    <div className={styles.itineraryContainer} id="itineraryContainer">
      {map ? (
        <LePiloteItineraryForm
          data={data}
          sendDataToParent={getDataFromForm}
          linesIconsData={linesIconsData}
        ></LePiloteItineraryForm>
      ) : null}
      <div ref={mapRef} className={styles.map} id={"itineraryMap"}>
        <div className={tripsList ? styles.itineraryCard + " " + styles.itineraryCardDisplay : styles.itineraryCard}>
          {tripsList &&
            tripsList.map((x, index) => {
              return (
                <div className={styles.itineraryCardWrapper} id={"itineraryCard-" + index}>
                  <div className={styles.itineraryCardHeader}>
                    <h3>
                      {x.tripType == "Faster"
                        ? data.fastestItinerary
                        : x.tripType == "LessChange"
                          ? data.shortestItinerary
                          : data.mostDirectItinerary}
                    </h3>
                    <div className={styles.subtitle}>
                      {getTripSubtitle(
                        data.departureLabel,
                        x.fullTripData.DepartureTime,
                        data.arrivalLabel,
                        x.fullTripData.ArrivalTime
                      )}
                    </div>
                  </div>
                  <div className={styles.itineraryCardDetailsClosed}>
                    {x.sections.map(section => {
                      return section[0].rideSegmentDetails ? (
                        <div className={styles.lineIconWrapper + " " + styles.lineIconWrapperDetailsClosed}>
                          {section[0].lineIcon[0] ? (
                            <img className={styles.lineIcon} src={section[0].lineIcon[0].url}></img>
                          ) : (
                            <div className={styles.lineText}>{section[0].rideSegmentDetails.Line.Number} </div>
                          )}
                        </div>
                      ) : null
                    })}
                    <div className={styles.duration}>
                      {getFormatedDuration(x.fullTripData.Duration) + data.minuteLabel}
                    </div>
                  </div>
                  <div
                    className={
                      selectedTripType != x.tripType || (selectedTripType == x.tripType && hideTrip == selectedTripType)
                        ? styles.panel
                        : styles.visiblePanel
                    }
                  >
                    {x.sections.map(section => {
                      return (
                        <div className={styles.tripSegmentDetails}>
                          <div className={styles.tripSegmentDetailsLeft}>
                            <div>
                              {section[0].rideSegmentDetails
                                ? section[0].rideSegmentDetails.Departure.Time.split(" ")[1]
                                    .split(":", 2)
                                    .slice(0, 2)
                                    .join(":")
                                : section[0].walkSegmentDetails.Departure.Time.split(" ")[1]
                                    .split(":", 2)
                                    .slice(0, 2)
                                    .join(":")}
                            </div>
                            <div className={styles.lineIconWrapper}>
                              {section[0].rideSegmentDetails && section[0].lineIcon[0] ? (
                                <img className={styles.lineIcon} src={section[0].lineIcon[0].url}></img>
                              ) : section[0].walkSegmentDetails ? (
                                <img
                                  src={data.walkSegmentIcon.url}
                                  alt={data.walkSegmentIcon.alt}
                                  className={styles.lineIcon + " " + styles.walkIcone}
                                ></img>
                              ) : (
                                <div className={styles.lineText}>{section[0].rideSegmentDetails.Line.Number} </div>
                              )}
                            </div>
                          </div>
                          <div className={styles.separator}></div>
                          <div className={""}>
                            <div className={styles.sectionName}>
                              {section[0].rideSegmentDetails
                                ? section[0].rideSegmentDetails.Departure?.StopPlace?.Name ||
                                  section[0].rideSegmentDetails.Line.Name
                                : section[0].walkSegmentDetails.Departure.Site.Name}
                            </div>
                            <div className={styles.sectionDirection}>
                              {section[0].rideSegmentDetails
                                ? data.directionLabel + " " + section[0].rideSegmentDetails.Direction.Name
                                : data.directionLabel + " " + section[0].walkSegmentDetails.Arrival.Site.Name}
                            </div>

                            <div className={styles.sectionDuration}>
                              {section[0].rideSegmentDetails
                                ? getTripRideSectionDetails(
                                    data.lineIndicationLabel,
                                    section[0].rideSegmentDetails.Line.Number,
                                    data.durationIndicationLabel,
                                    section[0].rideSegmentDetails.Duration,
                                    section[0].rideSegmentDetails.Arrival.Site
                                      ? section[0].rideSegmentDetails.Arrival.Site.Name
                                      : section[0].rideSegmentDetails.Arrival.StopPlace.Name
                                  )
                                : data.walkIndicationLabel +
                                  " " +
                                  getFormatedDuration(section[0].walkSegmentDetails.Duration) +
                                  data.minuteLabel +
                                  " " +
                                  data.destinationIndicationLabel +
                                  " " +
                                  section[0].walkSegmentDetails?.Arrival?.Site?.Name}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div>
                    <button className={styles.accordion} onClick={e => showItineraryDetails(e, x.tripType)}>
                      <ReactSVG
                        src={data.itineraryDetailsButtonIcon.url}
                        alt={data.itineraryDetailsButtonIcon.alt}
                        className={
                          selectedTripType == x.tripType && hideTrip != selectedTripType
                            ? styles.expandArrow + " " + styles.rotatedImage
                            : styles.expandArrow
                        }
                        beforeInjection={svg => svg.setAttribute("aria-hidden", "true")}
                      />
                    </button>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

function updateMarkers(
  map: mapboxgl.Map,
  previousMarker: mapboxgl.Marker,
  markerType: string,
  data: any,
  selectedTrip?: any,
  selectedTripType?: string
): mapboxgl.Marker {
  const primaryColor = `rgb(${config.primary_color.red}, ${config.primary_color.green}, ${config.primary_color.blue})`
  if (previousMarker) {
    previousMarker.remove()
  }
  let departureMarker: any
  let arrivalMarker: any
  if (selectedTrip) {
    selectedTrip.sections.map(x => {
      if (x[0].rideSegmentCoordinates) {
        let ln = x[0].rideSegmentCoordinates.length
        if (!departureMarker) {
          departureMarker = x[0].rideSegmentCoordinates[0]
        }
        arrivalMarker = x[0].rideSegmentCoordinates[ln - 1]
      }
    })
    if (markerType == "departure") {
      const markerElement = document.createElement("img")
      markerElement.src = data.mapDepartureMarker.url
      markerElement.alt = data.mapDepartureMarker.alt
      markerElement.style.width = "28px"
      markerElement.style.height = "28px"
      return new mapboxgl.Marker({
        color: primaryColor,
        element: markerElement,
      })
        .setLngLat([departureMarker[0], departureMarker[1]])
        .addTo(map)
    } else {
      const markerElement = document.createElement("img")
      markerElement.src = data.mapArrivalMarker.url
      markerElement.alt = data.mapArrivalMarker.alt
      markerElement.style.width = "28px"
      markerElement.style.height = "28px"
      return new mapboxgl.Marker({
        color: primaryColor,
        element: markerElement,
      })
        .setLngLat([arrivalMarker[0], arrivalMarker[1]])
        .addTo(map)
    }
  }
}

export default LePiloteItinerary
