import React, { useState } from "react"
import { ReactSVG } from "react-svg"
import { MainBlocksFieldsFragment } from "../../../../graphql-types"
import { infoTrafficBlockPicto, timesheet, triangle } from "../../../../static/images"
import * as styles from "./main-blocks-mobile-tabs.module.scss"

type RenderProps = {
  mainBlocks: MainBlocksFieldsFragment[]
  onActiveTabChange: (activeBlockId: string) => void
}
const MainBlocksMobileTabs: React.FC<RenderProps> = ({ mainBlocks, onActiveTabChange }) => {
  const blockTypeImages = {
    DatoCmsSchedulesSearchBlock: timesheet,
    DatoCmsTrafficInfoBlock: infoTrafficBlockPicto,
    DatoCmsInstantSystemWidgetBlock: infoTrafficBlockPicto,
  }
  const [activeTab, setActiveTab] = useState(mainBlocks[1]?.id)
  const handleTabClick = (blockId: string) => {
    setActiveTab(blockId)
    onActiveTabChange(blockId)
  }
  return (
    <div className={styles.mainBlocksTabs}>
      {mainBlocks.map((block, index) => (
        <div
          key={block.id}
          className={`${styles.mainBlockHeader} ${block.id === activeTab && styles.active}`}
          onClick={() => {
            handleTabClick(block.id)
          }}
        >
          {(blockTypeImages[block.__typename] || block.__typename === "DatoCmsCustomBlockExternalPage") && (
            <ReactSVG
              src={blockTypeImages[block.__typename] ? blockTypeImages[block.__typename] : block.image?.url}
              className={styles.blockHeaderPicto}
              beforeInjection={svg => svg.setAttribute("aria-hidden", "true")}
            />
          )}
          <h3 className={styles.tabTitle}>{block.title || block.widgetType}</h3>

          <ReactSVG
            src={triangle}
            className={styles.triangle}
            beforeInjection={svg => {
              svg.setAttribute("aria-hidden", "true")
            }}
          />
        </div>
      ))}
    </div>
  )
}
export default MainBlocksMobileTabs
