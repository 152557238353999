import React, { useEffect, useState } from "react"
import * as styles from "./share-buttons.module.scss"
import { facebookLogo, twitterLogo } from "../../../../static/images"

type RenderProps = {
  facebookDisplay: boolean
  twitterDisplay: boolean
}

const ShareButtons: React.FC<RenderProps> = ({ facebookDisplay, twitterDisplay }) => {
  const [facebookUrl, setFacebookUrl] = useState("")
  const [twitterUrl, setTwitterUrl] = useState("")

  useEffect(() => {
    setFacebookUrl(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`)
    setTwitterUrl(`https://twitter.com/intent/tweet?url=${window.location.href}`)
  }, [])

  return facebookDisplay || twitterDisplay ? (
    <div className={styles.shareButtons}>
      {facebookDisplay && (
        <a href={facebookUrl} target="_blank" className={styles.facebook}>
          <img src={facebookLogo} alt="Facebook logo" />
          Partager
        </a>
      )}
      {twitterDisplay && (
        <a href={twitterUrl} target="_blank" className={styles.twitter}>
          <img src={twitterLogo} alt="Twitter logo" />
          Tweet
        </a>
      )}
    </div>
  ) : null
}

export default ShareButtons
