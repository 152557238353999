import React, { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"
import { graphql, useStaticQuery } from "gatsby"
import { AlertBannerFieldsFragment, DatoCmsInfoTraffic, HomeQuery } from "../../../../graphql-types"
import * as config from "../../../../config"
import * as styles from "./alert-banner.module.scss"
import { format } from "date-fns"
import sanitizeHtml from "sanitize-html"
import { pushGtmEvent } from "../../../gtm/interaction-event"
import { alertImage, cross } from "../../../../static/images"
import { useInternalExternalLink } from "../../../hooks"

type RenderProps = {
  infoTrafficData: DatoCmsInfoTraffic
  buttonText: HomeQuery["datoCmsGlobalConfiguration"]["redirectionButtonText"]
  alertBannerConfig: AlertBannerFieldsFragment
  defaultLocale: string
}

const AlertBanner: React.FC<RenderProps> = ({
  infoTrafficData,
  buttonText,
  alertBannerConfig: {
    alertBannerIsActive,
    alertBannerTitle,
    alertBannerDate,
    alertBannerInternalExternalLink,
    alertBannerShortDescription,
    alertBannerMoreInfoButtonLabel,
    alertBannerClosingButtonAlt,
  },
  defaultLocale,
}) => {
  const globalConfig = useStaticQuery(graphql`
    query infoTrafficDetailPageQuery {
      allDatoCmsPage(filter: { isInfoTrafficDetailPage: { eq: true } }) {
        nodes {
          id
          title
          slug
          isInfoTrafficDetailPage
        }
      }
    }
  `)

  const [isBannerOpened, setBannerState] = useState(false)

  const closeBanner = () => {
    pushGtmEvent("eventga", "Homepage", "Info Banner", alertBannerClosingButtonAlt)
    setBannerState(false)
    setTimeout(function () {
      if (typeof window !== "undefined") {
        sessionStorage.setItem("id", alertBannerIsActive ? "generalBanner" : infoTrafficData.id)
      }
    }, 1000)
  }

  let infoTrafficId: string
  let generalBanner: string

  useEffect(() => {
    if (typeof window !== "undefined") {
      infoTrafficId = sessionStorage.getItem("id")
      generalBanner = sessionStorage.getItem("id")
    }
    if (
      (!infoTrafficId || infoTrafficId == "undefined" || infoTrafficId == "" || infoTrafficId == undefined) &&
      (!generalBanner || generalBanner != "generalBanner")
    ) {
      setBannerState(true)
    }
  }, [])

  // Error displayed by date-format => RangeError: Use `yyyy` instead of `YYYY` (in `dd/MM/YYYY`) for formatting years to the input
  const dateFormat =
    config.date_format[config.locale] === "dd/MM/YYYY" ? "dd/MM/yyyy" : config.date_format[config.locale]

  const link = alertBannerInternalExternalLink[0]
    ? useInternalExternalLink(alertBannerInternalExternalLink[0], config.locale, defaultLocale)
    : null

  return alertBannerIsActive && !generalBanner ? (
    <div className={`${styles.alertBanner} ${!isBannerOpened && styles.alertBannerClosed}`}>
      <div className={styles.alertBannerContentWrapper}>
        <ReactSVG
          src={alertImage}
          className={styles.images}
          beforeInjection={svg => svg.setAttribute("aria-hidden", "true")}
        />
        <div className={styles.alertBannerBody}>
          <div className={styles.infoTrafficDate}>{format(new Date(alertBannerDate), dateFormat)}</div>
          <h3 className={styles.title}>{alertBannerTitle}</h3>
          <div
            className={styles.shortDescription}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(alertBannerShortDescription, {
                allowedTags: ["p"],
              }),
            }}
          />
        </div>
        {link && (
          <div className={styles.alertBannerButton}>
            <a
              href={link}
              className={styles.alertBannerLink}
              target={alertBannerInternalExternalLink[0]?.externalLink ? "_blank" : "_self"}
              onClick={() =>
                pushGtmEvent("eventga", "Homepage", "Info Banner", link ? alertBannerMoreInfoButtonLabel : buttonText)
              }
            >
              {alertBannerMoreInfoButtonLabel || buttonText}
            </a>
          </div>
        )}
        <div className={styles.closeButtonWrapper}>
          <button className={styles.closeButton} onClick={closeBanner}>
            <img src={cross} alt={alertBannerClosingButtonAlt} />
          </button>
        </div>
      </div>
    </div>
  ) : (
    infoTrafficId !== infoTrafficData?.id && (
      <div className={isBannerOpened ? styles.alertBanner : `${styles.alertBanner} ${styles.alertBannerClosed}`}>
        <div className={styles.alertBannerContentWrapper}>
          <ReactSVG
            src={alertImage}
            className={styles.images}
            beforeInjection={svg => svg.setAttribute("aria-hidden", "true")}
          />
          <div className={styles.alertBannerBody}>
            <div className={styles.infoTrafficDate}>{format(new Date(infoTrafficData.start), dateFormat)}</div>
            <h3 className={styles.title}>{infoTrafficData.title}</h3>
            <div
              className={styles.shortDescription}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(infoTrafficData.shortDescription, {
                  allowedTags: ["p"],
                }),
              }}
            />
          </div>
          <div className={styles.alertBannerButton}>
            <a
              href={
                !globalConfig?.allDatoCmsPage?.nodes[0]?.slug
                  ? `/traffic-info/${infoTrafficData.slug}`
                  : `/${
                      globalConfig?.allDatoCmsPage?.nodes[0]?.slug ? globalConfig.allDatoCmsPage.nodes[0].slug : ""
                    }` +
                    "?" +
                    infoTrafficData.title.replace(new RegExp(" ", "g"), "-") +
                    "#" +
                    infoTrafficData.id
              }
              className={styles.alertBannerLink}
              onClick={() => pushGtmEvent("eventga", "Homepage", "Info Banner", buttonText)}
            >
              {buttonText}
            </a>
          </div>
          <div className={styles.closeButtonWrapper}>
            <button className={styles.closeButton} onClick={closeBanner}>
              <img alt={alertBannerClosingButtonAlt} src={cross} />
            </button>
          </div>
        </div>
      </div>
    )
  )
}

export const fragment = graphql`
  fragment AlertBannerFields on DatoCmsHome {
    alertBannerIsActive
    alertBannerTitle
    alertBannerDate
    alertBannerInternalExternalLink {
      ...InternalExternalLinkFields
    }
    alertBannerShortDescription
    alertBannerMoreInfoButtonLabel
    alertBannerClosingButtonAlt
  }
`

export default AlertBanner
