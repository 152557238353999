/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as config from "../../config"
import { enableGoogleAnalytics, enableGoogleTagManagerHead } from "./layout/header/header"

const SEO = ({ title, description, locale, meta }) => {
  const { site, datoCmsSite } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          social {
            twitter
          }
        }
      }
      datoCmsSite {
        id
        faviconMetaTags {
          tags
        }
        globalSeo {
          fallbackSeo {
            description
            title
            image {
              url
            }
          }
          siteName
          facebookPageUrl
          siteName
          titleSuffix
          twitterAccount
        }
      }
    }
  `)
  const metaDescription = description || datoCmsSite.globalSeo?.fallbackSeo?.description
  const defaultTitle = config.website_name
  const formattedTitle = title || datoCmsSite.globalSeo?.siteName || datoCmsSite.globalSeo?.fallbackSeo?.title
  const isTitleSuffix = datoCmsSite?.globalSeo?.titleSuffix ? title + datoCmsSite?.globalSeo?.titleSuffix : title
  const isTwitterCard = datoCmsSite?.globalSeo?.twitterCard ? datoCmsSite?.globalSeo?.twitterCard : `summary`

  return (
    <>
      <html lang={locale || config.locale} />
      <title>{defaultTitle ? `${formattedTitle} | ${defaultTitle}` : formattedTitle}</title>

      {/* META TAGS */}
      {!meta || meta.length === 0 ? (
        <>
          <meta name="description" content={metaDescription} />
          <meta property="og:title" content={isTitleSuffix} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={datoCmsSite?.globalSeo?.fallbackSeo?.image?.url || ""} />
          <meta property="twitter:card" content={isTwitterCard} />
          <meta
            property="twitter:title"
            content={site.siteMetadata?.social?.twitter || datoCmsSite?.globalSeo?.twitterAccount || ""}
          />
          <meta property="twitter:site" content={title || datoCmsSite?.globalSeo?.siteName || ""} />
          <meta property="twitter:description" content={metaDescription} />
        </>
      ) : (
        meta?.length > 0 &&
        meta?.map((seoMeta, index) => {
          return !seoMeta.tagName || seoMeta?.tagName === "meta" ? (
            <meta
              key={index}
              name={seoMeta.attributes?.name || seoMeta.attributes?.property}
              content={seoMeta.attributes?.content || seoMeta?.content}
            />
          ) : (
            <link key={index} content={seoMeta?.content} />
          )
        })
      )}

      {/* FAVICON META TAGS */}
      {datoCmsSite?.faviconMetaTags?.tags?.map((tags, index) => {
        return tags.tagName === "link" ? (
          <link
            key={index}
            rel={tags.attributes.rel || ""}
            sizes={tags.attributes.sizes || ""}
            type={tags.attributes.type || ""}
            href={tags.attributes.href || ""}
          />
        ) : (
          <meta key={index} name={tags.attributes.name || ""} content={tags.attributes.content || ""} />
        )
      })}

      {/* GTM & GA INTEGRATION */}
      {config.google_analytics_key && enableGoogleAnalytics()}
      {enableGoogleTagManagerHead()}

      {/* ACTISTAT INTEGRATION */}
      {config.actistat_key && (
        <script async defer data-website-id={config.actistat_key} src="https://actistat.fr/umami.js"></script>
      )}
    </>
  )
}

export default SEO
