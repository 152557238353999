import React, { useEffect, useRef, useState } from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { LePiloteItineraryBlockFieldsFragment } from "../../../../graphql-types"
import * as styles from "./le-pilote-itinerary-block.module.scss"
import * as globalBlockStyles from "../main-blocks/main-blocks.module.scss"
import * as blockStyles from "../custom-block/custom-block.module.scss"
import * as inputsStyles from "../../layout/inputs.module.scss"
import Autocomplete from "../../autocomplete/autocomplete"
import { getTripPointsFromApi } from "../../../api/le-pilote-api"
import { pushGtmEvent } from "../../../gtm/interaction-event"
import { useSpeechToText } from "../../../hooks"

type RenderProps = {
  block: LePiloteItineraryBlockFieldsFragment
  intersectionRatioCallback: (ratio: number) => void
}

const LePiloteItineraryBlock: React.FC<RenderProps> = ({ block, intersectionRatioCallback }) => {
  const component = useRef<HTMLFormElement>()
  const observer = useRef<IntersectionObserver>()
  const [listStartStops, setListStartStops] = useState(null)
  const [switchedStart, setSwitchedStart] = useState(null)
  const [switchedEnd, setSwitchedEnd] = useState(null)
  const [listEndStops, setListEndStops] = useState(null)
  const [departureCoord, setDepartureCoord] = useState(null)
  const [arrivalCoord, setArrivalCoord] = useState(null)
  const [invalidDeparture, setInvalidDeparture] = useState("")
  const [invalidArrival, setInvalidArrival] = useState("")

  useEffect(() => {
    if (!("IntersectionObserver" in window)) {
      return
    }
    const options = { threshold: [0.2, 0.4, 0.6, 0.8, 1.0] }
    observer.current = new IntersectionObserver(entries => {
      intersectionRatioCallback(entries[entries.length - 1].intersectionRatio)
    }, options)
    observer.current.observe(component.current)
    return () => observer.current.disconnect()
  }, [])

  const toLabel = element => (element ? element.Name : "Recherche...")

  const getStartStopList = query => {
    getTripPointsFromApi(query).then(allStops => setListStartStops(allStops || []))
  }
  const getEndStopList = query => {
    getTripPointsFromApi(query).then(allStops => setListEndStops(allStops || []))
  }

  const getStartStop = query => {
    if (departureCoord) {
      setDepartureCoord(null)
      return []
    }
    getStartStopList(query)
    return (Array.isArray(listStartStops) && listStartStops) || []
  }
  const getEndStop = query => {
    if (arrivalCoord) {
      setArrivalCoord(null)
      return []
    }
    getEndStopList(query)
    return (Array.isArray(listEndStops) && listEndStops) || []
  }

  const selectStartStop = startStop => {
    setDepartureCoord(null)
    setInvalidDeparture("")
    setListStartStops(startStop || listStartStops)
    setTimeout(() => {
      setIsListeningDeparture(false)
    }, 1500)
  }
  const selectEndStop = endStop => {
    setArrivalCoord(null)
    setInvalidArrival("")
    setListEndStops(endStop || listEndStops)
    setTimeout(() => {
      setIsListeningArrival(false)
    }, 1500)
  }

  const handleGeoLoc = (param: string) => () => {
    if ((departureCoord && param === "departure") || (arrivalCoord && param === "arrival")) {
      return
    }
    if (param === "departure") {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            let departure = document.getElementById("departureInput") as HTMLInputElement
            departure.value = ""
            let coords = position.coords
            coords["Name"] = block.geolocationLabel
            setDepartureCoord(coords)
            if (arrivalCoord) {
              setArrivalCoord(null)
              setListEndStops(null)
              setSwitchedEnd(null)
            }
            setInvalidDeparture("")
            setListStartStops(coords)
            setSwitchedStart(null)
          },
          error => {
            if (error.code == error.PERMISSION_DENIED) console.log("you denied me :-(")
          }
        )
      }
    } else if (param === "arrival") {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            let arrival = document.getElementById("arrivalInput") as HTMLInputElement
            arrival.value = ""
            let coords = position.coords
            coords["Name"] = block.geolocationLabel
            setArrivalCoord(coords)
            if (departureCoord) {
              setDepartureCoord(null)
              setListStartStops(null)
              setSwitchedStart(null)
            }
            setInvalidArrival("")
            setListEndStops(coords)
            setSwitchedEnd(null)
          },
          error => {
            if (error.code == error.PERMISSION_DENIED) console.log("you denied me :-(")
          }
        )
      }
    }
  }

  const handleSwitch = () => {
    if (listStartStops?.Name && listEndStops?.Name && !departureCoord && !arrivalCoord) {
      const tmpStart = listStartStops
      const tmpEnd = listEndStops
      setListStartStops(tmpEnd)
      setListEndStops(tmpStart)
      setSwitchedStart(tmpEnd)
      setSwitchedEnd(tmpStart)
    } else if (departureCoord) {
      let departure = departureCoord
      departureCoord.Name = block.geolocationLabel
      setArrivalCoord(departure)
      setListStartStops(listEndStops)
      setDepartureCoord(null)
      setSwitchedStart(listEndStops)
      setSwitchedEnd(departure)
      setListEndStops(departure)
    } else if (arrivalCoord) {
      let arrival = arrivalCoord
      arrival.Name = block.geolocationLabel
      setDepartureCoord(arrival)
      setListEndStops(listStartStops)
      setArrivalCoord(null)
      setSwitchedEnd(listStartStops)
      setSwitchedStart(arrival)
      setListStartStops(arrival)
    }
  }

  const [isListeningDeparture, setIsListeningDeparture] = useState(false)
  const [isListeningArrival, setIsListeningArrival] = useState(false)
  const [departureNote, setDepartureNote] = useState(null)
  const [arrivalNote, setArrivalNote] = useState(null)
  const [microphone, setMicrophone] = useState(null)

  useEffect(() => {
    let mic = useSpeechToText()
    if (mic != false) {
      setMicrophone(mic)
    }
  }, [microphone])

  useEffect(() => {
    handleListenDeparture()
  }, [isListeningDeparture])

  useEffect(() => {
    handleListenArrival()
  }, [isListeningArrival])

  const handleListenDeparture = () => {
    if (microphone) {
      if (isListeningDeparture && !isListeningArrival) {
        microphone.start()
        microphone.onend = () => {
          console.log("continue..")
          microphone.start()
        }
      } else if (!isListeningDeparture) {
        microphone.stop()
        microphone.onend = () => {
          console.log("Stopped Mic on Click")
        }
      }
      microphone.onstart = () => {
        console.log("Mics on")
      }

      microphone.onresult = event => {
        const transcript = Array.from(event.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join("")
        if ((transcript && !departureNote) || (transcript && departureNote && transcript != departureNote.Name)) {
          setDepartureNote({ Name: transcript })
          setSwitchedStart({ Name: transcript })
          document.getElementById("departureInput").focus()
          document.getElementById("departureInput").blur()
          document.getElementById("departureInput").focus()
        }
        microphone.onerror = errorEvent => {
          console.log(errorEvent.error)
        }
      }
    }
  }

  const handleListenArrival = () => {
    if (microphone) {
      if (isListeningArrival && !isListeningDeparture) {
        microphone.start()
        microphone.onend = () => {
          console.log("continue..")
          microphone.start()
        }
      } else if (!isListeningArrival) {
        microphone.stop()
        microphone.onend = () => {
          console.log("Stopped Mic on Click")
        }
      }
      microphone.onstart = () => {
        console.log("Mics on")
      }
      microphone.onresult = event => {
        const transcript = Array.from(event.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join("")
        if ((transcript && !arrivalNote) || (transcript && arrivalNote && transcript != arrivalNote.Name)) {
          setArrivalNote({ Name: transcript })
          setSwitchedEnd({ Name: transcript })
          document.getElementById("arrivalInput").focus()
          document.getElementById("arrivalInput").blur()
          document.getElementById("arrivalInput").focus()
        }
        microphone.onerror = errorEvent => {
          console.log(errorEvent.error)
        }
      }
    }
  }

  return (
    <form
      ref={component}
      className={`${blockStyles.block} ${globalBlockStyles.mainBlocksCard}`}
      method="get"
      action={`/${block.resultsPage.slug}`}
    >
      <div className={styles.blockHeader}>
        <ReactSVG
          src={block.blockPictogram.url}
          className={styles.blockHeaderPicto}
          beforeInjection={svg => svg.setAttribute("aria-label", block.blockPictogram.alt || "")}
        />
        <h3 className={blockStyles.title}>{block.title}</h3>
      </div>
      <div className={styles.form}>
        <div className={styles.formPart}>
          <div className={styles.field}>
            <label htmlFor="departureInput">{block.departureLabel}</label>
            <div className={styles.inputWithIcons}>
              <Autocomplete
                onChange={selectStartStop}
                suggestFunction={getStartStop}
                toLabel={toLabel}
                id="departureInput"
                placeholder={departureCoord ? block.geolocationLabel : block.departurePlaceholder}
                isItineraryForm={true}
                value={switchedStart ? switchedStart : departureCoord ? departureCoord : ""}
              />
              <button
                className={styles.inputIcon}
                onKeyPress={handleGeoLoc("departure")}
                onClick={handleGeoLoc("departure")}
                type="button"
              >
                <ReactSVG
                  src={block.geolocationIcon.url}
                  className={styles.inputWithIcons}
                  beforeInjection={svg => svg.setAttribute("aria-label", block.geolocationIcon.alt || "")}
                />
              </button>
              <button
                className={styles.inputIcon}
                id="activateSpeechButtonDeparture"
                onKeyPress={() => setIsListeningDeparture(prevState => !prevState)}
                onClick={() => setIsListeningDeparture(prevState => !prevState)}
                type="button"
              >
                <ReactSVG
                  src={block.microphonePictogram.url}
                  className={styles.inputWithIcons}
                  beforeInjection={svg => svg.setAttribute("aria-label", block.micArrivalAltText || "")}
                />
              </button>
            </div>
            {invalidDeparture && <div className={styles.errorMessage}>{invalidDeparture}</div>}
            <input
              type="hidden"
              name="departure"
              value={
                !departureCoord
                  ? listStartStops && listStartStops.Id
                    ? listStartStops.Id + "#" + listStartStops.Name + "#" + listStartStops.PointType
                    : listStartStops && listStartStops[0] && listStartStops[0].Id
                      ? listStartStops[0].Id + "#" + listStartStops[0].Name + "#" + listStartStops[0].PointType
                      : null
                  : `coords#${block.geolocationLabel}#${departureCoord.latitude}#${departureCoord.longitude}`
              }
            />
          </div>
        </div>
        <button className={styles.switchButton} onClick={handleSwitch}>
          <ReactSVG
            src={block.swapDepartureArrivalIcon.url}
            className={styles.inputWithIcons}
            beforeInjection={svg => svg.setAttribute("aria-label", block.swapDepartureArrivalIcon.alt || "")}
          />
        </button>
        <div className={styles.formPart}>
          <div className={styles.field}>
            <label htmlFor="arrivalInput">{block.arrivalLabel}</label>
            <div className={styles.inputWithIcons}>
              <Autocomplete
                onChange={selectEndStop}
                suggestFunction={getEndStop}
                toLabel={toLabel}
                id="arrivalInput"
                placeholder={arrivalCoord ? block.geolocationLabel : block.arrivalPlaceholder}
                title="placeholder"
                isItineraryForm={true}
                value={switchedEnd ? switchedEnd : arrivalCoord ? arrivalCoord : ""}
              />
              <button
                className={styles.inputIcon}
                onKeyPress={handleGeoLoc("arrival")}
                onClick={handleGeoLoc("arrival")}
                type="button"
              >
                <ReactSVG
                  src={block.geolocationIcon.url}
                  className={styles.inputWithIcons}
                  beforeInjection={svg => svg.setAttribute("aria-label", block.geolocationIcon.alt || "")}
                />
              </button>
              <button
                className={styles.inputIcon}
                id="activateSpeechButtonArrival"
                onKeyPress={() => setIsListeningArrival(prevState => !prevState)}
                onClick={() => setIsListeningArrival(prevState => !prevState)}
                type="button"
              >
                <ReactSVG
                  src={block.microphonePictogram.url}
                  className={styles.inputWithIcons}
                  beforeInjection={svg => svg.setAttribute("aria-label", block.micArrivalAltText || "")}
                />
              </button>
            </div>
            {invalidArrival && <div className={styles.errorMessage}>{invalidArrival}</div>}
            <input
              type="hidden"
              name="arrival"
              value={
                !arrivalCoord
                  ? listEndStops && listEndStops.Id
                    ? listEndStops.Id + "#" + listEndStops.Name + "#" + listEndStops.PointType
                    : listEndStops && listEndStops[0] && listEndStops[0].Id
                      ? listEndStops[0].Id + "#" + listEndStops[0].Name + "#" + listEndStops[0].PointType
                      : null
                  : `coords#${block.geolocationLabel}#${arrivalCoord.latitude}#${arrivalCoord.longitude}`
              }
            />
          </div>
        </div>
      </div>

      <button
        className={`${styles.searchButton} ${inputsStyles.primaryButton}`}
        type="submit"
        onClick={() =>
          pushGtmEvent(
            "eventga",
            "Homepage - Itinerary Search",
            listStartStops && listStartStops.Name ? listStartStops.Name : "",
            listEndStops && listEndStops.Name ? listEndStops.Name : ""
          )
        }
      >
        {block.searchButtonLabel}
      </button>
    </form>
  )
}

export const fragments = graphql`
  fragment LePiloteItineraryBlockFields on DatoCmsLePiloteItineraryBlock {
    __typename
    id
    title
    blockPictogram {
      alt
      url
    }
    departureLabel
    departurePlaceholder
    arrivalLabel
    arrivalPlaceholder
    micDepartureAltText
    micArrivalAltText
    microphonePictogram {
      url
    }
    geolocationIcon {
      alt
      url
    }
    geolocationLabel
    swapDepartureArrivalIcon {
      alt
      url
    }
    positionErrorMessage
    searchButtonLabel
    resultsPage {
      slug
    }
  }
`

export default LePiloteItineraryBlock
