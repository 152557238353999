import { graphql } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { TerDakarItinerarySearchBlockFieldsFragment } from "../../../../graphql-types"
import { useIsMobile } from "../../../hooks"
import * as blockStyles from "../custom-block/custom-block.module.scss"
import * as globalBlockStyles from "../main-blocks/main-blocks.module.scss"
import * as styles from "./ter-dakar-itinerary-search-block.module.scss"

type RenderProps = {
  block: TerDakarItinerarySearchBlockFieldsFragment
  isHighlighted: boolean
  intersectionRatioCallback?: (ratio: number) => void
}

const TerDakarItinerarySearchBlock: React.FC<RenderProps> = ({ block, isHighlighted, intersectionRatioCallback }) => {
  const component = useRef<HTMLFormElement>()
  const observer = useRef<IntersectionObserver>()
  const isSmallMobile: boolean = useIsMobile(360)
  const [dakarLoaded, setDakarLoaded] = useState(null)
  const [dakarStyleChanged, setDakarStyleChanged] = useState(null)

  useEffect(() => {
    if (intersectionRatioCallback) {
      if (!("IntersectionObserver" in window)) {
        return
      }
      const options = { threshold: [0.2, 0.4, 0.6, 0.8, 1.0] }
      observer.current = new IntersectionObserver(entries => {
        intersectionRatioCallback(entries[entries.length - 1].intersectionRatio)
      }, options)
      observer.current.observe(component.current)
      return () => observer.current.disconnect()
    }
  }, [])

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }
    const script = document.createElement("script")
    document.getElementsByTagName("body")[0].appendChild(script)
    script.async = true
    script.type = "text/javascript"
    script.src = `https://56.preprod-sim.instant-system.com/fr/widget/place-journey?token=${block.token}`
  }, [])

  useEffect(() => {
    if (typeof window === "undefined" || dakarLoaded) {
      return
    }
    const dakarWidget = document.getElementById("is-WidgetWrapper")
    dakarWidget.parentElement.style.zIndex = "2"
    if (dakarWidget && dakarWidget.innerHTML) {
      setDakarLoaded(true)
    }
  })

  useEffect(() => {
    if (dakarLoaded && !dakarStyleChanged) {
      const widget = document.getElementsByClassName("is-Widget")[0] as HTMLElement
      const journey = document.getElementById("is-Journey")
      if (isSmallMobile) {
        const journeyActionsArea = document.getElementById("is-Journey-MoreActionsArea")
        const journeyActionsAreaDatePreference = document.getElementById("is-Journey-MoreActionsArea-DatePreference")
        const journeyActionsAreaAdvancedSearch = document.getElementById("is-Journey-MoreActionsArea-AdvancedSearch")
        const journeySwapGroup = document.getElementById("is-Journey-SwapGroup")
        widget.style.minWidth = "0px"
        widget.style.padding = "0px"
        journey.style.paddingBottom = "0px"
        journeyActionsArea.style.transform = "translateX(10px)"
        journeyActionsAreaDatePreference.style.paddingRight = "0px"
        journeyActionsAreaAdvancedSearch.style.paddingLeft = "0px"
        journeyActionsAreaAdvancedSearch.style.transform = "translateX(-10px)"
        journeySwapGroup.style.margin = "0px"
      } else {
        widget.style.minWidth = "0px"
        journey.style.paddingBottom = "0px"
      }
      setDakarStyleChanged(true)
    }
  })

  return (
    <section
      className={`${blockStyles.block} ${blockStyles.customBlock}${globalBlockStyles.mainBlocksCard}`}
      ref={component}
    >
      <style>
        {`
          #is-Journey-MoreActionsArea {
            margin: 0 -1.3rem;
          }
          #is-PlaceSubmitButton {
            padding-top: 0 !important;
          }
        `}
      </style>
      <h3 className={styles.title}>{block.title}</h3>
      <div className={styles.widgetWrapper} id="is-WidgetWrapper" />
    </section>
  )
}

export const fragments = graphql`
  fragment TerDakarItinerarySearchBlockFields on DatoCmsTerDakarItinerarySearchBlock {
    __typename
    id
    title
    token
  }
`
export default TerDakarItinerarySearchBlock
