import React, { useState } from "react"
import {
  LostObjectsFormLineFieldsFragment,
  LostObjectsFormConfigurationFieldsFragment,
  CustomFormsConfigurationFieldsFragment,
} from "../../../graphql-types"
import { graphql } from "gatsby"
import * as styles from "./lost-objects-form.module.scss"
import { useForm } from "react-hook-form"
import Input, { ValidationType } from "../layout/form/input"
import * as inputsStyles from "../layout/inputs.module.scss"
import FormError from "../layout/form/form-error"
import Select from "../layout/form/select"
import Autocomplete from "../autocomplete/autocomplete"
import Textarea from "../layout/form/textarea"
import DateInput from "../layout/form/date-input"
import TimeInput from "../layout/form/time-input"
import { sendEmailV2 } from "../../api/notifications"
import { lostObjectsFormToText } from "./lost-objects-form-to-text"
import { pushGtmEvent } from "../../gtm/interaction-event"
import FileInput from "../layout/form/file-input"

type Line = LostObjectsFormLineFieldsFragment

type RenderProps = {
  title: string
  lines: Line[]
  moduleConfiguration: LostObjectsFormConfigurationFieldsFragment
  formsConfiguration: CustomFormsConfigurationFieldsFragment
}

const LostObjectsForm: React.FC<RenderProps> = ({ title, lines, moduleConfiguration, formsConfiguration }) => {
  const civilityOptions = JSON.parse(moduleConfiguration.civilityOptions)
  const [formState, setFormState] = useState<FormState>(FormState.Pending)
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()
  const [firstClick, setFirstClick] = useState(true)
  const [formValues, setFormValues] = useState<Map<string, string | boolean | FileList>>(new Map())

  function searchLine(query: string): LostObjectsFormLineFieldsFragment[] {
    return lines.filter(result => result.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
  }

  function onChange(fieldId, value) {
    const newValues = new Map(formValues)
    newValues.set(fieldId, value)
    setFormValues(newValues)
  }

  function onSubmit(formData) {
    pushGtmEvent("eventga", "Form", "Form Submit", title)
    const userEmailContent = lostObjectsFormToText(
      moduleConfiguration.userEmailContent,
      moduleConfiguration,
      formData
    ).replace(/&apos;/g, "'")
    const subsidiaryEmailContent = lostObjectsFormToText(
      moduleConfiguration.subsidiaryEmailContent,
      moduleConfiguration,
      formData
    ).replace(/&apos;/g, "'")

    setFormState(FormState.Submitting)
    // let replyTo = formData.email
    const files: File[] = Array.from(formData.attachments)
    const formId = moduleConfiguration.id.replace("DatoCmsLostObjectsFormConfiguration-", "")
    const subsidiaryEmailPromise = sendEmailV2(
      process.env.GATSBY_SUBSIDIARY_TRIGRAM,
      formId,
      subsidiaryEmailContent,
      files
    )
    const userEmailPromise = sendEmailV2(
      process.env.GATSBY_SUBSIDIARY_TRIGRAM,
      formId,
      userEmailContent,
      files,
      formData.email
    )
    return Promise.all([subsidiaryEmailPromise, userEmailPromise]).then(
      () => setFormState(FormState.Success),
      () => setFormState(FormState.Error)
    )
  }

  function pushGtmFirstClickEvent() {
    if (firstClick) {
      pushGtmEvent("Form", "Form", "Form 1st click", title)
      setFirstClick(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>{title}</h2>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="civilityField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.civilityFieldLabel}
        </label>
        <Select
          id="civilityField"
          name="honorific-prefix"
          autocomplete={true}
          required={true}
          register={register}
          options={civilityOptions}
          hasErrors={!!errors["honorific-prefix"]}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="honorific-prefix" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="nameField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.nameFieldLabel}
        </label>
        <Input
          id="nameField"
          type="text"
          name="family-name"
          autocomplete={true}
          placeholder={moduleConfiguration.nameFieldPlaceholder}
          required={true}
          register={register}
          hasErrors={!!errors["name"]}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="name" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="firstnameField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.firstnameFieldLabel}
        </label>
        <Input
          id="firstnameField"
          type="text"
          name="given-name"
          autocomplete={true}
          placeholder={moduleConfiguration.firstnameFieldPlaceholder}
          required={true}
          register={register}
          hasErrors={!!errors["given-name"]}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="given-name" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="emailField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.emailFieldLabel}
        </label>
        <Input
          id="emailField"
          type="text"
          name="email"
          autocomplete={true}
          placeholder={moduleConfiguration.emailFieldPlaceholder}
          required={true}
          register={register}
          validationType={ValidationType.Email}
          hasErrors={!!errors["email"]}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="email" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
        <FormError errors={errors} name="email" type="pattern">
          {formsConfiguration.emailFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="addressField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.addressFieldLabel}
        </label>
        <Input
          id="addressField"
          type="text"
          name="address"
          placeholder={moduleConfiguration.addressFieldPlaceholder}
          required={true}
          register={register}
          hasErrors={!!errors["address"]}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="address" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="zipCodeField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.zipCodeFieldLabel}
        </label>
        <Input
          id="zipCodeField"
          type="text"
          name="postal-code"
          autocomplete={true}
          placeholder={moduleConfiguration.zipCodeFieldPlaceholder}
          required={true}
          register={register}
          hasErrors={!!errors["postal-code"]}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="postal-code" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="cityField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.cityFieldLabel}
        </label>
        <Input
          id="cityField"
          type="text"
          name="city"
          placeholder={moduleConfiguration.cityFieldPlaceholder}
          required={true}
          register={register}
          hasErrors={!!errors["city"]}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="city" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="phoneField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.phoneFieldLabel}
        </label>
        <Input
          id="phoneField"
          type="text"
          name="tel"
          autocomplete={true}
          placeholder={moduleConfiguration.phoneFieldPlaceholder}
          required={true}
          register={register}
          hasErrors={!!errors["tel"]}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="tel" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="dateField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.dateFieldLabel}
        </label>
        <DateInput
          id="date"
          name="date"
          required={true}
          control={control}
          maxDate={new Date()}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="date" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="timeField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.timeFieldLabel}
        </label>
        <TimeInput id="time" name="time" required={true} control={control} onFocus={pushGtmFirstClickEvent} />
        <FormError errors={errors} name="time" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="lineField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.lineFieldLabel}
        </label>
        <Autocomplete
          id="lineField"
          name="line"
          toLabel={line => line.name}
          control={control}
          suggestFunction={searchLine}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="phone" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="directionField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.directionFieldLabel}
        </label>
        <Input
          id="directionField"
          type="text"
          name="direction"
          placeholder={moduleConfiguration.directionFieldPlaceholder}
          required={true}
          register={register}
          hasErrors={!!errors["direction"]}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="direction" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="stopField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.stopFieldLabel}
        </label>
        <Input
          id="stopField"
          type="text"
          name="stop"
          placeholder={moduleConfiguration.stopFieldPlaceholder}
          required={true}
          register={register}
          hasErrors={!!errors["stop"]}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="stop" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="messageField" className={inputsStyles.label + " " + inputsStyles.labelRequired}>
          {moduleConfiguration.messageFieldLabel}
        </label>
        <Textarea
          id="messageField"
          name="message"
          placeholder={moduleConfiguration.messageFieldPlaceholder}
          required={true}
          register={register}
          hasErrors={!!errors["message"]}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="message" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <div className={inputsStyles.formPart + " " + styles.formPart}>
        <label htmlFor="attachments" className={inputsStyles.label}>
          {moduleConfiguration.attachmentsFieldLabel}
        </label>
        <FileInput
          key={"attachments"}
          id={"attachments"}
          type="file"
          name={"attachments"}
          onChange={value => onChange("attachments", value)}
          className={""}
          register={register}
          onFocus={pushGtmFirstClickEvent}
        />
        <FormError errors={errors} name="attachments" type="required">
          {formsConfiguration.requiredFieldError}
        </FormError>
      </div>

      <button
        type="submit"
        className={inputsStyles.primaryButton + " " + styles.submitButton}
        disabled={formState === FormState.Submitting || formState === FormState.Success}
      >
        {moduleConfiguration.submitButtonLabel}
      </button>
      {formState === FormState.Error && (
        <div className={inputsStyles.formError + " " + styles.globalError}>{moduleConfiguration.errorMessage}</div>
      )}
      {formState === FormState.Success && (
        <div className={styles.successMessage}>{moduleConfiguration.successMessage}</div>
      )}
    </form>
  )
}

export const fragments = graphql`
  fragment LostObjectsFormConfigurationFields on DatoCmsLostObjectsFormConfiguration {
    id
    addressFieldLabel
    addressFieldPlaceholder
    attachmentsFieldLabel
    cityFieldLabel
    cityFieldPlaceholder
    civilityFieldLabel
    firstnameFieldPlaceholder
    firstnameFieldLabel
    errorMessage
    emailFieldPlaceholder
    emailFieldLabel
    directionFieldLabel
    directionFieldPlaceholder
    dateFieldLabel
    civilityOptions
    zipCodeFieldPlaceholder
    zipCodeFieldLabel
    timeFieldLabel
    successMessage
    submitButtonLabel
    stopFieldPlaceholder
    stopFieldLabel
    phoneFieldPlaceholder
    phoneFieldLabel
    nameFieldPlaceholder
    nameFieldLabel
    messageFieldPlaceholder
    messageFieldLabel
    lineFieldLabel
    # fromEmail
    # subsidiaryEmail
    userEmailSubject
    userEmailContent {
      value
      blocks
      links {
        id: originalId
        fragmentType
      }
    }
    subsidiaryEmailSubject
    subsidiaryEmailContent {
      value
      blocks
      links {
        id: originalId
        fragmentType
      }
    }
  }

  fragment LostObjectsFormLineFields on DatoCmsLine {
    id
    name
  }
`

enum FormState {
  Pending,
  Submitting,
  Success,
  Error,
}

export default LostObjectsForm
