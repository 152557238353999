import * as inputsStyles from "../inputs.module.scss"
import React from "react"
import { arrowDown } from "../../../../static/images"
import { ReactSVG } from "react-svg"
import { RegisterOptions, UseFormRegisterReturn } from "react-hook-form"

type RenderProps = {
  id?: string
  name: string
  required?: boolean
  className?: string
  options: string[]
  hasErrors: boolean
  onFocus?: (value: any) => void
  autocomplete?: boolean
} & (
  | {
      value?: string
      onChange?: (value: string) => void
      register: (name: string, options?: RegisterOptions) => UseFormRegisterReturn
    }
  | {
      value: string
      onChange: (value: string) => void
      register: undefined
    }
)

const Select: React.FC<RenderProps> = ({
  id,
  name,
  required,
  className = "",
  value,
  options,
  onChange,
  register,
  hasErrors,
  onFocus,
  autocomplete,
}) => {
  return (
    <div className={inputsStyles.select + " " + (hasErrors ? inputsStyles.fieldInError + " " : "") + className}>
      <select
        id={id}
        name={name}
        required={required}
        autoComplete={autocomplete ? name : undefined}
        onChange={event => onChange && onChange(event.target.value)}
        value={value}
        {...register(name, { required })}
        onFocus={event => {
          onFocus && onFocus(event.target.id)
        }}
      >
        {options.map(option => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
      <ReactSVG
        src={arrowDown}
        className={inputsStyles.arrowDown}
        beforeInjection={svg => svg.setAttribute("aria-hidden", "true")}
      />
    </div>
  )
}

export default Select
