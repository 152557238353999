import * as inputsStyles from "../inputs.module.scss"
import React from "react"
import { FieldErrors } from "react-hook-form/dist/types/errors"

type RenderProps = {
  errors: FieldErrors
  name: string
  type: string
  id?: string
  children?: React.ReactNode
}

const FormError: React.FC<RenderProps> = ({ errors, name, type, id, children }) => {
  if (errors && errors[name] && errors[name].type === type) {
    return (
      <div className={inputsStyles.formError} id={id || null} aria-describedby={name || null}>
        {children}
      </div>
    )
  }
  return <></>
}

export default FormError
