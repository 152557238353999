import React from "react"
import { graphql } from "gatsby"

import * as styles from "../type-page/type-page.module.scss"
import { useFormatLocale, useIsMobile } from "../../hooks"
import { detectWebView } from "../../utils"
import Layout from "../../components/layout/layout"
import TitleBanner from "../../components/type-page/title-banner/title-banner"
import Breadcrumb from "../../components/type-page/breadcrumb/breadcrumb"
import ShareButtons from "../../components/type-page/share-buttons/share-buttons"
import NewsBlocks from "../../components/type-page/news-blocks/news-blocks"
import SideBlocks from "../../components/type-page/side-blocks/side-blocks"

import { isNewsPublished } from "../../components/all-news/all-news"
import { RelativeLanguagePaths } from "../../components/layout/header/header-tool-bar/language-selector/language-selector"
import LoadScripts from "../../components/load-scripts"
import { MyAlertPageQuery } from "../../../graphql-types"

import SchedulesSearchResults from "../../components/schedules-search/results/schedules-search-results"
import { useLinesSubscription } from "../../hooks/use-lines-subscription"

type RenderProps = {
  data: MyAlertPageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    relativeLanguagePaths: RelativeLanguagePaths
  }
}

const SubscribedLines = ({ data }) => {
  const { isSubscribed } = useLinesSubscription()
  const linesToDisplay = data.allDatoCmsLine.nodes.filter(line => isSubscribed(line.id))
  const moduleConfiguration = data.datoCmsSchedulesSearchModule
  moduleConfiguration.resultsLabel = ""
  moduleConfiguration.noResultLabel = "Vous n'avez pas d'alerte pour le moment"
  return (
    <SchedulesSearchResults
      lines={linesToDisplay}
      // lines={data.allDatoCmsLine.nodes}
      moduleConfiguration={moduleConfiguration}
      selectedLine={null}
      selectedMunicipality={null}
      selectedPointOfInterest={null}
    />
  )
}

const MyAlertPage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const twitterDisplay: boolean = data.datoCmsGlobalConfiguration.twitterDisplay
  const facebookDisplay: boolean = data.datoCmsGlobalConfiguration.fbDisplay
  const sideBlocks = data.datoCmsGlobalConfiguration.sideBlocks
  const lastThreeNewsLabel: string = data.datoCmsGlobalConfiguration.lastNewsLabel
  const lastThreeNews = data.allDatoCmsNews.nodes.filter(isNewsPublished).slice(0, 3)

  const locale = useFormatLocale(pageContext.locale)
  const defaultLocale = useFormatLocale(pageContext.defaultLocale)
  const isWebView = detectWebView()

  return (
    <Layout
      location={location}
      menuData={data.datoCmsMenu}
      footerData={data.datoCmsFooter}
      toolbarData={data.datoCmsGlobalConfiguration}
      scriptsData={data.datoCmsGlobalConfiguration}
      locale={locale}
      defaultLocale={defaultLocale}
      relativeLanguagePaths={pageContext.relativeLanguagePaths}
    >
      <div className={styles.typePage}>
        {/* Localise and get the title from dato */}
        <TitleBanner title={"Mes Alertes"} />
        <Breadcrumb menuData={data.datoCmsMenu} pageTitle="My Alert" locale={locale} defaultLocale={defaultLocale} />
        <div className={styles.typePageContent}>
          {/* <div className={styles.content} style={{ width: data.datoCmsInfoTraffic.hideRightSidebar ? "100%" : "75%" }}> */}
          <div className={styles.content}>
            {/* Localise and get the message from dato */}
            {isWebView ? <SubscribedLines data={data} /> : <div>Seulement disponible sur l'app mobile </div>}
          </div>

          <div className={styles.rightSidebar}>
            <ShareButtons facebookDisplay={facebookDisplay} twitterDisplay={twitterDisplay}></ShareButtons>
            <NewsBlocks news={lastThreeNews} label={lastThreeNewsLabel} locale={locale} defaultLocale={defaultLocale} />
            <SideBlocks blocks={sideBlocks} locale={locale} defaultLocale={defaultLocale} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data }) => {
  return <LoadScripts scriptData={data.datoCmsGlobalConfiguration} />
}

export const pageQuery = graphql`
  query MyAlertPage($locale: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDatoCmsLine(locale: $locale, sort: { position: ASC }) {
      nodes {
        ...LinesListFields
        ...LostObjectsFormLineFields
      }
    }
    datoCmsSchedulesSearchModule {
      ...SchedulesSearchModuleFields
    }
    datoCmsMenu(locale: $locale) {
      ...HeaderMenuFields
      ...FooterMenuFields
      label
      label2
      label3
      label4
      column1 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
      column2 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
      column3 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
      column4 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
    }

    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }

    datoCmsGlobalConfiguration {
      id
      twitterDisplay
      fbDisplay
      # infoTrafficTitle
      # infoTrafficDetailSlug
      sideBlocks {
        ...SideBlockFields
      }
      ...AllNewsModuleConfigurationFields
      ...HeaderToolBarFields
      ...ScriptsFields
    }
    allDatoCmsNews(locale: $locale, sort: { publicationDate: DESC }) {
      nodes {
        __typename
        id
        title
        slug
        publicationDate
        publicationStopDate
      }
    }
  }
`

export default MyAlertPage
