import React from "react"
import { ReactSVG } from "react-svg"
import * as styles from "./transport-title.module.scss"
import { TransportTitleListFieldsFragment } from "../../../../graphql-types"
import sanitizeHtml from "sanitize-html"
import { useIsMobile } from "../../../hooks"
import RectangularButton from "../rectangular-button/rectangular-button"

type RenderProps = {
  transportTitleData: TransportTitleListFieldsFragment
}

const TransportTitle: React.FC<RenderProps> = ({ transportTitleData }) => {
  const isMobile = useIsMobile()
  const TEXT_MAX_LENGTH: number = isMobile ? 335 : 414
  const isEllispisNeeded: boolean =
    transportTitleData.description && transportTitleData.description.length > TEXT_MAX_LENGTH ? true : false

  return (
    <div className={styles.transportTitle}>
      <div className={styles.transportTitleLabel}>{transportTitleData.title}</div>
      <div className={styles.transportTitleContent}>
        {transportTitleData.picture && transportTitleData.picture.url ? (
          <div className={styles.transportTitleImageBox}>
            {transportTitleData.picture.format == "svg" ? (
              <ReactSVG
                src={transportTitleData.picture.url}
                beforeInjection={svg => svg.setAttribute("aria-label", transportTitleData.picture.alt || "")}
              />
            ) : (
              <img src={transportTitleData.picture.url} alt={transportTitleData.picture.alt || ""} />
            )}
          </div>
        ) : null}
        <div className={styles.transportTitleDescription}>
          <div
            className={
              isEllispisNeeded ? `${styles.truncateContent} ${styles.truncateOverflow}` : `${styles.truncateContent}`
            }
            title={transportTitleData.description}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(transportTitleData.description),
              }}
            ></p>
          </div>
          <div className={styles.transportTitleActionButton}>
            {transportTitleData.redirectionUrl ? (
              <RectangularButton
                label={transportTitleData.redirectionLabel}
                redirectUrl={transportTitleData.redirectionUrl}
              ></RectangularButton>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransportTitle
