// extracted by mini-css-extract-plugin
export var block = "traffic-parent-block-module--block--73d61";
export var blockHeader = "traffic-parent-block-module--blockHeader--c846c";
export var blockPicto = "traffic-parent-block-module--blockPicto--6c766";
export var customBlock = "traffic-parent-block-module--customBlock--756fe";
export var image = "traffic-parent-block-module--image--c4cda";
export var infoTrafficBlock = "traffic-parent-block-module--infoTrafficBlock--e87c5";
export var link = "traffic-parent-block-module--link--ce4fb";
export var linkTraffic = "traffic-parent-block-module--linkTraffic--6cea2";
export var myContainer = "traffic-parent-block-module--myContainer--e272c";
export var selectedButton = "traffic-parent-block-module--selectedButton--ba244";
export var svgImage = "traffic-parent-block-module--svgImage--c30b2";
export var tabButtonsContainer = "traffic-parent-block-module--tabButtonsContainer--1141f";
export var title = "traffic-parent-block-module--title--c202d";
export var trafficStatusLabel = "traffic-parent-block-module--trafficStatusLabel--d98b2";
export var unselectedButton = "traffic-parent-block-module--unselectedButton--96f45";