import React, { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { FooterFieldsFragment, FooterMenuFieldsFragment } from "../../../../graphql-types"
import * as styles from "./footer.module.scss"
import { useIsMobile } from "../../../hooks"
import FooterBarMenu from "./footer-bar-menu/footer-bar-menu"
import FooterTableMenu from "./footer-table-menu/footer-table-menu"
import {
  arrowIcon,
  facebookLogo,
  keolisLogoSmall,
  instagramLogo,
  linkedInLogo,
  twitterLogo,
  youtubeLogo,
} from "../../../../static/images"

const ENTER_KEY_CODE = 13
const SPACE_KEY_CODE = 32

type RenderProps = {
  data: FooterFieldsFragment
  menuData: FooterMenuFieldsFragment
  locale: string
  defaultLocale: string
}

const Footer: React.FC<RenderProps> = ({ data, menuData, locale, defaultLocale }) => {
  const isMobile = useIsMobile()
  const [isOpen, setIsOpen] = useState(!isMobile)

  useEffect(() => setIsOpen(!isMobile), [isMobile])

  const handleKeypress = e => {
    const key_code = e.keyCode ? e.keyCode : e.which
    if (key_code === ENTER_KEY_CODE || key_code === SPACE_KEY_CODE) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <footer className={styles.globalFooter} role="contentinfo">
      <div className={styles.collapsibleFooter}>
        <div className={styles.collapsibleFooterLogos}>
          <img
            src={data.subsidiaryLogo?.url || keolisLogoSmall}
            alt={data.subsidiaryLogo?.alt || "Keolis Logo"}
            className={styles.keolisLogo}
            width={data.subsidiaryLogo?.width /* 289 */}
            height={data.subsidiaryLogo?.height /* 59 */}
          />
          <div className={styles.transportIcons}>
            {data.transportMethodsLink.map((transportMethod, index) => {
              return transportMethod.pictogram.format === "svg" ? (
                <ReactSVG
                  src={transportMethod.pictogram.url}
                  className={styles.svgImage}
                  beforeInjection={svg => svg.setAttribute("aria-label", transportMethod.pictogram.alt || "")}
                  key={index}
                />
              ) : (
                <img
                  src={transportMethod.pictogram.url}
                  alt={transportMethod.pictogram.alt || ""}
                  className={styles.transportIcon}
                  key={index}
                />
              )
            })}
          </div>
          <div className={styles.socialNetworksIcons} role="list">
            {data.facebookUrl && (
              <span role="listitem">
                <a href={data.facebookUrl}>
                  <img src={facebookLogo} alt="facebook" className={styles.socialNetworkIcon} />
                </a>
              </span>
            )}
            {data.twitterUrl && (
              <span role="listitem">
                <a href={data.twitterUrl}>
                  <img src={twitterLogo} alt="twitter" className={styles.socialNetworkIcon} />
                </a>
              </span>
            )}
            {data.youtubeUrl && (
              <span role="listitem">
                <a href={data.youtubeUrl}>
                  <img src={youtubeLogo} alt="youtube" className={styles.socialNetworkIcon} />
                </a>
              </span>
            )}
            {data.linkedinUrl && (
              <span role="listitem">
                <a href={data.linkedinUrl}>
                  <img src={linkedInLogo} alt="linkedIn" className={styles.socialNetworkIcon} />
                </a>
              </span>
            )}
            {data.instagramUrl && (
              <span role="listitem">
                <a href={data.instagramUrl}>
                  <img src={instagramLogo} alt="instagram" className={styles.socialNetworkIcon} />
                </a>
              </span>
            )}
          </div>
        </div>
        {isMobile && (
          <div
            className={isOpen ? styles.arrowBoxOpen : styles.arrowBox}
            onClick={() => setIsOpen(!isOpen)}
            onKeyPress={handleKeypress}
            aria-expanded={isOpen}
            role="button"
            tabIndex={0}
          >
            <ReactSVG
              src={arrowIcon}
              className={styles.arrowIcon}
              beforeInjection={svg => svg.setAttribute("aria-label", "Pied de page")}
            />
          </div>
        )}
      </div>
      <div className={isOpen ? styles.collapsibleContentOpen : styles.collapsibleContent}>
        <div className={`${styles.organizingAuthorityLogoBox} ${styles.hideOnMobile}`}>
          {data.organizingAuthorityTitle && (
            <div className={styles.organizingAuthorityTitle}>{data.organizingAuthorityTitle}</div>
          )}
          {data.organizingAuthorityLogo && (
            <img
              src={data.organizingAuthorityLogo.url}
              alt={data.organizingAuthorityLogo.alt}
              className={styles.organizingAuthorityLogo}
            />
          )}
        </div>
        <FooterTableMenu data={menuData} locale={locale} defaultLocale={defaultLocale} />
        <FooterBarMenu barMenu={data.barMenu} locale={locale} defaultLocale={defaultLocale} />
        <div className={`${styles.organizingAuthorityLogoBox} ${styles.showOnMobile}`}>
          {data.organizingAuthorityTitle && (
            <div className={styles.organizingAuthorityTitle}>{data.organizingAuthorityTitle}</div>
          )}
          {data.organizingAuthorityLogo && (
            <img
              src={data.organizingAuthorityLogo.url}
              alt={data.organizingAuthorityLogo.alt}
              className={styles.organizingAuthorityLogo}
            />
          )}
        </div>
      </div>
    </footer>
  )
}

export const footerFragment = graphql`
  fragment FooterFields on DatoCmsFooter {
    facebookUrl
    twitterUrl
    linkedinUrl
    youtubeUrl
    instagramUrl
    transportMethodsLink {
      id
      name
      pictogram {
        alt
        url
        format
      }
    }
    organizingAuthorityTitle
    organizingAuthorityLogo {
      alt
      url
    }
    subsidiaryLogo {
      alt
      url
      format
      width
      height
    }
    barMenu {
      ...FooterBarMenuFields
    }
  }

  fragment FooterMenuFields on DatoCmsMenu {
    label
    footerDisplay1
    page {
      slug
    }
    column1 {
      label
      footerDisplay
      page {
        slug
      }
    }
    label2
    footerDisplay2
    page2 {
      slug
    }
    column2 {
      label
      footerDisplay
      page {
        slug
      }
    }
    label3
    footerDisplay3
    page3 {
      slug
    }
    column3 {
      label
      footerDisplay
      page {
        slug
      }
    }
    label4
    footerDisplay4
    page4 {
      slug
    }
    column4 {
      label
      footerDisplay
      page {
        slug
      }
    }
    label5
    footerDisplay5
    page5 {
      slug
    }
    column5 {
      label
      footerDisplay
      page {
        slug
      }
    }
    menuSection {
      label
      externalPage
      page {
        slug
      }
      headerDisplay
      footerDisplay
      subMenu {
        label
        externalPage
        page {
          slug
        }
        headerDisplay
        footerDisplay
      }
    }
  }
`
export default Footer
