import { graphql } from "gatsby"
import { InternalExternalLinkFieldsFragment } from "../../graphql-types"

export const useInternalExternalLink = (
  link: InternalExternalLinkFieldsFragment,
  locale: string,
  defaultLocale: string
): string => {
  if (link && !link.isInternalPage) {
    return link.externalLink
  }

  const slugLanguagePrefix = locale === defaultLocale ? "" : `/${locale}`
  switch (link?.internalLink?.__typename) {
    case "DatoCmsHome":
      return slugLanguagePrefix.length > 0 ? slugLanguagePrefix : "/"
    case "DatoCmsPage":
      return `${slugLanguagePrefix}/${link.internalLink.slug}`
    case "DatoCmsInfoTraffic":
      return `${slugLanguagePrefix}/traffic-info/${link.internalLink.slug}`
    case "DatoCmsNews":
      return `${slugLanguagePrefix}/news/${link.internalLink.slug}`
  }
}

export const fragment = graphql`
  fragment InternalExternalLinkFields on DatoCmsInternalExternalLinkBlock {
    isInternalPage
    externalLink
    internalLink {
      __typename
      ... on DatoCmsHome {
        __typename
      }
      ... on DatoCmsPage {
        __typename
        slug
      }
      ... on DatoCmsInfoTraffic {
        __typename
        slug
      }
      ... on DatoCmsNews {
        __typename
        slug
      }
    }
  }
`
