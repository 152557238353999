import React, { useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { InstantSystemQuery, InstantSystemWidgetBlockFieldsFragment } from "../../../../graphql-types"
import * as globalBlockStyles from "../main-blocks/main-blocks.module.scss"
import * as blockStyles from "./instant-system-widget-block.module.scss"
import "./custom-itinerary.scss"
import "./itinerary-widget.scss"
import { ReactSVG } from "react-svg"
import { infoTrafficBlockPicto } from "../../../../static/images"

type RenderProps = {
  block: InstantSystemWidgetBlockFieldsFragment
  intersectionRatioCallback: (ratio: number) => void
}

const InstantSystemWidgetBlock: React.FC<RenderProps> = ({ block }) => {
  const data: InstantSystemQuery = useStaticQuery(graphql`
    query InstantSystem {
      datoCmsInstantSystemConfig {
        ...InstantSystemConfiguration
      }
    }
  `)
  const component = useRef<HTMLElement>()
  const widgetIs = useRef<HTMLDivElement>()

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }
    const scriptId = "installer-script"
    if (document.getElementById(scriptId) && block.widgetType === "Itinéraire") {
      // initializeWidget is a function provided by the Instant System Widget script
      // it is automatically called the first time but we need to call it again when the widget is re-rendered
      //@ts-ignore
      window.initializeWidget(widgetIs.current) // mount widget
      return
    }
    const script = document.createElement("script")
    script.id = scriptId

    document.getElementsByTagName("body")[0].appendChild(script)
    script.async = true
    script.type = "text/javascript"

    switch (block.widgetType) {
      case "Info trafic":
        script.src = data.datoCmsInstantSystemConfig?.infoTrafficWidget
        break
      case "Itinéraire":
        script.src = data.datoCmsInstantSystemConfig?.itineraryWidget
        break
      case "Horaires":
        script.src = ""
        break
    }
  }, [])
  return (
    <section
      className={`${blockStyles.block} ${globalBlockStyles.mainBlocksCard} ${block.widgetType === "Itinéraire" ? blockStyles.blockIti : ""}`}
      ref={component}
    >
      <div className={blockStyles.blockHeader}>
        <ReactSVG
          src={infoTrafficBlockPicto}
          className={blockStyles.blockPicto}
          beforeInjection={svg => svg.setAttribute("aria-label", "info trafic")}
        />
        <h3 className={blockStyles.title}>{block.widgetType}</h3>
      </div>

      {block.widgetType === "Info trafic" && <div id="is-WidgetWrapper_Traffic"></div>}
      {block.widgetType === "Itinéraire" && (
        // <div data-is-widget data-domain="services.fluo.grandest.fr" data-widget-type="trip-planner"></div>
        <div
          ref={widgetIs}
          data-is-widget
          data-domain={data.datoCmsInstantSystemConfig?.itineraryWidget
            ?.replace("//", "")
            .replace("/widget/v1/installer.js", "")}
          data-widget-type="trip-planner"
        ></div>
      )}
      {block.widgetType === "Horaires" && (
        <>
          <div id="is-WidgetWrapper_Schedule"></div>
          <div id="is-MapWrapper"></div>
        </>
      )}
    </section>
  )
}
export const fragments = graphql`
  fragment InstantSystemWidgetBlockFields on DatoCmsInstantSystemWidgetBlock {
    __typename
    id
    widgetType
  }
  fragment InstantSystemConfiguration on DatoCmsInstantSystemConfig {
    infoTrafficWidget
    itineraryWidget
  }
`
export default InstantSystemWidgetBlock
