// extracted by mini-css-extract-plugin
export var content = "rich-datocms-content-module--content--a014f";
export var embeddedIframe = "rich-datocms-content-module--embeddedIframe--d3471";
export var highlight = "rich-datocms-content-module--highlight--ebf82";
export var image = "rich-datocms-content-module--image--e13a3";
export var imageOnRight = "rich-datocms-content-module--imageOnRight--c2c03";
export var richContent = "rich-datocms-content-module--richContent--02f35";
export var textWithImage = "rich-datocms-content-module--textWithImage--01227";
export var textWithVideo = "rich-datocms-content-module--textWithVideo--deb02";
export var video = "rich-datocms-content-module--video--60a01";
export var videoOnRight = "rich-datocms-content-module--videoOnRight--e13b9";
export var videoOnly = "rich-datocms-content-module--videoOnly--0274a";