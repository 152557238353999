import React from "react"
import {
  TicketOfficeSearchFieldsFragment,
  TicketOfficeSearchModuleConfigurationFieldsFragment,
} from "../../../../graphql-types"
import * as styles from "./ticket-office-search-results.module.scss"
import TicketOfficeSearchResult from "../result/ticket-office-search-result"

type TicketOffice = TicketOfficeSearchFieldsFragment

type RenderProps = {
  ticketOffices: TicketOffice[]
  moduleConfiguration: TicketOfficeSearchModuleConfigurationFieldsFragment
}

const TicketOfficeSearchResults: React.FC<RenderProps> = ({ ticketOffices, moduleConfiguration }) => {
  return (
    <ul className={styles.results}>
      {ticketOffices.map(ticketOffice => {
        return (
          <li key={ticketOffice.id} className={styles.result}>
            <TicketOfficeSearchResult ticketOffice={ticketOffice} moduleConfiguration={moduleConfiguration} />
          </li>
        )
      })}
    </ul>
  )
}

export default TicketOfficeSearchResults
