import React from "react"
import * as styles from "./capsule.module.scss"

type RenderProps = {
  label: string
  handleClick: (e: React.MouseEvent<HTMLInputElement>) => void
  filters: string[]
}

const Capsule: React.FC<RenderProps> = ({ label, handleClick, filters }) => {
  const selected = filters.includes(label)

  return (
    <input
      type="button"
      onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => handleClick(e)}
      aria-pressed={selected}
      className={selected ? `${styles.capsule} ${styles.selected}` : `${styles.capsule}`}
      value={label}
    />
  )
}

export default Capsule
