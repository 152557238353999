import React, { useEffect, useRef, useState } from "react"
import { ReactSVG } from "react-svg"
import { useForm } from "react-hook-form"
import {
  LePiloteItineraryModuleFieldsFragment,
  LePiloteInfoTrafficLinesIconsFieldsFragment,
} from "../../../graphql-types"
import * as styles from "./le-pilote-itinerary.module.scss"
import { useSpeechToText } from "../../hooks"
import {
  getItineraryLePilote,
  getItineraryLePiloteFormatedResults,
  getTripPointsFromApi,
} from "../../api/le-pilote-api"
import Autocomplete from "../autocomplete/autocomplete"
import DateInput from "../layout/form/date-input"
import TimeInput from "../layout/form/time-input"

interface TripStop {
  type: string
  value?: string
  latitude?: string
  longitude?: string
}

type RenderProps = {
  data: LePiloteItineraryModuleFieldsFragment
  sendDataToParent: (data: any) => void
  linesIconsData: LePiloteInfoTrafficLinesIconsFieldsFragment[]
}

const LePiloteItineraryForm: React.FC<RenderProps> = ({ data, sendDataToParent, linesIconsData }) => {
  const component = useRef<HTMLFormElement>()
  const { control } = useForm<FormData>()
  const [listStartStops, setListStartStops] = useState(undefined)
  const [switchedStart, setSwitchedStart] = useState(null)
  const [switchedEnd, setSwitchedEnd] = useState(null)
  const [listEndStops, setListEndStops] = useState(undefined)
  const [departureCoord, setDepartureCoord] = useState(null)
  const [arrivalCoord, setArrivalCoord] = useState(null)

  const [resultError, setResultError] = useState(null)
  const [checkboxValue, setCheckboxValue] = useState(false)

  const [invalidDeparture, setInvalidDeparture] = useState("")
  const [invalidArrival, setInvalidArrival] = useState("")

  function formatStopsResults(stops) {
    return stops || []
  }

  function getStartStopList(query) {
    getTripPointsFromApi(query).then(tripPoints => {
      if (tripPoints) {
        let formatedStops = formatStopsResults(tripPoints)
        setListStartStops(formatedStops)
        return formatedStops
      }
    })
  }

  function getStartStop(query) {
    if (departureCoord) {
      setDepartureCoord(null)
      return []
    }
    if (!query || query == undefined || query == "" || query == "undefined") {
      return []
    }
    getStartStopList(query)
    return (Array.isArray(listStartStops) && listStartStops) || []
  }

  function getEndStop(query) {
    if (arrivalCoord) {
      setArrivalCoord(null)
      return []
    }
    if (!query || query == undefined || query == "" || query == "undefined") {
      return []
    }
    getEndStopList(query)
    return (Array.isArray(listEndStops) && listEndStops) || []
  }

  function getEndStopList(query) {
    getTripPointsFromApi(query).then(tripPoints => {
      if (tripPoints) {
        let formatedStops = formatStopsResults(tripPoints)
        setListEndStops(formatedStops)
        return formatedStops
      }
    })
  }

  function toLabel(element?: any) {
    return element ? element.Name : "Recherche..."
  }

  function selectStartStop(startStop) {
    setDepartureCoord(null)
    setInvalidDeparture("")
    setListStartStops(startStop || listStartStops)
    setTimeout(() => {
      setIsListeningDeparture(false)
    }, 1500)
  }

  function selectEndStop(endStop) {
    setArrivalCoord(null)
    setInvalidArrival("")
    setListEndStops(endStop || listEndStops)
    setTimeout(() => {
      setIsListeningArrival(false)
    }, 1500)
  }

  function getTripStopsValueAndType(departure: string[], arrival: string[]) {
    let start: TripStop = { type: "", value: null }
    let end: TripStop = { type: "", value: null }
    if (departure[0] == "coords") {
      start.type = "COORDINATES"
      start.latitude = departure[2]
      start.longitude = departure[3]
    } else {
      start.type = "4"
      start.value = departure[0]
    }
    if (arrival[0] == "coords") {
      end.type = "COORDINATES"
      end.latitude = arrival[2]
      end.longitude = arrival[3]
    } else {
      end.type = "4"
      end.value = arrival[0]
    }
    return [start, end]
  }

  useEffect(() => {
    if (window.location.search) {
      const queryString = window.location.search
      if (queryString) {
        const urlParams = new URLSearchParams(queryString)
        const departure = urlParams.get("departure").split("#")
        const arrival = urlParams.get("arrival").split("#")
        if (departure && departure[0] && departure[1] && departure[2]) {
          setSwitchedStart({
            Id: departure[0],
            Name: departure[1],
            PointType: departure[2],
          })
        }
        if (arrival && arrival[0] && arrival[1] && arrival[2]) {
          setSwitchedEnd({
            Id: arrival[0],
            Name: arrival[1],
            PointType: arrival[2],
          })
        }
        const position = getTripStopsValueAndType(departure, arrival)
        let dateTime = new Date()
        let dd = String(dateTime.getDate()).padStart(2, "0")
        let mm = String(dateTime.getMonth() + 1).padStart(2, "0")
        let yyyy = dateTime.getFullYear()
        let hour = dateTime.getHours()
        let minutes = dateTime.getMinutes().toString()
        if (minutes.length == 1) {
          minutes = "0" + minutes
        }
        let time = hour + ":" + minutes
        let today = dd + "/" + mm + "/" + yyyy
        getItinerariesData(position[0], position[1], today, time, position[0].type, position[1].type)
        window.history.replaceState({}, document.title, window.location.href.split("?")[0])
      }
    }
  }, [])

  const submitForm = e => {
    e.preventDefault()
    setResultError(null)
    const form = component.current
    let departure: any
    let departurePointType = ""
    let arrival: any
    let arrivalPointType = ""
    let dateType = form["dateType"].checked
    if (departureCoord) {
      departurePointType = "COORDINATES"
      departure = departureCoord
    } else {
      departure = form["departureLine"].value.split("#")[0]
      departurePointType = form["departureLine"].value.split("#")[2]
    }
    if (arrivalCoord) {
      arrivalPointType = "COORDINATES"
      arrival = arrivalCoord
    } else {
      arrival = form["arrivalLine"].value.split("#")[0]
      arrivalPointType = form["arrivalLine"].value.split("#")[2]
    }
    let date = form["date"].value
    let time = form["time"].value
    if (!departure || departure == "" || departure == undefined || departure == "undefined") {
      if (listStartStops && listStartStops.Name) {
        departure = listStartStops.Id
        departurePointType = listStartStops.PointType
      } else if (listStartStops && listStartStops[0] && listStartStops[0].Name) {
        departure = listStartStops[0].Id
        departurePointType = listStartStops[0].PointType
      } else {
        setInvalidDeparture(data.positionErrorMessage)
        return
      }
    }
    if (!arrival || arrival == "" || arrival == undefined || arrival == "undefined") {
      if (listEndStops && listEndStops.Name) {
        arrival = listEndStops.Id
        arrivalPointType = listEndStops.PointType
      } else if (listEndStops && listEndStops[0] && listEndStops[0].Name) {
        arrival = listEndStops[0].Id
        arrivalPointType = listEndStops[0].PointType
      } else {
        setInvalidArrival(data.positionErrorMessage)
        return
      }
    }
    getItinerariesData(departure, arrival, date, time, departurePointType, arrivalPointType, dateType)
  }

  function getItinerariesData(departureId, arrivalId, date, time, departurePointType, arrivalPointType, dateType) {
    getItineraryLePilote(departureId, arrivalId, date, time, departurePointType, arrivalPointType, dateType)
      .then(tripsData => {
        if (tripsData) {
          const tripsResults = getItineraryLePiloteFormatedResults(tripsData, linesIconsData)
          sendDataToParent(tripsResults)
          setResultError(null)
        }
      })
      .catch(responseError => {
        if (responseError[0] == 300) {
          setResultError(data.tripAvailabilityErrorMessage)
        } else if (responseError[0] == 500) {
          if (responseError[2].includes("ArrivalId")) {
            setInvalidArrival(data.positionErrorMessage)
          } else if (responseError[2].includes("DepartureId")) {
            setInvalidDeparture(data.positionErrorMessage)
          }
        }
      })
  }

  const handleGeoLoc = param => e => {
    if ((departureCoord && param === "departure") || (arrivalCoord && param == "arrival")) {
      return
    }

    if (param === "departure") {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            let departure = document.getElementById("linesInputDeparture") as HTMLInputElement
            departure.value = ""
            let coords = position.coords
            coords["Name"] = data.geolocationLabel
            setDepartureCoord(coords)
            if (arrivalCoord) {
              setArrivalCoord(null)
              setListEndStops(null)
              setSwitchedEnd(null)
            }
            setInvalidDeparture("")
            setListStartStops(coords)
            setSwitchedStart(null)
          },
          function (error) {
            if (error.code == error.PERMISSION_DENIED) console.log("you denied me :-(")
          }
        )
      }
    } else if (param === "arrival") {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            let arrival = document.getElementById("linesInputArrival") as HTMLInputElement
            arrival.value = ""
            let coords = position.coords
            coords["Name"] = data.geolocationLabel
            setArrivalCoord(coords)
            if (departureCoord) {
              setDepartureCoord(null)
              setListStartStops(null)
              setSwitchedStart(null)
            }
            setInvalidArrival("")
            setListEndStops(coords)
            setSwitchedEnd(null)
          },
          function (error) {
            if (error.code == error.PERMISSION_DENIED) console.log("you denied me :-(")
          }
        )
      }
    }
  }

  function handleSwitch(e) {
    if (
      listStartStops &&
      listStartStops.Name &&
      listEndStops &&
      listEndStops.Name &&
      !departureCoord &&
      !arrivalCoord
    ) {
      const tmpStart = listStartStops
      const tmpEnd = listEndStops
      setListStartStops(tmpEnd)
      setListEndStops(tmpStart)
      setSwitchedStart(tmpEnd)
      setSwitchedEnd(tmpStart)
    } else if (departureCoord) {
      let departure = departureCoord
      departureCoord.Name = data.geolocationLabel
      setArrivalCoord(departure)
      setListStartStops(listEndStops)
      setDepartureCoord(null)
      setSwitchedStart(listEndStops)
      setSwitchedEnd(departure)
      setListEndStops(departure)
    } else if (arrivalCoord) {
      let arrival = arrivalCoord
      arrival.Name = data.geolocationLabel
      setDepartureCoord(arrival)
      setListEndStops(listStartStops)
      setArrivalCoord(null)
      setSwitchedEnd(listStartStops)
      setSwitchedStart(arrival)
      setListStartStops(arrival)
    }
  }

  const [menuIsOpen, setIsOpen] = useState(false)
  useEffect(() => {
    if (window && window.document && document.getElementById("sideMenuUlTag")) {
      let targetNode = document.getElementById("sideMenuUlTag") as Node
      let config = { attributes: true, childList: true }
      let callback = function (mutationsList) {
        for (let mutation of mutationsList) {
          if (mutation.type == "attributes") {
            setIsOpen(!menuIsOpen)
            // console.log('The ' + mutation.attributeName + ' attribute was modified.');
          }
        }
      }
      // Create an observer instance linked to the callback function
      let observer = new MutationObserver(callback)
      // Start observing the target node for configured mutations
      observer.observe(targetNode, config)
    }
  })

  function focusFn() {
    return
  }

  const [timeValue, setTimeValue] = useState(null)
  useEffect(() => {
    let date = new Date()
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60))
    date.setMinutes(0, 0, 0)
    let time = date.getTime()
    setTimeValue(time)
  }, [timeValue])

  const [isListeningDeparture, setIsListeningDeparture] = useState(false)
  const [isListeningArrival, setIsListeningArrival] = useState(false)
  const [departureNote, setDepartureNote] = useState(null)
  const [arrivalNote, setArrivalNote] = useState(null)
  const [microphone, setMicrophone] = useState(null)

  useEffect(() => {
    let mic = useSpeechToText()
    if (mic != false) {
      setMicrophone(mic)
    }
  }, [microphone])

  useEffect(() => {
    handleListenDeparture()
  }, [isListeningDeparture])

  useEffect(() => {
    handleListenArrival()
  }, [isListeningArrival])

  const handleListenDeparture = () => {
    if (microphone) {
      if (isListeningDeparture && !isListeningArrival) {
        microphone.start()
        microphone.onend = () => {
          console.log("continue..")
          microphone.start()
        }
      } else if (!isListeningDeparture) {
        microphone.stop()
        microphone.onend = () => {
          console.log("Stopped Mic on Click")
        }
      }
      microphone.onstart = () => {
        console.log("Mics on")
      }

      microphone.onresult = event => {
        const transcript = Array.from(event.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join("")
        if ((transcript && !departureNote) || (transcript && departureNote && transcript != departureNote.Name)) {
          setDepartureNote({ Name: transcript })
          setSwitchedStart({ Name: transcript })
          document.getElementById("linesInputDeparture").focus()
          document.getElementById("linesInputDeparture").blur()
          document.getElementById("linesInputDeparture").focus()
        }
        microphone.onerror = errorEvent => {
          console.log(errorEvent.error)
        }
      }
    }
  }

  const handleListenArrival = () => {
    if (microphone) {
      if (isListeningArrival && !isListeningDeparture) {
        microphone.start()
        microphone.onend = () => {
          console.log("continue..")
          microphone.start()
        }
      } else if (!isListeningArrival) {
        microphone.stop()
        microphone.onend = () => {
          console.log("Stopped Mic on Click")
        }
      }
      microphone.onstart = () => {
        console.log("Mics on")
      }
      microphone.onresult = event => {
        const transcript = Array.from(event.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join("")
        if ((transcript && !arrivalNote) || (transcript && arrivalNote && transcript != arrivalNote.Name)) {
          setArrivalNote({ Name: transcript })
          setSwitchedEnd({ Name: transcript })
          document.getElementById("linesInputArrival").focus()
          document.getElementById("linesInputArrival").blur()
          document.getElementById("linesInputArrival").focus()
        }
        microphone.onerror = errorEvent => {
          console.log(errorEvent.error)
        }
      }
    }
  }

  function handleCheckbox(e) {
    setCheckboxValue(!checkboxValue)
  }

  return (
    <div>
      <div className={styles.itinerarySearchForm}>
        <form ref={component} method="get" action={"#"} onSubmit={submitForm}>
          <h1 className={styles.title}>{data.title}</h1>
          <div className={styles.formContainer}>
            <div className={styles.coordinates}>
              <div className={""}>
                <label htmlFor="departureLineInput">{data.departureLabel}</label>
                <div className={styles.inputWithIcons}>
                  <Autocomplete
                    onChange={selectStartStop}
                    suggestFunction={getStartStop}
                    toLabel={toLabel}
                    id="linesInputDeparture"
                    placeholder={departureCoord ? data.geolocationLabel : data.departurePlaceholder}
                    isItineraryForm={true}
                    value={switchedStart ? switchedStart : departureCoord ? departureCoord : ""}
                    onFocus={() => focusFn}
                  />
                  <button
                    className={styles.inputIcon}
                    onKeyPress={handleGeoLoc("departure")}
                    onClick={handleGeoLoc("departure")}
                    type="button"
                  >
                    <ReactSVG
                      src={data.geolocationIcon.url}
                      className={styles.inputWithIcons}
                      beforeInjection={svg => svg.setAttribute("aria-label", data.geolocationIcon.alt || "")}
                    />
                  </button>
                  <button
                    className={styles.inputIcon}
                    id={"activateSpeechButtonDeparture"}
                    onKeyPress={() => {
                      setIsListeningDeparture(prevState => !prevState)
                    }}
                    onClick={() => {
                      setIsListeningDeparture(prevState => !prevState)
                    }}
                    type="button"
                  >
                    <ReactSVG
                      src={data.microphonePictogram.url}
                      className={styles.inputWithIcons}
                      beforeInjection={svg => svg.setAttribute("aria-label", data.micDepartureAltText || "")}
                    />
                  </button>
                </div>
                {invalidDeparture ? <div className={styles.errorMessage}>{invalidDeparture}</div> : null}
                <input
                  type="hidden"
                  name="departureLine"
                  value={
                    !departureCoord
                      ? listStartStops && listStartStops.Id + "#" + listStartStops.Name + "#" + listStartStops.PointType
                      : data.geolocationLabel
                  }
                />
              </div>
              <button className={styles.switchButton} onClick={handleSwitch}>
                <ReactSVG
                  src={data.swapDepartureArrivalIcon.url}
                  className={styles.inputWithIcons}
                  beforeInjection={svg => svg.setAttribute("aria-label", data.swapDepartureArrivalIcon.alt || "")}
                />
              </button>
              <div className={""}>
                <label htmlFor="arrivalLineInput">{data.arrivalLabel}</label>
                <div className={styles.inputWithIcons}>
                  <Autocomplete
                    onChange={selectEndStop}
                    suggestFunction={getEndStop}
                    toLabel={toLabel}
                    id="linesInputArrival"
                    placeholder={arrivalCoord ? data.geolocationLabel : data.arrivalPlaceholder}
                    isItineraryForm={true}
                    value={switchedEnd ? switchedEnd : arrivalCoord ? arrivalCoord : null}
                    onFocus={() => focusFn}
                  />
                  <button
                    className={styles.inputIcon}
                    onKeyPress={handleGeoLoc("arrival")}
                    onClick={handleGeoLoc("arrival")}
                    type="button"
                  >
                    <ReactSVG
                      src={data.geolocationIcon.url}
                      className={styles.inputWithIcons}
                      beforeInjection={svg => svg.setAttribute("aria-label", data.geolocationIcon.alt || "")}
                    />
                  </button>
                  <button
                    className={styles.inputIcon}
                    id={"activateSpeechButtonArrival"}
                    onKeyPress={() => {
                      setIsListeningArrival(prevState => !prevState)
                    }}
                    onClick={() => {
                      setIsListeningArrival(prevState => !prevState)
                    }}
                    type="button"
                  >
                    <ReactSVG
                      src={data.microphonePictogram.url}
                      className={styles.inputWithIcons}
                      beforeInjection={svg => svg.setAttribute("aria-label", data.micArrivalAltText || "")}
                    />
                  </button>
                </div>
                {invalidArrival ? <div className={styles.errorMessage}>{invalidArrival}</div> : null}
                <input
                  type="hidden"
                  name="arrivalLine"
                  value={
                    !arrivalCoord
                      ? listEndStops && listEndStops.Id + "#" + listEndStops.Name + "#" + listEndStops.PointType
                      : data.geolocationLabel
                  }
                />
              </div>
            </div>
            <div className={styles.time}>
              <div className={styles.timeAlign}>
                <div className={styles.toggleButtonContainer}>
                  <label className={!checkboxValue ? styles.selectedField : styles.unselectedField}>
                    {data.dateDepartureTypeLabel}
                  </label>
                  <label className={styles.switchButt}>
                    <input name="dateType" type="checkbox" checked={checkboxValue} onChange={e => handleCheckbox(e)} />
                    <span className={styles.slider + " " + styles.round}></span>
                  </label>
                  <label className={checkboxValue ? styles.selectedField : styles.unselectedField}>
                    {data.dateArrivalTypeLabel}
                  </label>
                </div>
                <div className={!menuIsOpen ? styles.timeRow : styles.timeRowNoIndex}>
                  <div className={styles.calendarContent}>
                    <ReactSVG
                      src={data.calendarIcon.url}
                      className={styles.timeRowIcon}
                      beforeInjection={svg => svg.setAttribute("aria-label", data.calendarIcon.alt || "")}
                    />
                    <DateInput
                      id="date"
                      name="date"
                      required={true}
                      control={control}
                      valueSelected={new Date().toLocaleDateString()}
                      isItineraryForm={true}
                    />
                  </div>
                  <span className={styles.textLine}>{data.preposition}</span>
                  <div className={styles.timeContent}>
                    <ReactSVG
                      src={data.clockIcon.url}
                      className={styles.timeRowIcon}
                      beforeInjection={svg => svg.setAttribute("aria-label", data.clockIcon.alt || "")}
                    />
                    <TimeInput
                      id="time"
                      name="time"
                      required={true}
                      control={control}
                      timeValue={timeValue}
                      isItineraryForm={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.button}>
              <button type="submit">{data.searchButtonLabel}</button>
            </div>
          </div>
        </form>
      </div>
      {resultError ? (
        <div className={styles.noTripErrorMessage}>
          <p>{data.tripAvailabilityErrorMessage}</p>
        </div>
      ) : null}
    </div>
  )
}

export default LePiloteItineraryForm
