import React, { useEffect } from "react"
import { OskarPaymentModuleFieldsFragment } from "../../../graphql-types"
import * as styles from "./oskar-payment.module.scss"

type RenderProps = {
  data: OskarPaymentModuleFieldsFragment
}

const OskarPayment: React.FC<RenderProps> = ({ data }) => {
  useEffect(() => {
    if (window) {
      if (document.head.attachShadow) {
        let paymentModuleScript = document.createElement("script")
        paymentModuleScript.type = "text/javascript"
        paymentModuleScript.async = true
        paymentModuleScript.src = "./oskar-payment/oskar-payment.js"
        document.getElementsByTagName("head")[0].appendChild(paymentModuleScript)
      } else {
        let app = document.getElementsByTagName("oskar-payment")[0]
        app.innerHTML =
          '<p id="error-msg">Votre navigateur n\'est pas compatible avec le module de paiement en ligne. Veuillez utiliser un autre navigateur comme Google Chrome ou Mozilla Firefox<p>'
        document.getElementById("error-msg").style.color = "white"
        document.getElementById("error-msg").style.backgroundColor = "red"
      }
    }
  })
  return (
    <div className={styles.oskarContainer}>
      <div dangerouslySetInnerHTML={{ __html: data.moduleTag }}></div>
    </div>
  )
}

export default OskarPayment
