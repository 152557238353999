import React, { useEffect, useState } from "react"
import { VianavigoItineraryModuleFieldsFragment } from "../../../graphql-types"
import * as styles from "./vianavigo-itinerary.module.scss"

import vianavigoItineraryScript from "./vianavigo-itinerary-script"

type RenderProps = {
  data: VianavigoItineraryModuleFieldsFragment
}

const VianavigoItinerary: React.FC<RenderProps> = ({ data }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    if (window && window.document) {
      if (!scriptLoaded) {
        let itineraryLinkTag = document.createElement("link")
        itineraryLinkTag.href = "https://mb-iv.iledefrance-mobilites.fr/v2.1/css/vianavigo.itinerary.css"
        itineraryLinkTag.type = "text/css"
        itineraryLinkTag.rel = "stylesheet"
        document.getElementsByTagName("head")[0].appendChild(itineraryLinkTag)
        let vendorScript = document.createElement("script")
        vendorScript.type = "text/javascript"
        vendorScript.src = "https://mb-iv.iledefrance-mobilites.fr/v2.1/vianavigo.vendor.js"
        document.getElementsByTagName("head")[0].appendChild(vendorScript)
        vendorScript.onload = function () {
          let itineraryScript = document.createElement("script")
          itineraryScript.type = "text/javascript"
          itineraryScript.src = "https://mb-iv.iledefrance-mobilites.fr/v2.1/vianavigo.itinerary.js"
          itineraryScript.id = "itineraryJsVianavigo"
          document.getElementsByTagName("head")[0].appendChild(itineraryScript)
          itineraryScript.onload = function () {
            setScriptLoaded(true)
          }
        }
      }
    }
  }, [])

  return (
    <div className={styles.scriptContainer} id="scriptContainer">
      <div id="mon_selecteur"></div>
      {scriptLoaded ? <script>{vianavigoItineraryScript(data.token, data.configurationId)}</script> : null}
    </div>
  )
}

export default VianavigoItinerary
