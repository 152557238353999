import React, { useEffect, useRef, useState } from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { LePiloteNextTripsSearchBlockFieldsFragment } from "../../../../graphql-types"
import * as styles from "./le-pilote-next-trips-search-block.module.scss"
import * as globalBlockStyles from "../main-blocks/main-blocks.module.scss"
import * as blockStyles from "../custom-block/custom-block.module.scss"
import * as inputsStyles from "../../layout/inputs.module.scss"
import Autocomplete from "../../autocomplete/autocomplete"
import { getStopsFromApi } from "../../../api/le-pilote-api"

type RenderProps = {
  block: LePiloteNextTripsSearchBlockFieldsFragment
  intersectionRatioCallback: (ratio: number) => void
}

const LePiloteNextTripsSearchBlock: React.FC<RenderProps> = ({ block, intersectionRatioCallback }) => {
  const component = useRef<HTMLFormElement>()
  const observer = useRef<IntersectionObserver>()
  const [stops, setStops] = useState([])
  const [realStops, setRealStops] = useState(null)

  useEffect(() => {
    if (intersectionRatioCallback) {
      if (!("IntersectionObserver" in window)) {
        return
      }
      const options = { threshold: [0.2, 0.4, 0.6, 0.8, 1.0] }
      observer.current = new IntersectionObserver(entries => {
        intersectionRatioCallback(entries[entries.length - 1].intersectionRatio)
      }, options)
      observer.current.observe(component.current)
      return () => observer.current.disconnect()
    }
  }, [])

  const search = <T,>(query: string, elements: T[], toLabelFn: (element: T) => string): T[] => {
    if (elements) {
      return elements.filter(result => toLabelFn(result).toLowerCase().indexOf(query.toLowerCase()) !== -1)
    }
  }
  const toLabel = element => (element ? element.Name : "")

  const formatStopsResults = stopsResults => {
    if (stopsResults) {
      return stopsResults.filter(
        (ele, index) => index === stopsResults.findIndex(elem => elem.LogicalId === ele.LogicalId)
      )
    } else {
      return []
    }
  }

  const searchAllTypeTripPoints = query => {
    if (query) {
      let cleanQuery = query.trim()
      let tmpStops
      ;(async () => {
        tmpStops = await getStopsFromApi(cleanQuery)
        setStops(tmpStops)
      })()
      const completeResults = formatStopsResults(stops)
      setRealStops(completeResults)
      return search(cleanQuery, completeResults, toLabel)
    }
    return []
  }

  return (
    <form
      className={`${blockStyles.block} ${globalBlockStyles.mainBlocksCard}`}
      ref={component}
      method="get"
      action={`/${block.resultsPage.slug}`}
    >
      <div className={styles.blockHeader}>
        {block.pictogram.format === "svg" ? (
          <ReactSVG
            src={block.pictogram.url}
            className={styles.blockPicto}
            beforeInjection={svg => svg.setAttribute("aria-label", block.pictogram.alt || "")}
          />
        ) : (
          <img src={block.pictogram.url} className={styles.blockPicto} alt={block.pictogram.alt || ""} />
        )}
        <div className={styles.title}>{block.title}</div>
      </div>
      <div className={styles.form}>
        <div className={styles.formPart}>
          <div className={styles.field}>
            <label htmlFor="trippoint">{block.trippointFieldLabel}</label>
            <Autocomplete
              onChange={setRealStops}
              suggestFunction={searchAllTypeTripPoints}
              toLabel={toLabel}
              id="trippoint"
              placeholder={block.trippointFieldPlaceholder}
            />
            <input
              type="hidden"
              name="search"
              value={
                (realStops && realStops.LogicalId + "#" + realStops.Name) ||
                (realStops && realStops.Id + "#" + realStops.Name)
              }
            />
          </div>
        </div>
      </div>
      <button className={`${styles.searchButton} ${inputsStyles.primaryButton}`} type="submit">
        {block.searchButtonLabel}
      </button>
    </form>
  )
}

export const fragments = graphql`
  fragment LePiloteNextTripsSearchBlockFields on DatoCmsLePiloteNextTripsSearchBlock {
    __typename
    id
    title
    pictogram {
      alt
      format
      url
    }
    trippointFieldLabel
    trippointFieldPlaceholder
    searchButtonLabel
    resultsPage {
      slug
    }
  }
`
export default LePiloteNextTripsSearchBlock
