import React from "react"
import * as styles from "./popup.module.scss"
import * as inputStyles from "../layout/inputs.module.scss"
import { ReactSVG } from "react-svg"

type Button = {
  label: string
  action: () => void
}
type RenderProps = {
  title: string
  message: string
  icon?: { src: string; alt: string; style?: any }
  button1?: Button
  button2?: Button
}

const Popup = ({ title, message, icon, button1, button2 }: RenderProps) => {
  return (
    <div className={styles.popup}>
      <div className={styles.content}>
        {icon && (
          <ReactSVG
            src={icon.src}
            className={icon.style}
            beforeInjection={svg => svg.setAttribute("aria-label", icon.alt || "")}
          />
        )}
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.message} dangerouslySetInnerHTML={{ __html: message }} />
        <div>
          {button1 && (
            <button className={inputStyles.primaryButton} onClick={button1.action} autoFocus>
              {button1.label}
            </button>
          )}
          {button2 && <button onClick={button2.action}>{button2.label}</button>}
        </div>
      </div>
    </div>
  )
}

export default Popup
