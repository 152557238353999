import React, { useEffect, useRef, useState } from "react"
import * as styles from "../traffic-block/traffic-blocks.module.scss"
import sanitizeHtml from "sanitize-html"
import { cross } from "../../../../static/images"
import { ArrowLeft, ArrowRight } from "../../layout/icons"
import TrafficBlock from "./le-pilote-traffic-block"

type RenderProps = {
  blocks: any
  previousLabel: string
  nextLabel: string
  closeButtonLabel: string
}

const LePiloteTrafficBlocks: React.FC<RenderProps> = ({ blocks, previousLabel, nextLabel, closeButtonLabel }) => {
  const container = useRef<HTMLUListElement>(null)
  const openTrafficIdRef = useRef<HTMLDivElement>()
  const scrollToNextItem = () => {
    //myTrafficData
    const itemWidth = container.current.children[0].clientWidth
    container.current.scrollBy({ left: itemWidth, top: 0, behavior: "smooth" })
  }

  const scrollToPreviousItem = () => {
    const itemWidth = container.current.children[0].clientWidth
    container.current.scrollBy({ left: -itemWidth, top: 0, behavior: "smooth" })
  }

  const [tab, setTab] = useState(true)
  const [trafficId, setId] = useState("")
  //const [myTrafficData, setTrafficData] = useState([])

  useEffect(() => {}, [tab, trafficId])

  const handleChildClick = event => {
    setTab(false)
    setId(event)
    setTimeout(focusEventTitle)
  }

  const focusEventTitle = () => {
    if (typeof window === "undefined" || !openTrafficIdRef.current) {
      return
    }
    openTrafficIdRef.current.focus()
  }

  const exitTrafficDetails = () => {
    setTab(true)
    setTimeout(() => focusPreviouslyOpenInfoTraffic(trafficId))
  }

  const focusPreviouslyOpenInfoTraffic = id => {
    if (typeof window === "undefined" || !id) {
      return
    }
    document.getElementById(`le-pilote-traffic-block-button-${id}`).focus()
  }

  const splitArrayIntoChunksOfLen = (arr: [], len: number) => {
    let chunks = [],
      i = 0,
      n = arr.length
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)))
    }
    return chunks
  }

  const perturbationGrids = splitArrayIntoChunksOfLen(blocks, 12)

  let trafficData: any
  perturbationGrids.forEach(element => {
    element.map(item => {
      if (item.line.Id == trafficId) {
        trafficData = item
      }
    })
  })

  return tab ? (
    <div className={blocks.length > 12 ? styles.gridBlocks : styles.oneGridBlock}>
      {blocks.length > 12 && (
        <div className={styles.controls}>
          <button
            aria-label={previousLabel}
            title={previousLabel}
            onClick={scrollToPreviousItem}
            className={styles.customBlockArrows}
          >
            <ArrowLeft />
          </button>
        </div>
      )}

      <ul className={styles.secondaryBlocks} ref={container}>
        {perturbationGrids.map((block, index) => (
          <li className={styles.secondaryBlock} key={index}>
            <TrafficBlock block={block} onPerturbationClicked={handleChildClick} />
          </li>
        ))}
      </ul>
      {blocks.length > 12 && (
        <div className={styles.controls}>
          <button
            aria-label={nextLabel}
            title={nextLabel}
            onClick={scrollToNextItem}
            className={styles.customBlockArrows}
          >
            <ArrowRight />
          </button>
        </div>
      )}
    </div>
  ) : (
    <div className={styles.detailsCard}>
      <div className={styles.detailsCardHeader}>
        <div className={styles.detailsCardHeaderContent}>
          {trafficData.line.icone ? (
            <img
              src={trafficData.line.icone.url}
              className={`${styles.image} ${styles.lePiloteImage}`}
              alt={trafficData.line.Code || ""}
            />
          ) : (
            <div className={styles.textInPlaceOfImage}>{trafficData.line.Code}</div>
          )}
          <h4 className={styles.detailsCardHeaderTitle}>{trafficData.line.Name}</h4>
          <div className={styles.exitIconContainer}>
            <button className={styles.exitButton} onClick={exitTrafficDetails}>
              <img src={cross} className={styles.exitIcon} alt={closeButtonLabel} />
            </button>
          </div>
        </div>
        <div className={styles.detailsCardBodyWrapper} tabIndex={0}>
          {trafficData.traffic.map((item, index) => (
            <div key={index}>
              <div className={`${styles.detailsCardBody} ${styles.detailsCardBodyLePilote}`}>
                <p className={styles.infoTrafficTitle}>{item.Name}</p>
              </div>
              <div
                className={styles.shortDescription}
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.Description) }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LePiloteTrafficBlocks
